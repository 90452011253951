import gql from 'graphql-tag';

export const GET_PRIMARY_ROLES = gql`
  query GetPrimaryRoles($resourceTypes: [String!]) {
    primaryRoles(resourceTypes: $resourceTypes) {
      primaryRoles {
        key
        name
        resourceType
      }
    }
  }
`;

export const GET_PRIMARY_ROLES_FULL_INFO = gql`
  query GetPrimaryRolesFullInfo($resourceTypes: [String!]) {
    primaryRoles(resourceTypes: $resourceTypes) {
      primaryRoles {
        id
        key
        name
        shortName
        resourceType
      }
      primaryRolesFullInfo {
        key
        usersCount
      }
    }
  }
`;
