import React from 'react';

import Layout from 'app/admin/layouts/Admin';

interface Props {
  scrollDisabled: boolean;
  noNavbarMargin?: boolean;
  children: any;
}

const ListPageLayout: React.FC<Props> = ({
  scrollDisabled,
  noNavbarMargin = false,
  children,
}) => {
  return (
    <Layout scrollDisabled={scrollDisabled} noNavbarMargin={noNavbarMargin}>
      {children}
    </Layout>
  );
};

export default ListPageLayout;
