import gql from 'graphql-tag';

import { artistSocialUrls } from 'app/shared/utils/socialLinks';

export const GET_ARTIST_MERGE_INFO = gql`
  query GetArtistMergeInfo($artistId1: Int!, $artistId2: Int!) {
    artistMergeInfo(artistId1: $artistId1, artistId2: $artistId2) {
      artist1 {
        id
        title
        cachedSlug
        description
        email
        homeCityDescription
        imageUrl
        socialUrls {
          ${artistSocialUrls.join(' ')}
        }
        pastEventsCount
        upcomingEventsCount
        lastEventAt
        associatedUsers {
          id
          firstName
          lastName
          email
        }
        reviewedAt
      }
      artist2 {
        id
        title
        cachedSlug
        description
        email
        homeCityDescription
        imageUrl
        socialUrls {
          ${artistSocialUrls.join(' ')}
        }
        pastEventsCount
        upcomingEventsCount
        lastEventAt
        associatedUsers {
          id
          firstName
          lastName
          email
        }
        reviewedAt
      }
    }
  }
`;

export const GET_DUPLICATE_ARTISTS = gql`
  query GetDuplicateArtists(
    $duplicateArtistSearch: String
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
  ) {
    duplicateArtists(
      duplicateArtistSearch: $duplicateArtistSearch
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      duplicateArtists {
        id
        title
        numArtists
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_DUPLICATED_ARTISTS_FOR_TITLE = gql`
  query GetDuplicatedArtistsForTitle($title: String) {
    duplicatedArtistsForTitle(title: $title) {
      artists {
        id
        title
        description
        email
        homeCityDescription
        socialUrls {
          urlWebsite
          urlFacebook
        }
      }
    }
  }
`;
