import React from 'react';

import ThemeWrapper from 'app/shared/components/atoms/ThemeWrapper';

import { FullFooter } from './FullFooter';
import { MinimalFooter } from './MinimalFooter';

interface FooterProps {
  'data-qaid'?: string;
  footerRef: React.RefObject<HTMLDivElement>;
  minimal?: boolean;
}

const Footer = ({ minimal, ...props }: FooterProps) => {
  return minimal ? <MinimalFooter {...props} /> : <FullFooter {...props} />;
};

const FooterWithTheme = ({ ...props }: FooterProps) => {
  // Theme is hard-coded b/c this footer is used by manualCSS pages which use the manualCSS or fanManualCSS theme,
  // so we always have to force the theme to be common
  const themeName = 'common';

  return (
    <ThemeWrapper themeName={themeName}>
      <Footer {...props} />
    </ThemeWrapper>
  );
};

export default FooterWithTheme;
