import gql from 'graphql-tag';

export const CREATE_BROADCAST = gql`
  mutation createBroadcast(
    $scheduledStartTimeUtc: String
    $artistId: Int
    $location: String
    $streamType: String
    $embedUrl: String
    $extraInfoUrl: String
    $linkType: String
    $partnershipTag: String
    $hideDonation: Boolean
    $donationText: String
  ) {
    createBroadcast(
      scheduledStartTimeUtc: $scheduledStartTimeUtc
      artistId: $artistId
      location: $location
      streamType: $streamType
      embedUrl: $embedUrl
      extraInfoUrl: $extraInfoUrl
      linkType: $linkType
      partnershipTag: $partnershipTag
      hideDonation: $hideDonation
      donationText: $donationText
    ) {
      success
      broadcast {
        id
        scheduledStartTimeUtc
        artistId
        artistTitle
        artistSlug
        artistBio
        artistImage
        artistSocials {
          urlSupport
        }
        location
        streamType
        embedUrl
        extraInfoUrl
        linkType
        partnershipTag
        hideDonation
        donationText
      }
    }
  }
`;

export const DELETE_BROADCAST = gql`
  mutation deleteBroadcast($broadcastId: Int!) {
    deleteBroadcast(broadcastId: $broadcastId) {
      success
    }
  }
`;

export const UPDATE_BROADCAST = gql`
  mutation updateBroadcast(
    $broadcastId: Int!
    $scheduledStartTimeUtc: String
    $artistId: Int
    $location: String
    $streamType: String
    $embedUrl: String
    $extraInfoUrl: String
    $linkType: String
    $partnershipTag: String
    $hideDonation: Boolean
    $donationText: String
  ) {
    updateBroadcast(
      broadcastId: $broadcastId
      scheduledStartTimeUtc: $scheduledStartTimeUtc
      artistId: $artistId
      location: $location
      streamType: $streamType
      embedUrl: $embedUrl
      extraInfoUrl: $extraInfoUrl
      linkType: $linkType
      partnershipTag: $partnershipTag
      hideDonation: $hideDonation
      donationText: $donationText
    ) {
      success
      broadcast {
        id
        scheduledStartTimeUtc
        artistId
        artistTitle
        artistSlug
        artistBio
        artistImage
        artistSocials {
          urlSupport
        }
        location
        streamType
        embedUrl
        extraInfoUrl
        linkType
        partnershipTag
        hideDonation
        donationText
      }
    }
  }
`;
