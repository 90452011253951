import gql from 'graphql-tag';

export const GET_COMPETITION_TEMPLATES = gql`
  query GetCompetitionTemplates($skipPagination: Boolean) {
    competitionTemplates(skipPagination: $skipPagination) {
      competitionTemplates {
        id
        templateName
        title
        city {
          id
          title
        }
        maxWinners
        intro
        thirdPartyCtaText
        thirdPartyCtaLink
        subtitle
        subtitleTwo
        description
        postDescription
        winnerCopy
        expiredCopy
        moreInfoTitle
        moreInfoSubheading
        moreInfoPreview
        featuredContentCtaText
        featuredContentCtaLink
        turnOnOptIn
        optInCopy
        shareTitle
        shareCopy
        createdAt
      }
      metadata {
        currentPage
        totalRecords
      }
    }
  }
`;
