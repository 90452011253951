import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useCurrentTheme } from 'app/shared/theme';
import { makeOpaque } from 'app/shared/utils/colors';
import useDisableScroll from 'app/shared/utils/useDisableScroll';
import useOutsideClick from 'app/shared/utils/useOutsideClick';
import { Box, Popper, PopperProps } from 'app/shared/components/atoms';
import { Icon } from 'app/shared/components/atoms';

interface ToolTipProps extends PopperProps {
  icon?: any;
  children: any;
  hasFlyoutContainer?: boolean;
  disableScrollWhenOpen?: boolean;
  invertColor?: boolean;
  'data-qaid'?: string;
  id?: string;
  className?: string;
  isLastChild?: boolean;
}

export const ToolTip: React.FC<ToolTipProps> = ({
  icon,
  children,
  hasFlyoutContainer = true,
  disableScrollWhenOpen = false,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  offset,
  keepInViewPort,
  'data-qaid': qaId,
  id,
  className,
  isLastChild = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<any>(null);
  const theme = useCurrentTheme();

  useDisableScroll(isOpen, disableScrollWhenOpen);

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  return (
    <Box
      className={className}
      display="inline-block"
      onClick={() => setIsOpen(!isOpen)}
    >
      <Box
        cursor="pointer"
        display="flex"
        id={id}
        ref={ref}
        onClick={() => setIsOpen(!isOpen)}
        data-qaid={qaId}
      >
        <Icon
          fill={theme.colors.primary.black.base}
          icon={icon}
          size="sm"
          data-qaid="help-tooltip"
        />
      </Box>
      {isOpen &&
        createPortal(
          <Popper
            offset={offset}
            anchorEl={ref}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            keepInViewPort={keepInViewPort}
            isLastChild={isLastChild}
            width="218px"
          >
            {hasFlyoutContainer ? (
              <Box
                data-qaid="tooltip-content"
                w="100%"
                m={1}
                boxShadow={`0px 12px 16px 1px ${makeOpaque(
                  theme.colors.primary.black.lightest,
                  0.25
                )}`}
              >
                {children}
              </Box>
            ) : (
              children
            )}
          </Popper>,
          document.body
        )}
      {isOpen && <Box position="absolute" cursor="pointer" />}
    </Box>
  );
};
