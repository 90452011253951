import gql from 'graphql-tag';

export const GET_PARTNERS = gql`
  query GetPartners($archivedStatus: String, $skipPagination: Boolean) {
    partners(archivedStatus: $archivedStatus, skipPagination: $skipPagination) {
      partners {
        id
        title
      }
    }
  }
`;

export const GET_PARTNERS_FULL_INFO = gql`
  query GetPartnersFullInfo(
    $partnerSearch: String
    $archivedStatus: String
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
  ) {
    partners(
      partnerSearch: $partnerSearch
      archivedStatus: $archivedStatus
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      partners {
        id
        title
        description
        address
        address2
        createdAt
        isArchived
        partnersCampaignsCount
      }
      metadata {
        totalRecords
      }
    }
  }
`;
