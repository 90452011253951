import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { useNavbarContext } from 'app/shared/context';
import { Box } from 'app/shared/components/atoms';
import { IconButton } from 'app/shared/components/molecules';
import { ReactComponent as MenuIcon } from 'icons/menu.svg';

import { useNavConfig } from './navUtils';
import { ParentMenuItem } from './ParentMenuItem';

export const NavbarMenuButtons = ({
  loggedIn,
  invertColors = false,
}: {
  loggedIn?: boolean;
  invertColors?: boolean;
}) => {
  const { isDesktop } = useIsBreakpointSize();
  const { colors } = useCurrentTheme();
  const { setIsMobileMenuOpen, isLandingPage } = useNavbarContext();
  const menuData = useNavConfig({ loggedIn });

  if (isDesktop || isLandingPage) {
    return (
      <Box
        justifyContent={menuData.length === 1 ? 'flex-end' : 'space-between'}
        alignItems="center"
        alignContent="center"
        maxWidth="550px"
        gap={2}
        flex={2}
      >
        {menuData.map((parentMenuItem, index) => (
          <Box w="fit-content" key={index}>
            <ParentMenuItem
              parentMenuItem={parentMenuItem}
              index={index}
              totalParentMenuItems={menuData.length}
              data-qaid={parentMenuItem.key}
              key={index}
              invertColors={invertColors}
            />
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box justifyContent="flex-end" gap={2} alignItems="center" w="fit-content">
      {menuData
        .filter(parentMenuItem => parentMenuItem.linkStyle === 'alwaysOnLink')
        .map((parentMenuItem, index) => (
          <ParentMenuItem
            parentMenuItem={parentMenuItem}
            index={index}
            totalParentMenuItems={menuData.length}
            data-qaid={parentMenuItem.key}
            key={index}
            invertColors={invertColors}
          />
        ))}
      <IconButton
        icon={MenuIcon}
        size="md"
        strokeColor={colors.accent.primaryAccent.base}
        backgroundColor="transparent"
        onClick={() => setIsMobileMenuOpen(true)}
      />
    </Box>
  );
};
