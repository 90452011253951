import { useQuery } from 'react-apollo';

import { SharedGetDataParameters } from 'app/typings/generics';
import { queryParams } from 'app/shared/utils/queries';
import { GET_RAW_PERMISSIONS } from 'app/admin/graphql/permissions/queries';

export interface RawPermission {
  roleKey: string;
  abilityKey: string;
}

export interface GetRawPermissionsParameters extends SharedGetDataParameters {
  roleKey?: string | null;
  abilityKey?: string | null;
}

export interface GetRawPermissionsData {
  rawPermissions: RawPermission[];
}

export const GetRawPermissions = (params: GetRawPermissionsParameters = {}) => {
  return useQuery<GetRawPermissionsData>(
    GET_RAW_PERMISSIONS,
    queryParams(params)
  );
};
