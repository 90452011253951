import { useQuery } from 'react-apollo';

import {
  GetContinentsData,
  GetContinentsVariables,
} from 'app/typings/continents';
import { queryParams } from 'app/shared/utils/queries';
import { GET_CONTINENTS } from 'app/admin/graphql/continents/queries';

export const GetContinents = (params: GetContinentsVariables = {}) => {
  return useQuery<GetContinentsData>(GET_CONTINENTS, queryParams(params));
};
