import React from 'react';

import Layout from 'app/admin/layouts/Admin';

interface Props {
  children: any;
}

const AcknowledgeUserAgreementLayout: React.FC<Props> = ({ children }) => {
  return (
    <Layout
      centerContent={true}
      data-qaid="acknowledge-user-agreement"
      navbarProps={{ suppressAllLinks: true }}
    >
      {children}
    </Layout>
  );
};

export default AcknowledgeUserAgreementLayout;
