import gql from 'graphql-tag';

export const ARCHIVE_THEME = gql`
  mutation archiveTheme($id: Int!) {
    archiveTheme(id: $id) {
      success
      theme {
        id
      }
    }
  }
`;

export const CREATE_THEME = gql`
  mutation createTheme($title: String, $notes: String) {
    createTheme(title: $title, notes: $notes) {
      success
      theme {
        id
        title
        notes
      }
    }
  }
`;

export const UPDATE_THEME = gql`
  mutation updateTheme($themeId: Int!, $title: String, $notes: String) {
    updateTheme(themeId: $themeId, title: $title, notes: $notes) {
      success
      theme {
        id
        title
        notes
      }
    }
  }
`;
