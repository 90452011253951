import React from 'react';
import { useIntl } from 'react-intl';

import { City } from 'app/typings';
import { useCurrentTheme } from 'app/shared/theme';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import {
  NavbarContextProps,
  NavbarContextProvider,
  useNavbarContext,
} from 'app/shared/context';
import {
  Box,
  Image,
  Logo,
  Responsive,
  ThemeWrapper,
  Typography,
} from 'app/shared/components/atoms';
import { NavigationLink as Link } from 'app/shared/components/atoms';
import { BasePageBox, MobileNav } from 'app/shared/components/molecules';
import { CitySelector } from 'app/shared/components/organisms';

import { NavbarAuthModal } from './NavbarAuthModal';
import { NavbarMenuButtons } from './NavbarMenuButtons';
import { useNavConfig } from './navUtils';

export { NavbarAuthModal, useNavConfig };

export interface NavbarProps {
  transparent?: boolean;
  suppressAllLinks?: boolean;
  eventId?: string;
  city?: City;
  hideCitySelector?: boolean;
  loggedIn?: boolean; // ONLY USE THIS FOR STORYBOOK
  backgroundColor?: string;
  isCheckout?: boolean;
  invertColors?: boolean;
}

export const Navbar: React.FC<NavbarProps> = ({
  hideCitySelector,
  city,
  eventId,
  suppressAllLinks,
  loggedIn = false,
  backgroundColor,
  isCheckout = false,
  invertColors = true,
}) => {
  const { formatMessage } = useIntl();
  const citySelectorPlaceholder = formatMessage({
    id: 'navbar.citySelector.placeholder',
  });

  const { isDesktop } = useIsBreakpointSize();
  const { isLandingPage } = useNavbarContext();
  const mobileNavMenuData = useNavConfig({ loggedIn });
  const { colors, zIndex } = useCurrentTheme();

  const borderColor = colors.accent.primaryAccent.base;

  return (
    <>
      <Box
        position="relative"
        backgroundColor={
          backgroundColor || invertColors
            ? colors.primary.black.base
            : colors.primary.white.lightest
        }
        zIndex={zIndex.navbar}
        w="100%"
        bt={borderColor}
        bb={borderColor}
      >
        <NavbarAuthModal eventId={eventId} city={city} />
        {!isDesktop && !isLandingPage && !suppressAllLinks && (
          <MobileNav menuData={mobileNavMenuData} />
        )}
        <>
          <BasePageBox
            data-qaid="navbar"
            w="100%"
            justifyContent="center"
            flexDirection={isDesktop ? 'row' : 'column'}
            gap={5}
            py={[8, 8, 6, 3]}
          >
            <Box
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              alignContent="center"
              gap={8}
            >
              <Box gap={5} alignItems="center">
                <Link variant="primary" href="/">
                  <Logo
                    variant="primary"
                    fillColor={colors.accent.primaryAccent.base}
                    height={32}
                  />
                </Link>
                {!hideCitySelector && (
                  <Responsive showUp="smallDesktop">
                    <Box minWidth="285px">
                      <CitySelector
                        invertColors={invertColors}
                        placeholder={citySelectorPlaceholder}
                        placeholderColor={colors.primary.black.lightest}
                        hideInputLabel
                        data-qaid="navbar-city-selector"
                      />
                    </Box>
                  </Responsive>
                )}
              </Box>
              {!suppressAllLinks && (
                <NavbarMenuButtons
                  loggedIn={loggedIn}
                  invertColors={invertColors}
                />
              )}
              {isCheckout && (
                <Box flexDirection="row" gap={4} alignItems="center">
                  <Typography
                    as="span"
                    fontSize="fontSize300"
                    lineHeight="100%"
                    color={
                      invertColors ? colors.primary.white.lightest : undefined
                    }
                  >
                    {formatMessage({ id: 'checkout.topBar.secureCheckout' })}
                  </Typography>
                  <Image
                    src={`${process.env.PUBLIC_PATH}powered-by-stripe-white.svg`}
                    h="24px"
                    w="auto"
                  />
                </Box>
              )}
            </Box>
            {!hideCitySelector && (
              <Responsive hideUp="smallDesktop">
                <CitySelector
                  invertColors={invertColors}
                  placeholder={citySelectorPlaceholder}
                  hideInputLabel
                />
              </Responsive>
            )}
          </BasePageBox>
        </>
      </Box>
    </>
  );
};

const NavbarWithTheme = ({ ...props }: NavbarProps) => {
  // Theme is hard-coded b/c this navbar is used by manualCSS pages which use the manualCSS or fanManualCSS theme,
  // so we always have to force the theme to be common
  const themeName = 'common';

  return (
    <ThemeWrapper themeName={themeName}>
      <Navbar {...props} />
    </ThemeWrapper>
  );
};

const NavbarWithContext = ({
  isLandingPage = false,
  ...props
}: NavbarProps & NavbarContextProps) => {
  return (
    <NavbarContextProvider isLandingPage={isLandingPage}>
      <NavbarWithTheme {...props} />
    </NavbarContextProvider>
  );
};

export default NavbarWithContext;
