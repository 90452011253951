import gql from 'graphql-tag';

export const GET_EMAIL_TESTS = gql`
  query GetEmailTests(
    $emailType: String
    $orderDirection: String
    $orderBy: String
  ) {
    emailTests(
      emailType: $emailType
      orderDirection: $orderDirection
      orderBy: $orderBy
    ) {
      emailTests {
        key
        name
        useCases {
          key
          name
        }
      }
    }
  }
`;
