import { useLazyQuery } from 'react-apollo';

import {
  GetEmailTestsData,
  GetEmailTestsVariables,
} from 'app/typings/emailTest';
import { queryParams } from 'app/shared/utils/queries';
import { GET_EMAIL_TESTS } from 'app/admin/graphql/emailTest/queries';

export const GetEmailTestsLazy = (params: GetEmailTestsVariables = {}) => {
  return useLazyQuery<GetEmailTestsData>(GET_EMAIL_TESTS, queryParams(params));
};
