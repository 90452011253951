import React from 'react';

import ThemeWrapper from 'app/shared/components/atoms/ThemeWrapper';
import {
  NavigationButton,
  NavigationButtonProps,
} from 'app/shared/components/molecules/NavigationButton';

export const NavigationButtonWithCommonTheme: React.FC<NavigationButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <ThemeWrapper themeName="common">
      <NavigationButton {...props}>{children}</NavigationButton>
    </ThemeWrapper>
  );
};
