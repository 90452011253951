import { useQuery } from 'react-apollo';

import {
  GetNeighborhoodsData,
  GetNeighborhoodsVariables,
} from 'app/typings/neighborhoods';
import { queryParams } from 'app/shared/utils/queries';
import { GET_NEIGHBORHOODS } from 'app/admin/graphql/neighborhoods/queries';

export const GetNeighborhoods = (params: GetNeighborhoodsVariables = {}) => {
  return useQuery<GetNeighborhoodsData>(GET_NEIGHBORHOODS, queryParams(params));
};
