import gql from 'graphql-tag';

export const GET_RAW_PERMISSIONS = gql`
  query GetRawPermissions($roleKey: String, $abilityKey: String) {
    rawPermissions(roleKey: $roleKey, abilityKey: $abilityKey) {
      rawPermissions {
        roleKey
        abilityKey
      }
    }
  }
`;
