import { useMutation } from 'react-apollo';

import {
  ArchiveCampaignData,
  CreateCampaignData,
  UpdateCampaignData,
} from 'app/typings/campaigns';
import {
  ARCHIVE_CAMPAIGN,
  CREATE_CAMPAIGN,
  UPDATE_CAMPAIGN,
} from 'app/admin/graphql/campaigns/mutations';

export const ArchiveCampaign = () => {
  return useMutation<ArchiveCampaignData>(ARCHIVE_CAMPAIGN)[0];
};

export const CreateCampaign = () => {
  return useMutation<CreateCampaignData>(CREATE_CAMPAIGN)[0];
};

export const UpdateCampaign = () => {
  return useMutation<UpdateCampaignData>(UPDATE_CAMPAIGN)[0];
};
