import { Mobile } from 'app/typings/users';

export const mobileNumberFormatter = (
  mobile: Mobile | undefined,
  displayTwoLetterCountryCode?: boolean
) =>
  mobile
    ? `${
        displayTwoLetterCountryCode ? `(${mobile.countryCodeIso3166}) ` : ''
      }+${mobile.countryCode} ${mobile.numberBody}`
    : null;
