import gql from 'graphql-tag';

export const CREATE_SIGNED_FORM = gql`
  mutation createSignedForm(
    $document: Document!
    $documentType: String!
    $uploadableId: Int!
    $uploadableType: String!
  ) {
    createSignedForm(
      document: $document
      documentType: $documentType
      uploadableId: $uploadableId
      uploadableType: $uploadableType
    ) {
      success
      signedForm {
        id
        url
        documentId
        documentType
        uploadableId
        uploadableType
      }
    }
  }
`;

export const DELETE_SIGNED_FORM = gql`
  mutation deleteSignedForm($signedFormId: Int!) {
    deleteSignedForm(signedFormId: $signedFormId) {
      success
    }
  }
`;
