import gql from 'graphql-tag';

export const ARCHIVE_PARTNER = gql`
  mutation archivePartner($id: Int!) {
    archivePartner(id: $id) {
      success
      partner {
        id
      }
    }
  }
`;

export const CREATE_PARTNER = gql`
  mutation createPartner(
    $title: String
    $description: String
    $address: String
    $address2: String
  ) {
    createPartner(
      title: $title
      description: $description
      address: $address
      address2: $address2
    ) {
      success
      partner {
        id
        title
      }
    }
  }
`;

export const UPDATE_PARTNER = gql`
  mutation updatePartner(
    $partnerId: Int!
    $title: String
    $description: String
    $address: String
    $address2: String
  ) {
    updatePartner(
      partnerId: $partnerId
      title: $title
      description: $description
      address: $address
      address2: $address2
    ) {
      success
      partner {
        id
        title
      }
    }
  }
`;
