import { useQuery } from 'react-apollo';

import {
  GetCrewMessageData,
  GetCrewMessagesVariables,
  GetCrewSlackMessagesData,
  GetCrewSlackMessagesVariables,
  GetStaffMemberData,
} from 'app/typings/staffMembers';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_CREW_MESSAGES,
  GET_CREW_SLACK_MESSAGES,
  GET_STAFF_MEMBER,
} from 'app/admin/graphql/staffMembers/queries';

export const GetCrewMessages = (params: GetCrewMessagesVariables) => {
  return useQuery<GetCrewMessageData>(GET_CREW_MESSAGES, queryParams(params));
};

export const GetStaffMember = () => {
  return useQuery<GetStaffMemberData>(GET_STAFF_MEMBER);
};

export const GetCrewSlackMessages = (params: GetCrewSlackMessagesVariables) => {
  return useQuery<GetCrewSlackMessagesData>(
    GET_CREW_SLACK_MESSAGES,
    queryParams(params)
  );
};
