import gql from 'graphql-tag';

export const GET_CONTINENTS = gql`
  query GetContinents {
    continents {
      continents {
        id
        title
        continentCode
        cachedSlug
        countriesCount
      }
    }
  }
`;
