import gql from 'graphql-tag';

export const CREATE_VIP_ATTENDEE = gql`
  mutation createVipAttendee(
    $eventId: Int!
    $fullName: String
    $email: String
    $ticketsCount: Int
    $reference: String
  ) {
    createVipAttendee(
      eventId: $eventId
      fullName: $fullName
      email: $email
      ticketsCount: $ticketsCount
      reference: $reference
    ) {
      success
      vipAttendee {
        id
        fullName
        email
        ticketsCount
        reference
        createdById
      }
    }
  }
`;

export const DELETE_VIP_ATTENDEE = gql`
  mutation deleteVipAttendee($vipAttendeeId: Int!) {
    deleteVipAttendee(vipAttendeeId: $vipAttendeeId) {
      success
    }
  }
`;

export const UPDATE_VIP_ATTENDEE = gql`
  mutation updateVipAttendee(
    $vipAttendeeId: Int!
    $fullName: String
    $email: String
    $ticketsCount: Int
    $ticketsAttendedCount: Int
    $reference: String
  ) {
    updateVipAttendee(
      vipAttendeeId: $vipAttendeeId
      fullName: $fullName
      email: $email
      ticketsCount: $ticketsCount
      ticketsAttendedCount: $ticketsAttendedCount
      reference: $reference
    ) {
      success
      vipAttendee {
        id
        fullName
        email
        ticketsCount
        ticketsAttendedCount
        reference
        createdById
      }
    }
  }
`;
