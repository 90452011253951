import { useMutation } from 'react-apollo';

import {
  CancelCompetitionData,
  CreateCompetitionData,
  UpdateCompetitionData,
} from 'app/typings/competitions';
import {
  CANCEL_COMPETITION,
  CREATE_COMPETITION,
  UPDATE_COMPETITION,
} from 'app/admin/graphql/competitions/mutations';

export const CancelCompetition = () => {
  return useMutation<CancelCompetitionData>(CANCEL_COMPETITION)[0];
};

export const CreateCompetition = () => {
  return useMutation<CreateCompetitionData>(CREATE_COMPETITION)[0];
};

export const UpdateCompetition = () => {
  return useMutation<UpdateCompetitionData>(UPDATE_COMPETITION)[0];
};
