import { useLazyQuery, useQuery } from 'react-apollo';

import {
  GetVersionData,
  GetVersionItemTypesData,
  GetVersionItemTypesVariables,
  GetVersionsData,
  GetVersionsVariables,
  GetVersionVariables,
} from 'app/typings/versions';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_VERSION,
  GET_VERSION_ITEM_TYPES,
  GET_VERSIONS,
} from 'app/admin/graphql/versions/queries';

export const GetVersion = (params: GetVersionVariables = {}) => {
  return useQuery<GetVersionData>(GET_VERSION, queryParams(params));
};

export const GetVersionItemTypes = (
  params: GetVersionItemTypesVariables = {}
) => {
  return useQuery<GetVersionItemTypesData>(
    GET_VERSION_ITEM_TYPES,
    queryParams(params)
  );
};

export const GetVersionsLazy = (params: GetVersionsVariables = {}) => {
  return useLazyQuery<GetVersionsData>(GET_VERSIONS, queryParams(params));
};
