import { useMutation } from 'react-apollo';

import {
  AddArtistToOfferData,
  AddVenuesToOfferData,
  CloseOfferData,
  CreateOfferForArtistsData,
  CreateOfferForVenuesData,
} from 'app/typings/offers';
import {
  ADD_ARTIST_TO_OFFER,
  ADD_VENUES_TO_OFFER,
  CLOSE_OFFER,
  CREATE_OFFER_FOR_ARTISTS,
  CREATE_OFFER_FOR_VENUES,
} from 'app/admin/graphql/offers/mutations';

export const AddArtistToOffer = () => {
  return useMutation<AddArtistToOfferData>(ADD_ARTIST_TO_OFFER)[0];
};

export const CloseOffer = () => {
  return useMutation<CloseOfferData>(CLOSE_OFFER)[0];
};

export const CreateOfferForArtists = () => {
  return useMutation<CreateOfferForArtistsData>(CREATE_OFFER_FOR_ARTISTS)[0];
};

export const CreateOfferForVenues = () => {
  return useMutation<CreateOfferForVenuesData>(CREATE_OFFER_FOR_VENUES)[0];
};

export const AddVenuesToOffer = () => {
  return useMutation<AddVenuesToOfferData>(ADD_VENUES_TO_OFFER)[0];
};
