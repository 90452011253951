import { useMutation } from 'react-apollo';

import {
  AcceptArtistApplicationData,
  RejectArtistApplicationData,
  UpdateArtistApplicationReferrerData,
} from 'app/typings/artistApplications';
import {
  ACCEPT_ARTIST_APPLICATION,
  REJECT_ARTIST_APPLICATION,
  UPDATE_ARTIST_APPLICATION_REFERRER,
} from 'app/admin/graphql/artistApplications/mutations';

export const AcceptArtistApplication = () => {
  return useMutation<AcceptArtistApplicationData>(ACCEPT_ARTIST_APPLICATION)[0];
};

export const RejectArtistApplication = () => {
  return useMutation<RejectArtistApplicationData>(REJECT_ARTIST_APPLICATION)[0];
};

export const UpdateArtistApplicationReferrer = () => {
  return useMutation<UpdateArtistApplicationReferrerData>(
    UPDATE_ARTIST_APPLICATION_REFERRER
  )[0];
};
