import { useQuery } from 'react-apollo';

import {
  GetCityBasicInfoData,
  GetCityBasicInfoVariables,
} from 'app/typings/cities';
import { queryParams } from 'app/shared/utils/queries';
import { GET_CITY_BASIC_INFO } from 'app/admin/graphql/cities/queries';

export const GetCityBasicInfo = (params: GetCityBasicInfoVariables = {}) => {
  return useQuery<GetCityBasicInfoData>(
    GET_CITY_BASIC_INFO,
    queryParams(params)
  );
};
