import gql from 'graphql-tag';

export const GET_EVENT_EMAILS = gql`
  query GetEventEmails($emailKey: String, $eventId: Int!) {
    eventEmails(emailKey: $emailKey, eventId: $eventId) {
      emails {
        id
        emailName
        emailGroup
        isDisabled
        isDisableable
        description
        longDescription
        isEmailCountsEnabled
        numSent
        numTotal
        isPreviewable
        isSendable
        numAllPotentialRecipients
        numNewPotentialRecipients
      }
    }
  }
`;

export const GET_EVENT_EMAIL_PREVIEW_CONTENT = gql`
  query GetEventEmailPreviewContent($emailKey: String!, $eventId: Int!) {
    eventEmailPreviewContent(emailKey: $emailKey, eventId: $eventId) {
      subject
      content
    }
  }
`;
