export const getSlice = (
  things: any[],
  sliceNum: number,
  totalNumSlices: number
) => {
  const numThingsPerSlice = Math.ceil(things.length / totalNumSlices);
  const indexStart = (sliceNum - 1) * numThingsPerSlice;
  const indexEnd = Math.min(indexStart + numThingsPerSlice, things.length);
  return things.slice(indexStart, indexEnd);
};

export const getRandom = (list: any[]) => {
  return list[Math.floor(Math.random() * list.length)];
};
