import gql from 'graphql-tag';

export const GET_CREW_MESSAGES = gql`
  query GetCrewMessages($eventId: Int!, $isArchived: Boolean!) {
    crewMessages(eventId: $eventId, isArchived: $isArchived) {
      crewMessages {
        id
        body
        createdAt
        createdBy {
          id
          firstName
          lastName
          email
        }
        event {
          id
          city {
            id
            timezone
          }
        }
        slackMessages {
          id
          messagable {
            id
            name
          }
          isSuccess
        }
      }
    }
  }
`;

export const GET_CREW_SLACK_MESSAGES = gql`
  query GetSlackMessages($eventId: Int!, $slackKey: String!) {
    slackMessages(eventId: $eventId, slackKey: $slackKey) {
      slackMessages {
        id
        messagable {
          id
          name
        }
        isSuccess
      }
    }
  }
`;

export const GET_STAFF_MEMBER = gql`
  query GetStaffMember {
    staffMember {
      id
      staffMemberAvailabilityDates {
        date
        isAvailable
      }
      eventsPendingFeedback {
        id
        localStartsAt
        city {
          id
          title
          cachedSlug
          country {
            id
            title
            countryCode
          }
        }
        venue {
          id
          venueName
          address
          venueType
          neighborhood {
            id
            title
          }
        }
        performances {
          id
          artist {
            id
            title
            imageUrl
          }
          position
          artistConfirmed
        }
      }
    }
  }
`;
