import { useMutation } from 'react-apollo';

import {
  CreateCompetitionTemplateData,
  DeleteCompetitionTemplateData,
  UpdateCompetitionTemplateData,
} from 'app/typings/competitionTemplates';
import {
  CREATE_COMPETITION_TEMPLATE,
  DELETE_COMPETITION_TEMPLATE,
  UPDATE_COMPETITION_TEMPLATE,
} from 'app/admin/graphql/competitionTemplates/mutations';

export const CreateCompetitionTemplate = () => {
  return useMutation<CreateCompetitionTemplateData>(
    CREATE_COMPETITION_TEMPLATE
  )[0];
};

export const DeleteCompetitionTemplate = () => {
  return useMutation<DeleteCompetitionTemplateData>(
    DELETE_COMPETITION_TEMPLATE
  )[0];
};

export const UpdateCompetitionTemplate = () => {
  return useMutation<UpdateCompetitionTemplateData>(
    UPDATE_COMPETITION_TEMPLATE
  )[0];
};
