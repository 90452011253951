const getTimeNumFromDateStr = (dateStr: string) =>
  // Incoming date strings might be in ANY of the following formats:
  //   2023-04-06 20:30:00
  //   2023-04-06T20:30:50Z
  //   2023-04-06T20:30:50.371Z
  // We want the following, so that we can compare numerically:
  //   2030
  Number(
    dateStr
      .replace(/^\d{4}-\d{2}-\d{2}[ T]/, '')
      .replace(/Z$/, '')
      .replace(/\.\d+$/, '')
      .replace(/:\d{2}$/, '')
      .replace(/:/, '')
  );

export const isHour2AfterHour1 = (dateStr1: string, dateStr2: string) => {
  const timeNum1 = getTimeNumFromDateStr(dateStr1);
  const timeNum2 = getTimeNumFromDateStr(dateStr2);

  return timeNum2 > timeNum1;
};
