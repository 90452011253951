import { useQuery } from 'react-apollo';

import {
  EventAttendeeApiResponse,
  GetEventAttendeeVariables,
} from 'app/typings/eventAttendees';
import { queryParams } from 'app/shared/utils/queries';
import { GET_EVENT_ATTENDEE_FOR_ADMIN } from 'app/admin/graphql/eventAttendees/queries';

export const GetEventAttendeeForAdmin = (
  params: GetEventAttendeeVariables = {}
) => {
  return useQuery<EventAttendeeApiResponse>(
    GET_EVENT_ATTENDEE_FOR_ADMIN,
    queryParams(params)
  );
};
