import React from 'react';
import { useIntl } from 'react-intl';

import { useCurrentTheme } from 'app/shared/theme';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import {
  Box,
  Divider,
  Grid,
  GridItem,
  Logo,
  Responsive,
  Typography,
} from 'app/shared/components/atoms';
import { NavigationLink as Link } from 'app/shared/components/atoms';
import { BasePageBox, SocialIcons } from 'app/shared/components/molecules';

import { BottomSection } from './BottomSection';
import { SiteLinks } from './SiteLinks';
import { TopCities } from './TopCities';

interface Props {
  'data-qaid'?: string;
  footerRef: React.RefObject<HTMLDivElement>;
}

export const FullFooter: React.FC<Props> = ({
  'data-qaid': qaId,
  footerRef,
}) => {
  const intl = useIntl();
  const { isMobile } = useIsBreakpointSize();
  const { colors, zIndex } = useCurrentTheme();

  const sofarMission = () => {
    return (
      <>
        <Link variant="tertiary" href="/">
          <Logo
            fillColor={colors.primary.white.base}
            variant="primary"
            height={30}
          />
        </Link>
        <Typography
          color={colors.primary.white.base}
          pt={5}
          fontSize="fontSize100"
        >
          {intl.formatMessage({ id: 'footer.sofarMission' })}
        </Typography>
      </>
    );
  };

  return (
    <Box
      h="100%"
      w="100%"
      data-qaid={qaId}
      justifyContent="center"
      alignContent="center"
      flexDirection="column"
      alignItems="center"
      backgroundColor={colors.primary.black.base}
      zIndex={zIndex.navbar}
      ref={footerRef}
    >
      <Divider color={colors.accent.primaryAccent.base} />
      <Box ml={0} w="100%">
        <BasePageBox py={3}>
          <SocialIcons />
        </BasePageBox>
      </Box>
      <Divider color={colors.accent.primaryAccent.base} />
      <Responsive showOnSizes={['mobile']}>
        <Box>
          <BasePageBox py={6}>{sofarMission()}</BasePageBox>
          <Divider color={colors.accent.primaryAccent.base} />
          <BasePageBox>
            <SiteLinks />
          </BasePageBox>
        </Box>
      </Responsive>
      <Responsive showUp="tablet">
        <BasePageBox>
          <Grid numberOfColumns={2} gap={0}>
            <GridItem
              mobileStart={1}
              tabletStart={1}
              smallDesktopStart={1}
              largeDesktopStart={1}
              mobile={2}
              tablet={1}
              smallDesktop={1}
              largeDesktop={1}
              py={6}
              pr={[0, 5, 16, 18]}
              flexDirection="column"
              justifyContent="center"
            >
              {sofarMission()}
            </GridItem>
            <GridItem
              mobileStart={1}
              tabletStart={2}
              smallDesktopStart={2}
              largeDesktopStart={2}
              mobile={2}
              tablet={2}
              smallDesktop={2}
              largeDesktop={2}
              bl={isMobile ? 'none' : colors.accent.primaryAccent.base}
              pl={[0, 5, 16, 18]}
              py={6}
            >
              <SiteLinks />
            </GridItem>
          </Grid>
        </BasePageBox>
      </Responsive>
      <Divider color={colors.accent.primaryAccent.base} />
      <BasePageBox>
        <TopCities />
      </BasePageBox>
      <Divider color={colors.accent.primaryAccent.base} />
      <BottomSection />
    </Box>
  );
};
