import { useMutation } from 'react-apollo';

import {
  ArchiveCrewMessageData,
  CreateCrewMessageData,
  UpdateStaffMemberData,
} from 'app/typings/staffMembers';
import {
  ARCHIVE_CREW_MESSAGE,
  CREATE_CREW_MESSAGE,
  UPDATE_STAFF_MEMBER,
} from 'app/admin/graphql/staffMembers/mutations';

export const UpdateStaffMember = () => {
  return useMutation<UpdateStaffMemberData>(UPDATE_STAFF_MEMBER)[0];
};

export const CreateCrewMessage = () => {
  return useMutation<CreateCrewMessageData>(CREATE_CREW_MESSAGE)[0];
};

export const ArchiveCrewMessage = () => {
  return useMutation<ArchiveCrewMessageData>(ARCHIVE_CREW_MESSAGE)[0];
};
