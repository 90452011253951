import { useQuery } from 'react-apollo';

import {
  GetCompetitionTemplatesData,
  GetCompetitionTemplatesVariables,
} from 'app/typings/competitionTemplates';
import { queryParams } from 'app/shared/utils/queries';
import { GET_COMPETITION_TEMPLATES } from 'app/admin/graphql/competitionTemplates/queries';

export const GetCompetitionTemplates = (
  params: GetCompetitionTemplatesVariables = {}
) => {
  return useQuery<GetCompetitionTemplatesData>(
    GET_COMPETITION_TEMPLATES,
    queryParams(params)
  );
};
