import gql from 'graphql-tag';

export const GET_CANCELLATION_REASONS = gql`
  query GetCancellationReasons($cancellableType: String!) {
    cancellationReasons(cancellableType: $cancellableType) {
      cancellationReasons {
        id
        cancellableType
        description
      }
    }
  }
`;
