import { User } from 'app/typings/users';

interface NavConfigProps {
  intl?: any;
  signOut?: Function;
  afterSignOut?: Function;
  user?: User | null;
}

export const navConfig = ({
  intl,
  signOut,
  afterSignOut,
  user,
}: NavConfigProps) => {
  const formatMessage = intl?.formatMessage || (() => '');

  const navLoggedOutConfig = [
    {
      title: formatMessage({ id: 'navbar.signIn' }),
      key: 'navbar-sign-in',
      linkStyle: 'alwaysOnLink',
      parentItemType: 'auth',
      childMenu: [],
    },
  ];

  const navLoggedInConfig = [
    {
      title: formatMessage({ id: 'navbar.myAccount' }),
      key: 'navbar-my-account',
      linkStyle: 'alwaysOnLink',
      mobileOnly: true,
      childMenu: [
        {
          title: formatMessage({
            id: 'navbar.myAccount.dashboard',
          }),
          key: 'navbar-my-account-profile',
          link: '/dashboard',
        },
        {
          title: formatMessage({
            id: 'navbar.myAccount.editProfile',
          }),
          key: 'navbar-my-account-edit-profile',
          link: '/profile/edit',
        },
        {
          title: formatMessage({
            id: 'navbar.myAccount.concertOrganizerPortal',
          }),
          key: 'navbar-my-account-concert-organizer-portal',
          link: '/admin/concert-portal',
          display: !!user?.isInternal,
        },
        {
          title: formatMessage({
            id: 'navbar.myAccount.artistPortal',
          }),
          key: 'navbar-my-account-artist-portal',
          link: '/artist/dashboard',
          display: !!user?.isArtist,
        },
        {
          title: formatMessage({ id: 'navbar.myAccount.signOut' }),
          key: 'navbar-my-account-sign-out',
          onClick: async () => {
            signOut && (await signOut());
            afterSignOut && afterSignOut();
          },
        },
      ],
    },
  ];

  const navSharedConfig = [
    {
      title: formatMessage({ id: 'navbar.concerts' }),
      key: 'navbar-concerts',
      linkStyle: 'button',
      childMenu: [
        {
          title: formatMessage({
            id: 'navbar.concerts.discovery',
          }),
          key: 'navbar-concerts-discovery',
          link: '/',
        },
        {
          title: formatMessage({
            id: 'navbar.concerts.privateConcerts',
          }),
          key: 'navbar-concerts-private-discovery',
          link: '/private-concerts',
        },
      ],
    },
    {
      title: formatMessage({ id: 'navbar.artists' }),
      key: 'navbar-artists',
      linkStyle: 'button',
      childMenu: [
        {
          title: formatMessage({
            id: 'navbar.artists.theArtistExperience',
          }),
          key: 'navbar-artists-the-artist-experience',
          link: '/about/artists/how-it-works-playing',
        },
        {
          title: formatMessage({
            id: 'navbar.artists.applyToSofar',
          }),
          key: 'navbar-artists-apply-to-sofar',
          link: '/about/artists',
        },
        {
          title: formatMessage({
            id: 'navbar.artists.artistResources',
          }),
          key: 'navbar-artists-artist-resources',
          link: '/v/artistresources',
        },
      ],
    },
    {
      title: formatMessage({ id: 'navbar.getInvolved' }),
      linkStyle: 'button',
      key: 'navbar-get-involved',
      childMenu: [
        {
          title: formatMessage({
            id: 'navbar.getInvolved.curator',
          }),
          key: 'navbar-get-involved-curate',
          link: '/about/curators',
        },
        {
          title: formatMessage({
            id: 'navbar.getInvolved.host',
          }),
          key: 'navbar-get-involved-host',
          link: '/about/hosts',
        },
        {
          title: formatMessage({
            id: 'navbar.getInvolved.partnerships',
          }),
          key: 'navbar-get-involved-partner',
          link: '/partnerships',
        },
      ],
    },
    {
      title: formatMessage({ id: 'navbar.discover' }),
      key: 'navbar-discover',
      childMenu: [
        {
          title: formatMessage({ id: 'navbar.discover.blog' }),
          key: 'navbar-discover-blog',
          link: '/blog',
          openInNewTab: true,
        },
        {
          title: formatMessage({ id: 'navbar.discover.videos' }),
          key: 'navbar-discover-videos',
          link: '/videos',
        },
        {
          title: formatMessage({
            id: 'navbar.discover.listeningRoom',
          }),
          key: 'navbar-discover-listening-room',
          link: '/v/listeningroom',
        },
        {
          title: formatMessage({
            id: 'navbar.discover.livestreamArchive',
          }),
          key: 'navbar-discover-livestream-archive',
          link: '/v/listeningroom/past-streams',
        },
      ],
    },
    {
      title: formatMessage({ id: 'navbar.help' }),
      key: 'navbar-help',
      childMenu: [
        {
          title: 'Help Center',
          key: 'navbar-help-center',
          link: 'https://help.sofarsounds.com',
          openInNewTab: true,
        },
      ],
    },
  ];

  return {
    navLoggedOutConfig,
    navLoggedInConfig,
    navSharedConfig,
  };
};
