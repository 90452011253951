import gql from 'graphql-tag';

export const GET_COUNTRIES_FULL_INFO = gql`
  query GetCountriesFullInfo(
    $countrySearch: String
    $continent: String
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    countries(
      countrySearch: $countrySearch
      continent: $continent
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      countries {
        id
        title
        continent {
          id
          title
        }
        countryCode
        citiesCount
        cachedSlug
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_COUNTRY_FULL_INFO = gql`
  query GetCountry($id: Int) {
    country(id: $id) {
      id
      title
      titlesByLocale {
        locale
        title
      }
      continent {
        id
        title
      }
      countryCode
      citiesCount
      cachedSlug
      aliases
      currency
      currencySymbol
      displayBookingFee
      bookingFee
      isTieredArtistCompensationEnabled
      artistCompensation
      artistCompensationTier1
      artistCompensationTier2
      artistCompensationTier3
      artistCompensationTier4
      artistCompensationTier5
      exchangeRateWithUsd
      artistsCount
      venuesCount
      pastEventsCount
    }
  }
`;
