import { useMutation } from 'react-apollo';
import apolloClient from 'src/apollo/sofarApolloClient';

import {
  CreateSignedFormData,
  CreateSignedFormVariables,
  DeleteSignedFormData,
} from 'app/typings/signedForms';
import {
  CREATE_SIGNED_FORM,
  DELETE_SIGNED_FORM,
} from 'app/admin/graphql/signedForms/mutations';

export const CreateSignedForm = async (data: CreateSignedFormVariables) => {
  return apolloClient.mutate<CreateSignedFormData, CreateSignedFormVariables>({
    mutation: CREATE_SIGNED_FORM,
    variables: data,
  });
};

export const DeleteSignedForm = () => {
  return useMutation<DeleteSignedFormData>(DELETE_SIGNED_FORM)[0];
};
