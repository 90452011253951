import { useMutation } from 'react-apollo';

import {
  ArchiveThemeApiResponse,
  CreateThemeApiResponse,
  UpdateThemeApiResponse,
} from 'app/typings/themes';
import {
  ARCHIVE_THEME,
  CREATE_THEME,
  UPDATE_THEME,
} from 'app/admin/graphql/themes/mutations';

export const ArchiveTheme = () => {
  return useMutation<ArchiveThemeApiResponse>(ARCHIVE_THEME)[0];
};

export const CreateTheme = () => {
  return useMutation<CreateThemeApiResponse>(CREATE_THEME)[0];
};

export const UpdateTheme = () => {
  return useMutation<UpdateThemeApiResponse>(UPDATE_THEME)[0];
};
