import { useQuery } from 'react-apollo';

import {
  GetVipAttendeeData,
  GetVipAttendeeVariables,
} from 'app/typings/vipAttendees';
import { queryParams } from 'app/shared/utils/queries';
import { GET_VIP_ATTENDEE } from 'app/admin/graphql/vipAttendees/queries';

export const GetVipAttendee = (params: GetVipAttendeeVariables = {}) => {
  return useQuery<GetVipAttendeeData>(GET_VIP_ATTENDEE, queryParams(params));
};
