import { useMutation } from 'react-apollo';

import {
  CreateCountryData,
  DeleteCountryData,
  UpdateCountryData,
} from 'app/typings/countries';
import {
  CREATE_COUNTRY,
  DELETE_COUNTRY,
  UPDATE_COUNTRY,
} from 'app/admin/graphql/countries/mutations';

export const CreateCountry = () => {
  return useMutation<CreateCountryData>(CREATE_COUNTRY)[0];
};

export const DeleteCountry = () => {
  return useMutation<DeleteCountryData>(DELETE_COUNTRY)[0];
};

export const UpdateCountry = () => {
  return useMutation<UpdateCountryData>(UPDATE_COUNTRY)[0];
};
