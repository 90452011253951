import { useMutation } from 'react-apollo';

import {
  CancelPromoCodeData,
  CreatePromoCodesData,
  UpdatePromoCodeData,
} from 'app/typings/promoCodes';
import {
  CANCEL_PROMO_CODE,
  CREATE_PROMO_CODES,
  UPDATE_PROMO_CODE,
} from 'app/admin/graphql/promoCodes/mutations';

export const CancelPromoCode = () => {
  return useMutation<CancelPromoCodeData>(CANCEL_PROMO_CODE)[0];
};

export const CreatePromoCodes = () => {
  return useMutation<CreatePromoCodesData>(CREATE_PROMO_CODES)[0];
};

export const UpdatePromoCode = () => {
  return useMutation<UpdatePromoCodeData>(UPDATE_PROMO_CODE)[0];
};
