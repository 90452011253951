export const eventTypeDisplayConfig = {
  CommercialPartnershipEvent: {
    displayVenue: true,
    displayCapacity: true,
    displaySuggestedTickets: true,
    displayTotalHeadcount: false,
    displayGuestsProgressBar: false,
    displayTicketsAvailable: true,
    displayTicketsConfirmed: true,
    displayTicketPrice: false,
    displayBookingFee: false,
    displayMaxTicketsPerAttendee: true,
    displayTotalGuests: true,
    displayMaxPaInputs: true,
    displayIsFilmed: true,
    displayRevenue: false,
    displayDealAmount: true,
    displayPromoCodeDetails: true,
    displayOnSaleAt: true,
    displayOnPresale: false,
    displayIsArtistSelfBookingEnabled: false,
    displayArtistFacingPageUrl: false,
    cachedSlugPublicPageUrl: '',
    displayManagePage: true,
    displayIsAutoInviteEnabled: false,
    displayIsGloballyPromoted: false,
    displayPartnerInformation: true,
  },
  DiscoveryEvent: {
    displayVenue: true,
    displayCapacity: true,
    displaySuggestedTickets: true,
    displayTotalHeadcount: true,
    displayGuestsProgressBar: true,
    displayTicketsAvailable: true,
    displayTicketsConfirmed: true,
    displayTicketPrice: true,
    displayBookingFee: true,
    displayMaxTicketsPerAttendee: true,
    displayTotalGuests: true,
    displayMaxPaInputs: true,
    displayIsFilmed: true,
    displayRevenue: true,
    displayDealAmount: false,
    displayPromoCodeDetails: true,
    displayOnSaleAt: true,
    displayOnPresale: true,
    displayIsArtistSelfBookingEnabled: true,
    displayArtistFacingPageUrl: false,
    cachedSlugPublicPageUrl: '',
    displayManagePage: true,
    displayIsAutoInviteEnabled: true,
    displayIsGloballyPromoted: true,
    displayPartnerInformation: true,
  },
  FeaturedSetEvent: {
    displayVenue: true,
    displayCapacity: true,
    displaySuggestedTickets: true,
    displayTotalHeadcount: true,
    displayGuestsProgressBar: true,
    displayTicketsAvailable: true,
    displayTicketsConfirmed: true,
    displayTicketPrice: true,
    displayBookingFee: false,
    displayMaxTicketsPerAttendee: true,
    displayTotalGuests: true,
    displayMaxPaInputs: true,
    displayIsFilmed: true,
    displayRevenue: true,
    displayDealAmount: false,
    displayPromoCodeDetails: true,
    displayOnSaleAt: true,
    displayOnPresale: false,
    displayIsArtistSelfBookingEnabled: false,
    displayArtistFacingPageUrl: true,
    cachedSlugPublicPageUrl: 'featuring',
    displayManagePage: false,
    displayIsAutoInviteEnabled: false,
    displayIsGloballyPromoted: true,
    displayPartnerInformation: false,
  },
  PrivateDiscoveryEvent: {
    displayVenue: true,
    displayCapacity: true,
    displaySuggestedTickets: true,
    displayTotalHeadcount: false,
    displayGuestsProgressBar: false,
    displayTicketsAvailable: true,
    displayTicketsConfirmed: true,
    displayTicketPrice: false,
    displayBookingFee: false,
    displayMaxTicketsPerAttendee: true,
    displayTotalGuests: true,
    displayMaxPaInputs: true,
    displayIsFilmed: true,
    displayRevenue: false,
    displayDealAmount: true,
    displayPromoCodeDetails: true,
    displayOnSaleAt: true,
    displayOnPresale: false,
    displayIsArtistSelfBookingEnabled: false,
    displayArtistFacingPageUrl: false,
    cachedSlugPublicPageUrl: '',
    displayManagePage: true,
    displayIsAutoInviteEnabled: false,
    displayIsGloballyPromoted: false,
    displayPartnerInformation: true,
  },
};
