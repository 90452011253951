import { useMutation } from 'react-apollo';
import apolloClient from 'src/apollo/sofarApolloClient';

import {
  ArchiveVenueData,
  CreateVenueData,
  CreateVenuePhotoData,
  CreateVenuePhotoVariables,
  DeleteVenuePhotoData,
  UpdateVenueData,
  UpdateVenuePhotoData,
  UpdateVenuePhotoVariables,
  Venue,
} from 'app/typings/venues';
import {
  ARCHIVE_VENUE,
  CREATE_VENUE,
  CREATE_VENUE_PHOTO,
  DELETE_VENUE_PHOTO,
  RESET_VENUE_TERMS_AND_CONDITIONS,
  UPDATE_VENUE,
  UPDATE_VENUE_PHOTO,
} from 'app/admin/graphql/venues/mutations';

interface ResetVenueTermsAndConditionsData {
  success: boolean;
  venue: Venue;
}

export const ArchiveVenue = () => {
  return useMutation<ArchiveVenueData>(ARCHIVE_VENUE)[0];
};

export const CreateVenue = () => {
  return useMutation<CreateVenueData>(CREATE_VENUE)[0];
};

export const DeleteVenuePhoto = () => {
  return useMutation<DeleteVenuePhotoData>(DELETE_VENUE_PHOTO)[0];
};

export const ResetVenueTermsAndConditions = () => {
  return useMutation<ResetVenueTermsAndConditionsData>(
    RESET_VENUE_TERMS_AND_CONDITIONS
  )[0];
};

export const UpdateVenue = () => {
  return useMutation<UpdateVenueData>(UPDATE_VENUE)[0];
};

export const createVenuePhoto = async (data: CreateVenuePhotoVariables) => {
  return apolloClient.mutate<CreateVenuePhotoData, CreateVenuePhotoVariables>({
    mutation: CREATE_VENUE_PHOTO,
    variables: data,
  });
};

export const updateVenuePhoto = async (data: UpdateVenuePhotoVariables) => {
  return apolloClient.mutate<UpdateVenuePhotoData, UpdateVenuePhotoVariables>({
    mutation: UPDATE_VENUE_PHOTO,
    variables: data,
  });
};
