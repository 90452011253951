import { useQuery } from 'react-apollo';

import {
  GetCompetitionData,
  GetCompetitionsData,
  GetCompetitionsVariables,
  GetCompetitionVariables,
} from 'app/typings/competitions';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_COMPETITION,
  GET_COMPETITIONS,
} from 'app/admin/graphql/competitions/queries';

export const GetCompetition = (params: GetCompetitionVariables = {}) => {
  return useQuery<GetCompetitionData>(GET_COMPETITION, queryParams(params));
};

export const GetCompetitions = (params: GetCompetitionsVariables = {}) => {
  return useQuery<GetCompetitionsData>(GET_COMPETITIONS, queryParams(params));
};
