import gql from 'graphql-tag';

export const GET_EVENT_FORMAT_EXPERIMENTS = gql`
  query GetEventFormatExperiments {
    eventFormatExperiments {
      eventFormatExperiments {
        id
        title
      }
    }
  }
`;
