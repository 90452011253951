import { useMutation } from 'react-apollo';

import { Event, EventPreview } from 'app/typings';

import {
  CREATE_EVENT_STAFF_MEMBER,
  CREATE_EVENTS,
  DELETE_EVENT_STAFF_MEMBER,
  UPDATE_EVENT,
  UPDATE_EVENT_STAFF_MEMBER,
} from './mutations';

export interface CreateEventsDataResponse {
  events: {
    __typename?: string;
    events: EventPreview[];
    metadata: {
      totalRecords: number;
      currentPage: number;
      __typename?: string;
    };
  };
  success: boolean;
}

export const CreateEvents = () => {
  return useMutation<CreateEventsDataResponse>(CREATE_EVENTS)[0];
};

export interface CreateEventStaffMemberApiResponse {
  success: boolean;
  event: Event;
}

export const CreateEventStaffMember = () => {
  return useMutation<CreateEventStaffMemberApiResponse>(
    CREATE_EVENT_STAFF_MEMBER
  )[0];
};

export interface UpdateEventStaffMemberApiResponse {
  success: boolean;
  event: Event;
}

export const UpdateEventStaffMember = () => {
  return useMutation<UpdateEventStaffMemberApiResponse>(
    UPDATE_EVENT_STAFF_MEMBER
  )[0];
};

export interface DeleteEventStaffMemberApiResponse {
  success: boolean;
  event: Event;
}

export const DeleteEventStaffMember = () => {
  return useMutation<DeleteEventStaffMemberApiResponse>(
    DELETE_EVENT_STAFF_MEMBER
  )[0];
};

export interface UpdateEventDataResponse {
  updateEvent: {
    __typename?: string;
    event: EventPreview;
    success: boolean;
  };
}

export const UpdateEvent = () => {
  return useMutation<UpdateEventDataResponse>(UPDATE_EVENT)[0];
};
