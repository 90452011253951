import { useQuery } from 'react-apollo';

import {
  GetEventCollectionData,
  GetEventCollectionsData,
  GetEventCollectionsVariables,
  GetEventCollectionVariables,
} from 'app/typings/eventCollections';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_EVENT_COLLECTION_FOR_ADMIN,
  GET_EVENT_COLLECTIONS_FOR_ADMIN,
} from 'app/admin/graphql/eventCollections/queries';

export const GetEventCollectionForAdmin = (
  params: GetEventCollectionVariables
) => {
  return useQuery<GetEventCollectionData>(
    GET_EVENT_COLLECTION_FOR_ADMIN,
    queryParams(params)
  );
};

export const GetEventCollectionsForAdmin = (
  params: GetEventCollectionsVariables
) => {
  return useQuery<GetEventCollectionsData>(
    GET_EVENT_COLLECTIONS_FOR_ADMIN,
    queryParams(params)
  );
};
