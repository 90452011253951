import gql from 'graphql-tag';

export const CREATE_PRIMARY_ROLE = gql`
  mutation createPrimaryRole(
    $displayName: String!
    $shortDisplayName: String
    $resourceType: String
  ) {
    createPrimaryRole(
      displayName: $displayName
      shortDisplayName: $shortDisplayName
      resourceType: $resourceType
    ) {
      success
      primaryRole {
        id
        key
        name
        shortName
        resourceType
      }
    }
  }
`;

export const DELETE_PRIMARY_ROLE = gql`
  mutation deletePrimaryRole($primaryRoleId: Int!) {
    deletePrimaryRole(primaryRoleId: $primaryRoleId) {
      success
    }
  }
`;

export const UPDATE_PRIMARY_ROLE = gql`
  mutation updatePrimaryRole(
    $primaryRoleId: Int!
    $displayName: String!
    $shortDisplayName: String
  ) {
    updatePrimaryRole(
      primaryRoleId: $primaryRoleId
      displayName: $displayName
      shortDisplayName: $shortDisplayName
    ) {
      success
      primaryRole {
        id
        key
        name
        shortName
        resourceType
      }
    }
  }
`;
