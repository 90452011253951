import gql from 'graphql-tag';

export const GET_ATTENDEES = gql`
  query GetAttendees(
    $eventId: Int!
    $attendeeSearch: String
    $status: String
    $vipStatus: String
    $appliedPromoCodeStatus: String
    $isAttending: Boolean
    $orderBy: String
    $orderDirection: String
  ) {
    attendees(
      eventId: $eventId
      attendeeSearch: $attendeeSearch
      status: $status
      vipStatus: $vipStatus
      appliedPromoCodeStatus: $appliedPromoCodeStatus
      isAttending: $isAttending
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      attendees {
        id
        name
        email
        eventAttendee {
          id
          currentState
          ticketsCount
          ticketsAttendedCount
          totalTicketCost
          homeCityTitle
          generalNotes
          occasion
          occasionDetails
          isTransferred
          appliedPromoCodeCode
          externalConfirmationId
          pastEventsCount
          tickets {
            id
            email
            firstName
            lastName
            primary
            hasAttended
          }
        }
        vipAttendee {
          id
          fullName
          email
          reference
          ticketsCount
          ticketsAttendedCount
          createdById
        }
      }
      attendeesMetadata {
        ticketsCounts {
          ticketsCountTotalForResults
          ticketsCountStatusApplied
          ticketsCountStatusGuestlisted
          ticketsCountStatusConfirmed
          ticketsCountStatusCantGo
          ticketsCountStatusExpired
          ticketsCountIsVip
          ticketsCountHasAppliedPromoCode
        }
      }
    }
  }
`;
