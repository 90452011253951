import gql from 'graphql-tag';

export const DELETE_VIDEO = gql`
  mutation deleteVideo($videoId: Int!) {
    deleteVideo(videoId: $videoId) {
      success
    }
  }
`;

export const UPDATE_VIDEO = gql`
  mutation updateVideo(
    $videoId: Int
    $performanceId: Int
    $title: String
    $description: String
    $link: String
    $showOnFeaturedVideos: Boolean
  ) {
    updateVideo(
      videoId: $videoId
      performanceId: $performanceId
      title: $title
      description: $description
      link: $link
      showOnFeaturedVideos: $showOnFeaturedVideos
    ) {
      video {
        id
        url
        displayName
        description
        performance {
          id
        }
        showOnFeaturedVideos
      }
      success
    }
  }
`;
