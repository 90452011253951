export const cityOperatingModelLabels = {
  curator: 'Curator',
  sofar_operated: 'O&O',
  sofar_operated_and_curator: 'O&O and Curator',
};

export const cityOperatingModelLabelsShort = {
  curator: 'Curator',
  sofar_operated: 'O&O',
  sofar_operated_and_curator: 'O&O/Curator',
};

export const sofarOperatedMarketTypeLabels = {
  market_type_small: 'Small',
  market_type_developing: 'Developing',
  market_type_growing: 'Growing',
  market_type_established: 'Established',
};

export const sofarOperatedMarketTypeLabelsShort = {
  market_type_small: 'Small',
  market_type_developing: 'Developing',
  market_type_growing: 'Growing',
  market_type_established: 'Established',
};
