import React from 'react';

import { Icon } from 'app/shared/components/atoms';
import { Box } from 'app/shared/components/atoms';
import { NavigationLink as Link } from 'app/shared/components/atoms';
import { ActionLink } from 'app/shared/components/atoms';

interface LinkProps {
  title?: string;
  isActive?: boolean;
  dataQaid?: string;
  href?: string;
  onClick?: (arg: any) => void;
  openInNewTab?: boolean;
  invertColors?: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const RenderLink: React.FC<LinkProps> = ({
  title,
  dataQaid,
  openInNewTab = false,
  href,
  invertColors,
  icon,
  ...props
}) => {
  const target = openInNewTab ? '_blank' : '_self';
  const rel = openInNewTab ? 'noopener' : undefined;

  if (href) {
    return (
      <Link
        lineHeight="100%"
        textDecoration="none !important"
        variant={invertColors ? 'primary' : 'secondary'}
        fontWeight="medium"
        data-qaid={dataQaid}
        href={href}
        target={target}
        rel={rel}
        display="flex"
        w="-webkit-fill-available"
        {...props}
      >
        {title}
      </Link>
    );
  }

  return (
    <ActionLink
      lineHeight="100%"
      textDecoration="none !important"
      variant={invertColors ? 'primary' : 'secondary'}
      data-qaid={dataQaid}
      display="flex"
      w="-webkit-fill-available"
      fontWeight="medium"
      alignItems="center"
      svgFillColor="none"
      {...props}
    >
      {icon && (
        <Box mr={1}>
          <Icon size="md" icon={icon} />
        </Box>
      )}
      {title}
    </ActionLink>
  );
};
