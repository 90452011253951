import React from 'react';

import { sofarSoundsSocialUrls } from 'app/shared/utils/socialLinks';
import { Box, Icon } from 'app/shared/components/atoms';
import { NavigationLink as Link } from 'app/shared/components/atoms';
import { ReactComponent as Facebook } from 'icons/facebook.svg';
import { ReactComponent as Instagram } from 'icons/instagram.svg';
import { ReactComponent as Spotify } from 'icons/spotify.svg';
import { ReactComponent as Tiktok } from 'icons/tiktok.svg';
import { ReactComponent as Twitter } from 'icons/twitter.svg';
import { ReactComponent as Youtube } from 'icons/youtube.svg';

type SocialLink = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  url: string;
  key: string;
  w?: string;
  fillColor?: string;
};
interface Props {
  'data-qaid'?: string;
  socialLinks?: SocialLink[];
}

const defaultSocialLinks: SocialLink[] = [
  {
    icon: Instagram,
    url: sofarSoundsSocialUrls.instagram,
    key: 'instagram',
  },
  { icon: Tiktok, url: sofarSoundsSocialUrls.tikTok, key: 'tiktok' },
  { icon: Spotify, url: sofarSoundsSocialUrls.spotify, key: 'spotify' },
  { icon: Youtube, url: sofarSoundsSocialUrls.youtube, key: 'youtube' },
  { icon: Twitter, url: sofarSoundsSocialUrls.twitter, key: 'twitter' },
  { icon: Facebook, url: sofarSoundsSocialUrls.facebook, key: 'facebook' },
];

export const SocialIcons: React.FC<Props> = ({
  'data-qaid': qaId = 'sofar-social-icons',
  socialLinks = defaultSocialLinks,
}) => {
  return (
    <Box data-qaid={qaId} overflow="visible" alignContent="middle" gap={5}>
      {socialLinks.map(({ url, icon, key, w, fillColor }) => (
        <Link
          variant="tertiary"
          key={url}
          href={url}
          openInNewTab={true}
          svgFillColor={fillColor}
        >
          <Icon icon={icon} size="md" w={w} data-qaid={`${qaId}-${key}`} />
        </Link>
      ))}
    </Box>
  );
};

export default SocialIcons;
