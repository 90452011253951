import { object as YupObject, string as YupString } from 'yup';

import { get } from 'app/shared/utils/get';
import { validateUrlNoHttp } from 'app/shared/utils/urls';

export const sofarSoundsSocialUrls = {
  facebook: 'http://www.facebook.com/sofarsounds',
  youtube: 'http://www.youtube.com/sofarsounds',
  twitter: 'http://www.twitter.com/sofarsounds',
  instagram: 'http://www.instagram.com/sofarsounds',
  spotify:
    'https://open.spotify.com/artist/4Xo8wtSEkrCKEVqioeNlTk?si=ZOpr-FtgQIuQM3xbvJa2wA',
  tikTok: 'https://www.tiktok.com/@sofarsounds',
};

// See also similar SocialUrlsConfig in backend

// Order of entries below is SIGNIFICANT - i.e. the order social links are displayed in in UI everywhere
export const artistSocialUrls = [
  'urlFacebook',
  'urlTwitter',
  'urlWebsite',
  'urlSoundcloud',
  'urlYoutube',
  'urlInstagram',
  'urlBandcamp',
  'urlSpotify',
  'urlSupport',
];

// Order of entries below is SIGNIFICANT - i.e. the order social links are displayed in in UI everywhere
export const citySocialUrls = [
  'urlFacebook',
  'urlTwitter',
  'urlYoutube',
  'urlInstagram',
  'urlVKontacte',
  'urlSpotify',
];

// Order of entries below is SIGNIFICANT - i.e. the order social links are displayed in in UI everywhere
export const venueSocialUrls = [
  'urlWebsite',
  'urlFacebook',
  'urlInstagram',
  'urlTwitter',
];

// Please keep in alpha order by key
export const socialUrls = {
  urlBandcamp: {
    name: 'Bandcamp',
  },
  urlFacebook: {
    name: 'Facebook',
  },
  urlInstagram: {
    name: 'Instagram',
  },
  urlSoundcloud: {
    name: 'SoundCloud',
  },
  urlSpotify: {
    name: 'Spotify',
  },
  urlSupport: {
    name: 'Support',
  },
  urlTwitter: {
    name: 'Twitter',
  },
  urlVKontacte: {
    name: 'VKontakte',
  },
  urlWebsite: {
    name: 'Website',
  },
  urlYoutube: {
    name: 'YouTube',
  },
};

export const getSocialUrlsFormInitialValues = (
  socialUrlKeys: string[],
  contentProps: any = {},
  dataKey = 'socialUrls'
) =>
  socialUrlKeys.reduce(
    (obj, key) => ({
      ...obj,
      [key]: get(contentProps, `${dataKey}.${key}`, '') || '',
    }),
    {}
  );

export const getSocialUrlsSubmitVariable = (values: any) =>
  Object.keys(socialUrls)
    .filter((key: string) => values.socialUrls.hasOwnProperty(key))
    .reduce(
      (obj, key) => ({ ...obj, [key]: values.socialUrls[key] || null }),
      {}
    );

export const getSocialUrlsFormSchema = (intl: any) =>
  YupObject().shape(
    Object.keys(socialUrls).reduce(
      (obj, key) => ({
        ...obj,
        [key]: YupString()
          .nullable()
          .test(
            'isUrl',
            intl.formatMessage(
              {
                id: 'form.fieldMustBeValidUrl',
              },
              {
                field: socialUrls[key].name,
              }
            ),
            function(value) {
              return value ? validateUrlNoHttp(value) : true;
            }
          ),
      }),
      {}
    )
  );
