import { useQuery } from 'react-apollo';

import {
  GetPerformingRightsOrganisationsData,
  GetPerformingRightsOrganisationsVariables,
} from 'app/typings/performingRightsOrganisations';
import { queryParams } from 'app/shared/utils/queries';
import { GET_PERFORMING_RIGHTS_ORGANISATIONS } from 'app/admin/graphql/performingRightsOrganisations/queries';

export const GetPerformingRightsOrganisations = (
  params: GetPerformingRightsOrganisationsVariables = {}
) => {
  return useQuery<GetPerformingRightsOrganisationsData>(
    GET_PERFORMING_RIGHTS_ORGANISATIONS,
    queryParams(params)
  );
};
