import gql from 'graphql-tag';

export const CREATE_CURATOR_GROUP = gql`
  mutation createCuratorGroup(
    $name: String
    $cityId: Int
    $curatorGroupType: String
  ) {
    createCuratorGroup(
      name: $name
      cityId: $cityId
      curatorGroupType: $curatorGroupType
    ) {
      success
      curatorGroup {
        id
      }
    }
  }
`;

export const UPDATE_CURATOR_GROUP = gql`
  mutation updateCuratorGroup(
    $curatorGroupId: Int!
    $name: String
    $curatorGroupType: String
    $status: String
    $useFromEmailForAllEmails: Boolean
    $fromEmail: String
    $artistOpsId: Int
    $crewOpsId: Int
    $venueOpsId: Int
    $eventPrice: Int
  ) {
    updateCuratorGroup(
      curatorGroupId: $curatorGroupId
      name: $name
      curatorGroupType: $curatorGroupType
      status: $status
      useFromEmailForAllEmails: $useFromEmailForAllEmails
      fromEmail: $fromEmail
      artistOpsId: $artistOpsId
      crewOpsId: $crewOpsId
      venueOpsId: $venueOpsId
      eventPrice: $eventPrice
    ) {
      success
      curatorGroup {
        id
        name
        city {
          id
          title
        }
        curatorGroupType
        status
      }
    }
  }
`;
