const optionKeyForValue = (options: any, value: any) => {
  const matchingOptions =
    options && options.filter((opt: any) => opt.value === value);
  return matchingOptions[0] && matchingOptions[0].key
    ? matchingOptions[0].key
    : null;
};

const optionValueForKey = (options: any, key: any) => {
  const matchingOptions =
    options && options.filter((opt: any) => opt.key && opt.key === key);
  return matchingOptions[0] ? matchingOptions[0].value : null;
};

const valueDependsOnValue2 = (
  options: any,
  optionDependencies: any,
  value: string,
  value2: string
) => {
  const key = optionKeyForValue(options, value);
  const key2 = optionKeyForValue(options, value2);
  return (
    optionDependencies && key && key2 && optionDependencies[key2].includes(key)
  );
};

const valueDependsOnAnySelectedValue = (
  options: any,
  optionDependencies: any,
  selectedValues: any[],
  value: string
) => {
  const key = optionKeyForValue(options, value);
  return (
    optionDependencies &&
    key &&
    Object.keys(optionDependencies).some(
      k =>
        optionDependencies[k].includes(key) &&
        selectedValues.includes(optionValueForKey(options, k))
    )
  );
};

export const defaultIsDisabledState = (
  options: any,
  optionDependencies: any,
  selectedValues: any[]
) => {
  return options.reduce(function(vals: any, obj: any) {
    vals[obj.value] =
      valueDependsOnAnySelectedValue(
        options,
        optionDependencies,
        selectedValues,
        obj.value
      ) || obj.disabled;
    return vals;
  }, {});
};

export const handleOptionChange = (
  options: any,
  optionDependencies: any,
  selectedValues: any[],
  isDisabled: object,
  setIsDisabled: Function,
  onChange: Function,
  optIsSelected: boolean,
  opt: any
) => {
  if (optIsSelected) {
    if (optionDependencies && opt.key && optionDependencies[opt.key]) {
      setIsDisabled(
        options.reduce(function(vals: any, obj: any) {
          vals[obj.value] =
            valueDependsOnValue2(
              options,
              optionDependencies,
              obj.value,
              opt.value
            ) || isDisabled[obj.value];
          return vals;
        }, {})
      );
      onChange([
        ...selectedValues.filter(
          val =>
            !valueDependsOnValue2(options, optionDependencies, val, opt.value)
        ),
        opt.value,
      ]);
    } else {
      onChange([...selectedValues, opt.value]);
    }
  } else {
    if (optionDependencies && opt.key && optionDependencies[opt.key]) {
      setIsDisabled(
        options.reduce(function(vals: any, obj: any) {
          vals[obj.value] =
            !valueDependsOnValue2(
              options,
              optionDependencies,
              obj.value,
              opt.value
            ) && isDisabled[obj.value];
          return vals;
        }, {})
      );
    }
    onChange(selectedValues.filter(val => val !== opt.value));
  }
};

export const isOptionSelected = (selectedValues: any[], opt: any) => {
  return selectedValues.includes(opt.value);
};
