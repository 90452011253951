import gql from 'graphql-tag';

export const CREATE_ARTIST_FEEDBACK = gql`
  mutation createArtistFeedback(
    $eventId: Int!
    $artistId: Int!
    $feedbackPhrasesIds: [Int!]!
    $overallFeeling: String!
  ) {
    createArtistFeedback(
      eventId: $eventId
      artistId: $artistId
      feedbackPhrasesIds: $feedbackPhrasesIds
      overallFeeling: $overallFeeling
    ) {
      success
      feedback {
        id
        feedbackSubjectId
        feedbackSubjectType
        feedbackContext
        feedbackPieces {
          id
          feedbackPhrase {
            phrase
            isPositive
          }
        }
        overallFeeling
        feedbackableAt
        feedbackableRelatedData {
          key
          value
        }
        createdBy {
          id
        }
        createdAt
      }
    }
  }
`;

export const CREATE_EVENT_FEEDBACK = gql`
  mutation createEventFeedback(
    $eventId: Int!
    $eventRating: Int!
    $artistFeedbacks: [ArtistFeedbackInput!]!
    $eventFeedbackNotes: String
    $mcOverallFeeling: String!
    $venueOverallFeeling: String!
    $venueComfortOverallFeeling: String!
  ) {
    createEventFeedback(
      eventId: $eventId
      eventRating: $eventRating
      artistFeedbacks: $artistFeedbacks
      eventFeedbackNotes: $eventFeedbackNotes
      mcOverallFeeling: $mcOverallFeeling
      venueOverallFeeling: $venueOverallFeeling
      venueComfortOverallFeeling: $venueComfortOverallFeeling
    ) {
      success
    }
  }
`;

export const DELETE_FEEDBACK = gql`
  mutation deleteFeedback($feedbackId: Int!) {
    deleteFeedback(feedbackId: $feedbackId) {
      success
    }
  }
`;
