import gql from 'graphql-tag';

export const GET_COMMENTS = gql`
  query GetComments(
    $page: Int
    $perPage: Int
    $commentableId: Int!
    $commentableType: String!
    $orderBy: String
    $orderDirection: String
  ) {
    comments(
      page: $page
      perPage: $perPage
      commentableId: $commentableId
      commentableType: $commentableType
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      comments {
        id
        body
        updatedAt
        user {
          email
          firstName
          lastName
          id
        }
      }
      metadata {
        currentPage
        totalRecords
      }
    }
  }
`;
