import gql from 'graphql-tag';

export const GET_EMAIL_MESSAGE = gql`
  query GetEmailMessage($id: Int!) {
    emailMessage(id: $id) {
      id
      emailKey
      emailName
      subject
      recipient
      sender
      hasContent
      content
      status
      numOpens
      numClicks
      messagable {
        id
        objectType
        name
      }
      domainObject {
        id
        objectType
        name
        timezone
      }
      sentBy {
        id
        firstName
        lastName
      }
      createdAt
    }
  }
`;

export const GET_EMAIL_MESSAGES = gql`
  query GetEmailMessages(
    $emailMessageSearch: String
    $emailKey: String
    $status: String
    $date: String
    $eventId: String
    $curatorGroup: String
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
  ) {
    emailMessages(
      emailMessageSearch: $emailMessageSearch
      emailKey: $emailKey
      status: $status
      date: $date
      eventId: $eventId
      curatorGroup: $curatorGroup
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      emailMessages {
        id
        emailKey
        emailName
        subject
        recipient
        sender
        hasContent
        status
        numOpens
        numClicks
        messagable {
          id
          objectType
          name
        }
        domainObject {
          id
          objectType
          name
          timezone
        }
        sentBy {
          id
          firstName
          lastName
        }
        createdAt
      }
      metadata {
        currentPage
        totalRecords
      }
    }
  }
`;
