import { useQuery } from 'react-apollo';

import {
  GetVenueApplicationsData,
  GetVenueApplicationsVariables,
} from 'app/typings/venueApplications';
import { queryParams } from 'app/shared/utils/queries';
import { GET_VENUE_APPLICATIONS } from 'app/admin/graphql/venueApplications/queries';

export const GetVenueApplications = (
  params: GetVenueApplicationsVariables = {}
) => {
  return useQuery<GetVenueApplicationsData>(
    GET_VENUE_APPLICATIONS,
    queryParams(params)
  );
};
