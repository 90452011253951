import gql from 'graphql-tag';

export const GET_VIP_ATTENDEE = gql`
  query GetVipAttendee($id: Int!) {
    vipAttendee(id: $id) {
      id
      fullName
      email
      ticketsCount
      reference
      createdById
    }
  }
`;
