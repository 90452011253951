import { useMutation } from 'react-apollo';

import { CREATE_GDPR_DATA_REMOVAL_PROCESS } from 'app/admin/graphql/gdpr/mutations';

interface CreateGdprDataRemovalProcessData {
  success: boolean;
}

export const CreateGdprDataRemovalProcess = () => {
  return useMutation<CreateGdprDataRemovalProcessData>(
    CREATE_GDPR_DATA_REMOVAL_PROCESS
  )[0];
};
