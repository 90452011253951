import { useQuery } from 'react-apollo';

import {
  GetOfferData,
  GetOffersData,
  GetOffersVariables,
  GetOfferVariables,
} from 'app/typings/offers';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_OFFER_FOR_OFFER_DETAIL,
  GET_OFFERS,
} from 'app/admin/graphql/offers/queries';

export const GetOfferForOfferDetail = (params: GetOfferVariables = {}) => {
  return useQuery<GetOfferData>(
    GET_OFFER_FOR_OFFER_DETAIL,
    queryParams(params)
  );
};

export const GetOffers = (params: GetOffersVariables) => {
  return useQuery<GetOffersData>(GET_OFFERS, queryParams(params));
};
