import gql from 'graphql-tag';

import { artistSocialUrls } from 'app/shared/utils/socialLinks';

export const MERGE_ARTISTS = gql`
  mutation mergeArtists(
    $artistId1: Int!
    $artistId2: Int!
    $title: String
    $cachedSlug: String
    $description: String
    $email: String
    $homeCityDescriptionArtistId: Int
    $mainImageArtistId: Int
    $socialUrls: SocialUrlsInput
  ) {
    mergeArtists(
      artistId1: $artistId1
      artistId2: $artistId2
      title: $title
      cachedSlug: $cachedSlug
      description: $description
      email: $email
      homeCityDescriptionArtistId: $homeCityDescriptionArtistId
      mainImageArtistId: $mainImageArtistId
      socialUrls: $socialUrls
    ) {
      success
      artist {
        id
        title
        cachedSlug
        description
        email
        homeCityDescription
        homeCity
        homeState
        homeCountryCode
        homeCityLatitude
        homeCityLongitude
        imageUrl
        socialUrls {
          ${artistSocialUrls.join(' ')}
        }
      }
    }
  }
`;
