import gql from 'graphql-tag';

export const CREATE_COMPETITION_TEMPLATE = gql`
  mutation createCompetitionTemplate($templateName: String!) {
    createCompetitionTemplate(templateName: $templateName) {
      success
      competitionTemplate {
        id
        templateName
      }
    }
  }
`;

export const DELETE_COMPETITION_TEMPLATE = gql`
  mutation deleteCompetitionTemplate($competitionTemplateId: Int!) {
    deleteCompetitionTemplate(competitionTemplateId: $competitionTemplateId) {
      success
    }
  }
`;

export const UPDATE_COMPETITION_TEMPLATE = gql`
  mutation updateCompetitionTemplate(
    $competitionTemplateId: Int!
    $templateName: String!
    $title: String
    $cityId: Int
    $maxWinners: Int
    $intro: String
    $thirdPartyCtaText: String
    $thirdPartyCtaLink: String
    $subtitle: String
    $subtitleTwo: String
    $description: String
    $postDescription: String
    $winnerCopy: String
    $expiredCopy: String
    $moreInfoTitle: String
    $moreInfoSubheading: String
    $moreInfoPreview: String
    $featuredContentCtaText: String
    $featuredContentCtaLink: String
    $turnOnOptIn: Boolean
    $optInCopy: String
    $shareTitle: String
    $shareCopy: String
  ) {
    updateCompetitionTemplate(
      competitionTemplateId: $competitionTemplateId
      templateName: $templateName
      title: $title
      cityId: $cityId
      maxWinners: $maxWinners
      intro: $intro
      thirdPartyCtaText: $thirdPartyCtaText
      thirdPartyCtaLink: $thirdPartyCtaLink
      subtitle: $subtitle
      subtitleTwo: $subtitleTwo
      description: $description
      postDescription: $postDescription
      winnerCopy: $winnerCopy
      expiredCopy: $expiredCopy
      moreInfoTitle: $moreInfoTitle
      moreInfoSubheading: $moreInfoSubheading
      moreInfoPreview: $moreInfoPreview
      featuredContentCtaText: $featuredContentCtaText
      featuredContentCtaLink: $featuredContentCtaLink
      turnOnOptIn: $turnOnOptIn
      optInCopy: $optInCopy
      shareTitle: $shareTitle
      shareCopy: $shareCopy
    ) {
      success
      competitionTemplate {
        id
        templateName
        title
        city {
          id
          title
        }
        maxWinners
        intro
        thirdPartyCtaText
        thirdPartyCtaLink
        subtitle
        subtitleTwo
        description
        postDescription
        winnerCopy
        expiredCopy
        moreInfoTitle
        moreInfoSubheading
        moreInfoPreview
        featuredContentCtaText
        featuredContentCtaLink
        turnOnOptIn
        optInCopy
        shareTitle
        shareCopy
      }
    }
  }
`;
