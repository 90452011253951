import { EventCollection, EventForCityPage } from 'app/typings';
import { EventForEventCollection } from 'app/typings';

import { useAnalyticsContext } from './useAnalyticsContext';

interface GetAnalyticsProps {
  events: {
    id: string;
    isPurchasable: boolean;
    isSoldOut: boolean;
    cancelled: boolean;
  }[];
  eventCollection: EventCollection;
  collectionDisplayRank: number;
  cityId?: number;
  imageSrc?: string;
}

export const useEventCollectionAnalytics = () => {
  const { trackAnalyticsEvent } = useAnalyticsContext();

  function getAnalyticsProps({
    events,
    eventCollection,
    imageSrc,
    cityId,
    collectionDisplayRank,
  }: GetAnalyticsProps) {
    const visibleEventIds = events.map(event => event.id);
    const visiblePurchasableEventIds = events
      .filter(e => {
        return e.isPurchasable && !e.isSoldOut && !e.cancelled;
      })
      .map(e => e.id);

    const filenameParts = imageSrc?.split('?')[0].split('/');
    const filename = filenameParts
      ? filenameParts[filenameParts.length - 1]
      : null;

    return {
      city_id: cityId,
      visible_event_ids: visibleEventIds,
      visible_events_count: events.length,
      visible_purchasable_event_ids: visiblePurchasableEventIds,
      visible_purchasable_events_count: visiblePurchasableEventIds.length,
      event_collection_id: eventCollection.id,
      event_collection_title: eventCollection.title,
      event_collection_cached_slug: eventCollection.cachedSlug,
      event_collection_description: eventCollection.description,
      event_collection_what_to_expect: eventCollection.whatToExpect,
      event_collection_display_rank: collectionDisplayRank,
      event_collection_image_url: imageSrc || null,
      event_collection_image_filename: filename,
    };
  }

  const trackCollectionDisplayed = (props: GetAnalyticsProps) => {
    const analyticsProps = getAnalyticsProps({ ...props });

    trackAnalyticsEvent('Collection Displayed', analyticsProps, true);
  };

  const trackCollectionProductClicked = ({
    event,
    index,
    eventCollection,
    ...props
  }: GetAnalyticsProps & {
    event: EventForEventCollection | EventForCityPage;
    index: number;
  }) => {
    const analyticsProps = getAnalyticsProps({ eventCollection, ...props });

    trackAnalyticsEvent('Collection Product Clicked', {
      event_id: event.id,
      event_display_rank: index,
      ...analyticsProps,
    });
  };

  return {
    trackCollectionDisplayed,
    trackCollectionProductClicked,
  };
};
