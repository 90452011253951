import { useMutation } from 'react-apollo';

import {
  SendEmailData,
  SendEventEmailData,
  SendUrgentEmailData,
  UpdateEventEmailsData,
} from 'app/typings/emails';
import {
  SEND_EMAIL,
  SEND_EVENT_EMAIL,
  SEND_URGENT_EMAIL,
  UPDATE_EVENT_EMAILS,
} from 'app/admin/graphql/emails/mutations';

export const SendEmail = () => {
  return useMutation<SendEmailData>(SEND_EMAIL)[0];
};

export const SendEventEmail = () => {
  return useMutation<SendEventEmailData>(SEND_EVENT_EMAIL)[0];
};

export const SendUrgentEmail = () => {
  return useMutation<SendUrgentEmailData>(SEND_URGENT_EMAIL)[0];
};

export const UpdateEventEmails = () => {
  return useMutation<UpdateEventEmailsData>(UPDATE_EVENT_EMAILS)[0];
};
