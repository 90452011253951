import { Artist } from 'app/typings/artists';
import { ArtistSetup } from 'app/typings/artistSetups';
import { BookingPreference } from 'app/typings/bookingPreferences';
import { Performance } from 'app/typings/performances';
import { Property } from 'app/typings/properties';

export const bookingStatusFilters = {
  should_not_book: 'Do Not Book',
  is_self_booking_enabled: 'Artist can self-book',
  needs_review: 'Artist needs review',
};

export const bookingStatusLabels = {
  should_not_book: 'Do Not Book',
  is_self_booking_enabled: 'Artist can self-book',
};

export const artistTitleWithEmail = (artist: Artist | undefined) =>
  artist && `${artist.title} (${artist.email || 'No email'})`;

export const getArtistSetupName = (artistSetup: ArtistSetup) => {
  const setupName = artistSetup.setupName;
  return artistSetup.isPrimarySetup ? `${setupName} (primary)` : setupName;
};

export const getBookingPreferenceCities = (
  bookingPreferences: BookingPreference[] | undefined
) => {
  if (bookingPreferences) {
    const availableLastMinuteCities = bookingPreferences
      .filter(
        (bookingPreference: BookingPreference) =>
          bookingPreference.isAvailableLastMinute && !bookingPreference.isLegacy
      )
      .map((bookingPreference: BookingPreference) => {
        return { name: bookingPreference.city.title };
      });

    const generalAvailabilityCities = bookingPreferences
      .filter(
        (bookingPreference: BookingPreference) =>
          bookingPreference.isGenerallyAvailable && !bookingPreference.isLegacy
      )
      .map((bookingPreference: BookingPreference) => {
        return { name: bookingPreference.city.title };
      });

    const specificAvailabilityCities = bookingPreferences
      .filter(
        (bookingPreference: BookingPreference) =>
          bookingPreference.isAvailableOnCertainDates
      )
      .map((bookingPreference: BookingPreference) => {
        return { name: bookingPreference.city.title };
      });

    const legacyAvailabilityCities = bookingPreferences
      .filter(
        (bookingPreference: BookingPreference) => bookingPreference.isLegacy
      )
      .map((bookingPreference: BookingPreference) => {
        return { name: bookingPreference.city.title };
      });

    return {
      availableLastMinuteCities,
      generalAvailabilityCities,
      specificAvailabilityCities,
      legacyAvailabilityCities,
    };
  } else {
    return {
      availableLastMinuteCities: null,
      generalAvailabilityCities: null,
      specificAvailabilityCities: null,
      legacyAvailabilityCities: null,
    };
  }
};

export const getSpecificAvailabilityCitiesAndDates = (
  bookingPreferences: BookingPreference[] | undefined
) => {
  if (!bookingPreferences) {
    return;
  }

  let specificAvailabilityCities = bookingPreferences.filter(
    (bookingPreference: BookingPreference) =>
      bookingPreference.isAvailableOnCertainDates &&
      bookingPreference.artistBookingAvailabilityDates.length > 0
  );

  if (specificAvailabilityCities) {
    let specificAvailabilityCitiesAndDates = bookingPreferences
      .filter(
        (bookingPreference: BookingPreference) =>
          bookingPreference.isAvailableOnCertainDates
      )
      .map((bookingPreference: BookingPreference) => {
        return {
          city: bookingPreference.city.title,
          bookingAvailabilityDates:
            bookingPreference.artistBookingAvailabilityDates,
        };
      });
    return specificAvailabilityCitiesAndDates;
  } else {
    return;
  }
};

export const demographicGroupNames = (intl: any) => ({
  Age: intl.formatMessage({
    id: 'artistProfile.title.age',
  }),
  'Gender Identity': intl.formatMessage({
    id: 'artistProfile.title.genderIdentity',
  }),
  'Race Ethnicity': intl.formatMessage({
    id: 'artistProfile.title.raceEthnicity',
  }),
  'Sexual Orientation': intl.formatMessage({
    id: 'artistProfile.title.sexualOrientation',
  }),
  'Transgender Identification': intl.formatMessage({
    id: 'artistProfile.title.transgenderIdentification',
  }),
  'Used Languages': intl.formatMessage({
    id: 'artistProfile.title.usedLanguages',
  }),
});

export const getArtistPronouns = (intl: any, pronouns: Property[]) => {
  return pronouns.map((property: Property) => ({
    title: intl.formatMessage({
      id: `artistProfile.checkboxOption.${property.key}`,
    }),
  }));
};

export const performancesForPositions = (
  performances: Performance[],
  positions: any
) => {
  const positionMap = {};
  performances.forEach((performance: Performance) => {
    if (performance.position) {
      positionMap[performance.position] = performance;
    }
  });
  return positions.map((pos: number) => positionMap[pos] || null);
};
