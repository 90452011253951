import gql from 'graphql-tag';

export const GET_PERFORMING_RIGHTS_ORGANISATIONS = gql`
  query GetPerformingRightsOrganisations {
    performingRightsOrganisations {
      performingRightsOrganisations {
        id
        title
        country {
          id
          title
        }
      }
    }
  }
`;
