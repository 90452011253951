import { useMutation } from 'react-apollo';

import {
  CreateVipAttendeeData,
  DeleteVipAttendeeData,
  UpdateVipAttendeeData,
} from 'app/typings/vipAttendees';
import {
  CREATE_VIP_ATTENDEE,
  DELETE_VIP_ATTENDEE,
  UPDATE_VIP_ATTENDEE,
} from 'app/admin/graphql/vipAttendees/mutations';

export const CreateVipAttendee = () => {
  return useMutation<CreateVipAttendeeData>(CREATE_VIP_ATTENDEE)[0];
};

export const DeleteVipAttendee = () => {
  return useMutation<DeleteVipAttendeeData>(DELETE_VIP_ATTENDEE)[0];
};

export const UpdateVipAttendee = () => {
  return useMutation<UpdateVipAttendeeData>(UPDATE_VIP_ATTENDEE)[0];
};
