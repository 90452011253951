import gql from 'graphql-tag';

export const GET_EVENT_ATTENDEE_FOR_ADMIN = gql`
  query GetEventAttendeeForAdmin($id: Int) {
    eventAttendee(id: $id) {
      id
      currentState
      fullName
      email
      ticketsCount
      ticketsAttendedCount
      totalTicketCost
      homeCityTitle
      generalNotes
      occasion
      occasionDetails
      isTransferred
      appliedPromoCodeCode
      createdAt
      userLastEventAt
      userPastEventsCount
      tickets {
        id
        firstName
        lastName
        email
        primary
      }
    }
  }
`;
