import gql from 'graphql-tag';

export const GET_BUSINESS_OWNERS = gql`
  query GetBusinessOwners(
    $archivedStatus: String
    $businessOwnerSearch: String
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    businessOwners(
      archivedStatus: $archivedStatus
      businessOwnerSearch: $businessOwnerSearch
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      businessOwners {
        id
        title
        citiesCount
        cachedSlug
        createdAt
      }
      metadata {
        totalRecords
      }
    }
  }
`;
