import { useQuery } from 'react-apollo';

import {
  GetCuratorGroupData,
  GetCuratorGroupsData,
  GetCuratorGroupsVariables,
  GetCuratorGroupVariables,
} from 'app/typings/curatorGroups';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_CURATOR_GROUP,
  GET_CURATOR_GROUPS,
  GET_CURATOR_GROUPS_LITE,
} from 'app/admin/graphql/curatorGroups/queries';

const liteQueryParams = (params: any) =>
  Object.assign(params, { isLiteQuery: true });

export const GetCuratorGroup = (params: GetCuratorGroupVariables = {}) => {
  return useQuery<GetCuratorGroupData>(GET_CURATOR_GROUP, queryParams(params));
};

export const GetCuratorGroups = (params: GetCuratorGroupsVariables = {}) => {
  return useQuery<GetCuratorGroupsData>(
    GET_CURATOR_GROUPS,
    queryParams(params)
  );
};

export const GetCuratorGroupsLite = (
  params: GetCuratorGroupsVariables = {}
) => {
  return useQuery<GetCuratorGroupsData>(
    GET_CURATOR_GROUPS_LITE,
    queryParams(liteQueryParams(params))
  );
};
