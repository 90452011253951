import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { City } from 'app/typings';
import { useCurrentTheme } from 'app/shared/theme';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { GetCitiesForWebsiteFooter } from 'app/shared/graphql/cities/queryHooks';
import { WithFlexProps } from 'app/shared/components/styleUtils/manualCSS/withFlex';
import { Box, Typography } from 'app/shared/components/atoms';
import { NavigationLink as Link } from 'app/shared/components/atoms';
import { IconButton } from 'app/shared/components/molecules/IconButton';
import { ReactComponent as Down } from 'icons/chevron-down.svg';
import { ReactComponent as Up } from 'icons/chevron-up.svg';

interface Props extends WithFlexProps {
  show: boolean;
}

interface TopCityProps extends WithFlexProps {
  city: City;
}

const TopCity: React.FC<TopCityProps> = ({ city }) => {
  const { colors } = useCurrentTheme();

  return (
    <Link
      href={`/cities/${city.cachedSlug}`}
      data-qaid={`footer-top-cities-${city.title}`}
      color={colors.primary.white.base}
      textDecoration="none"
      textTransform="uppercase"
      fontSize="fontSize50"
      fontWeight="medium"
      variant="tertiary"
    >
      {city.title}
    </Link>
  );
};

const TopCitiesCore = ({
  show,
  flexDirection,
  justifyContent,
  flexWrap,
  gap,
}: Props) => {
  const {
    loading: loadingTopCities,
    error: errorTopCities,
    data: dataTopCities,
  } = GetCitiesForWebsiteFooter({
    topCity: true,
  });

  // This is for the website footer displayed on ALL public-facing pages, so no, we don't want
  // to display loading or error indicators or any kind while loading or if something goes wrong -
  // just display nothing
  if (errorTopCities || loadingTopCities || !dataTopCities) {
    return null;
  }

  return (
    <Box display={show ? 'flex' : 'none'}>
      <Box
        flexDirection={flexDirection}
        justifyContent={justifyContent}
        flexWrap={flexWrap}
        gap={gap}
        py={6}
      >
        {dataTopCities?.cities?.cities?.map((city: City, index: number) => (
          <TopCity city={city} key={index} />
        ))}
      </Box>
    </Box>
  );
};

const TopCitiesMobile = () => {
  const { colors } = useCurrentTheme();

  const intl = useIntl();
  const [isMobileTopCitiesExpanded, setIsMobileTopCitiesExpanded] = useState(
    false
  );
  return (
    <Box py={9}>
      <Box
        py={0}
        px={0}
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
      >
        <Typography
          color={colors.primary.white.base}
          textTransform="uppercase"
          fontSize="fontSize50"
        >
          {intl.formatMessage({ id: 'footer.mostVisitedCities' })}
        </Typography>
        <IconButton
          icon={isMobileTopCitiesExpanded ? Up : Down}
          size="md"
          strokeColor={colors.primary.white.base}
          backgroundColor="transparent"
          onClick={() => {
            setIsMobileTopCitiesExpanded(
              isMobileTopCitiesExpanded => !isMobileTopCitiesExpanded
            );
          }}
          p={2}
        />
      </Box>
      <TopCitiesCore
        flexDirection="column"
        flexWrap="nowrap"
        justifyContent="flex-start"
        show={isMobileTopCitiesExpanded}
        gap={2}
      />
    </Box>
  );
};

const TopCitiesDesktop = () => {
  return (
    <TopCitiesCore
      show={true}
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="center"
      gap={4}
    />
  );
};

export const TopCities = () => {
  const { isMobile } = useIsBreakpointSize();
  if (isMobile) {
    return <TopCitiesMobile />;
  }
  return <TopCitiesDesktop />;
};
