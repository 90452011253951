import gql from 'graphql-tag';

export const SEND_EMAIL = gql`
  mutation sendEmail($emailKey: String!, $venueId: Int) {
    sendEmail(emailKey: $emailKey, venueId: $venueId) {
      success
    }
  }
`;

export const SEND_EVENT_EMAIL = gql`
  mutation sendEventEmail(
    $emailKey: String!
    $sendType: String
    $eventId: Int
    $artistId: Int
    $eventAttendeeId: Int
    $vipAttendeeId: Int
    $staffUserId: Int
  ) {
    sendEventEmail(
      emailKey: $emailKey
      sendType: $sendType
      eventId: $eventId
      artistId: $artistId
      eventAttendeeId: $eventAttendeeId
      vipAttendeeId: $vipAttendeeId
      staffUserId: $staffUserId
    ) {
      success
      email {
        id
        emailName
        emailGroup
        isDisabled
        description
        longDescription
        isEmailCountsEnabled
        numSent
        numTotal
        isPreviewable
        isSendable
        numAllPotentialRecipients
        numNewPotentialRecipients
      }
    }
  }
`;

export const SEND_URGENT_EMAIL = gql`
  mutation sendUrgentEmail(
    $emailKey: String!
    $eventId: Int!
    $recipientType: String
    $emailSubject: String
    $emailBody: String
    $newAddress: String
  ) {
    sendUrgentEmail(
      emailKey: $emailKey
      eventId: $eventId
      recipientType: $recipientType
      emailSubject: $emailSubject
      emailBody: $emailBody
      newAddress: $newAddress
    ) {
      success
      emailKey
    }
  }
`;

export const UPDATE_EVENT_EMAILS = gql`
  mutation UpdateEventEmails(
    $eventId: Int!
    $useFromEmailForAllEmails: Boolean
    $fromEmail: String
    $artistOpsId: Int
    $crewOpsId: Int
    $venueOpsId: Int
    $eventEmailSettings: [EventEmailSetting!]!
  ) {
    updateEventEmails(
      eventId: $eventId
      useFromEmailForAllEmails: $useFromEmailForAllEmails
      fromEmail: $fromEmail
      artistOpsId: $artistOpsId
      crewOpsId: $crewOpsId
      venueOpsId: $venueOpsId
      eventEmailSettings: $eventEmailSettings
    ) {
      success
      event {
        id
        useFromEmailForAllEmails
        fromEmail
        mainArtistOps {
          id
          firstName
          lastName
          email
        }
        mainCrewOps {
          id
          firstName
          lastName
          email
        }
        mainVenueOps {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;
