import { useMutation } from 'react-apollo';

import {
  CreateNeighborhoodData,
  DeleteNeighborhoodData,
  UpdateNeighborhoodData,
} from 'app/typings/neighborhoods';
import {
  CREATE_NEIGHBORHOOD,
  DELETE_NEIGHBORHOOD,
  UPDATE_NEIGHBORHOOD,
} from 'app/admin/graphql/neighborhoods/mutations';

export const CreateNeighborhood = () => {
  return useMutation<CreateNeighborhoodData>(CREATE_NEIGHBORHOOD)[0];
};

export const DeleteNeighborhood = () => {
  return useMutation<DeleteNeighborhoodData>(DELETE_NEIGHBORHOOD)[0];
};

export const UpdateNeighborhood = () => {
  return useMutation<UpdateNeighborhoodData>(UPDATE_NEIGHBORHOOD)[0];
};
