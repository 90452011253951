import React from 'react';
import { useIntl } from 'react-intl';

import { useCurrentTheme } from 'app/shared/theme';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { Box } from 'app/shared/components/atoms';
import { NavigationLink as Link } from 'app/shared/components/atoms';

export const SiteLinks = () => {
  const { formatMessage } = useIntl();
  const { isMobile, isTablet } = useIsBreakpointSize();
  const { ruler } = useCurrentTheme();

  return (
    <Box
      gap={3}
      h="100%"
      flexWrap={isMobile || isTablet ? 'wrap' : 'nowrap'}
      py={[6, 0, 0, 0]}
      justifyContent="space-between"
    >
      <Box
        bottom="0px"
        gap={3}
        flexDirection="column"
        flex={`1 1 calc(50% - ${ruler[3]}px)`}
        justifyContent="space-between"
        w="fit-content"
      >
        <Link
          href="https://help.sofarsounds.com/hc/en-us"
          data-qaid="footer-help-center"
          variant="tertiary"
          textDecoration="none"
          fontSize="fontSize100"
          letterSpacing="0px"
          w="fit-content"
        >
          {formatMessage({ id: 'footer.helpCenter' })}
        </Link>
        <Link
          href="/careers"
          data-qaid="footer-careers"
          variant="tertiary"
          textDecoration="none"
          fontSize="fontSize100"
          letterSpacing="0px"
          w="fit-content"
        >
          {formatMessage({ id: 'footer.careers' })}
        </Link>
        <Link
          href="/contact"
          data-qaid="footer-contact"
          variant="tertiary"
          textDecoration="none"
          fontSize="fontSize100"
          letterSpacing="0px"
          w="fit-content"
        >
          {formatMessage({ id: 'footer.contactUs' })}
        </Link>
      </Box>
      <Box
        gap={3}
        flexDirection="column"
        justifyContent="space-between"
        w="fit-content"
        flex={`1 1 calc(50% - ${ruler[3]}px)`}
      >
        <Link
          href="/about"
          data-qaid="footer-our-story"
          variant="tertiary"
          textDecoration="none"
          fontSize="fontSize100"
          letterSpacing="0px"
          w="fit-content"
        >
          {formatMessage({ id: 'footer.ourStory' })}
        </Link>
        <Link
          href="/about/what-is-sofar"
          data-qaid="footer-sofar-experience"
          variant="tertiary"
          textDecoration="none"
          fontSize="fontSize100"
          letterSpacing="0px"
          w="fit-content"
        >
          {formatMessage({ id: 'footer.theSofarExperience' })}
        </Link>
        <Link
          href="/community-guidelines"
          data-qaid="footer-community-guidelines"
          variant="tertiary"
          textDecoration="none"
          fontSize="fontSize100"
          letterSpacing="0px"
          w="max-content"
        >
          {formatMessage({ id: 'footer.communityGuidelines' })}
        </Link>
      </Box>
    </Box>
  );
};
