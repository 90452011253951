import { useMutation } from 'react-apollo';

import {
  ArchiveVenueApplicationData,
  CreateVenueFromVenueApplicationData,
} from 'app/typings/venueApplications';
import {
  ARCHIVE_VENUE_APPLICATION,
  CREATE_VENUE_FROM_VENUE_APPLICATION,
} from 'app/admin/graphql/venueApplications/mutations';

export const ArchiveVenueApplication = () => {
  return useMutation<ArchiveVenueApplicationData>(ARCHIVE_VENUE_APPLICATION)[0];
};

export const CreateVenueFromVenueApplication = () => {
  return useMutation<CreateVenueFromVenueApplicationData>(
    CREATE_VENUE_FROM_VENUE_APPLICATION
  )[0];
};
