import gql from 'graphql-tag';

export const GET_FEEDBACK_PHRASES = gql`
  query GetFeedbackPhrases {
    feedbackPhrases {
      feedbackPhrases {
        id
        phrase
        isPositive
      }
      metadata {
        totalRecords
      }
    }
  }
`;

export const GET_FEEDBACKS = gql`
  query GetFeedbacks(
    $feedbackSubjectId: Int!
    $feedbackSubjectType: String!
    $feedbackContext: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    feedbacks(
      feedbackSubjectId: $feedbackSubjectId
      feedbackSubjectType: $feedbackSubjectType
      feedbackContext: $feedbackContext
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      feedbacks {
        id
        feedbackSubjectId
        feedbackSubjectType
        feedbackContext
        feedbackPieces {
          id
          feedbackPhrase {
            phrase
            isPositive
          }
        }
        overallFeeling
        feedbackableAt
        feedbackableRelatedData {
          key
          value
        }
        createdBy {
          id
        }
        createdAt
      }
      topFeedbacks {
        ops {
          id
          phrase
          isPositive
          percentage
        }
        crew {
          id
          phrase
          isPositive
          percentage
        }
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;
