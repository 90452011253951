import gql from 'graphql-tag';

export const ACCEPT_ARTIST_APPLICATION = gql`
  mutation acceptArtistApplication(
    $id: Int!
    $customEmailMessage: String
    $status: String!
  ) {
    acceptArtistApplication(
      id: $id
      customEmailMessage: $customEmailMessage
      status: $status
    ) {
      success
      artist {
        id
        title
        imageUrl
        homeCityDescription
      }
    }
  }
`;

export const REJECT_ARTIST_APPLICATION = gql`
  mutation rejectArtistApplication(
    $id: Int!
    $customEmailMessage: String
    $status: String!
  ) {
    rejectArtistApplication(
      id: $id
      customEmailMessage: $customEmailMessage
      status: $status
    ) {
      success
    }
  }
`;

export const UPDATE_ARTIST_APPLICATION_REFERRER = gql`
  mutation updateArtistApplicationReferrer(
    $id: Int!
    $removeReferrer: Boolean
  ) {
    updateArtistApplicationReferrer(id: $id, removeReferrer: $removeReferrer) {
      success
      artistApplication {
        id
        referrer {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
