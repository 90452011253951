import { TitleByLocale } from 'app/typings/titleByLocale';
import { get } from 'app/shared/utils/get';
import { upperFirst } from 'app/shared/utils/string';

export const titleLocales = {
  en: 'English',
  es: 'Spanish',
  it: 'Italian',
};

export const getTitleFormInitialValue = (data: any, locale: string) => {
  const matchingTitleObj = get(data, 'titlesByLocale', '')
    ? get(data, 'titlesByLocale', '').find(
        (titleObj: TitleByLocale) => titleObj.locale === locale
      )
    : null;
  return matchingTitleObj && matchingTitleObj.title !== null
    ? matchingTitleObj.title
    : '';
};

export const getTitlesSubmitVariable = (values: any) =>
  Object.keys(titleLocales).map((locale: string) => ({
    locale,
    title: values[`title${upperFirst(locale)}`],
  }));
