import gql from 'graphql-tag';

export const CANCEL_PROMO_CODE = gql`
  mutation cancelPromoCode($id: Int!) {
    cancelPromoCode(id: $id) {
      success
      promoCode {
        id
      }
    }
  }
`;

export const CREATE_PROMO_CODES = gql`
  mutation createPromoCodes(
    $numPromoCodes: Int
    $curatorGroupId: Int
    $campaignId: Int
    $type: String
    $codePrefix: String
    $includePostfix: Boolean
    $generationReasonId: Int
    $percentageDiscount: Int
    $customPrices: [PromoCodeCustomPriceInput!]
    $validFrom: String
    $expiresAt: String
    $maxUses: Int
    $maxUsesPerUser: Int
    $ruleTypes: [String!]
    $ruleDayOfWeek: String
    $ruleDaysOfWeek: [String!]
    $ruleCityId: Int
    $ruleCityIds: [Int!]
    $ruleCountryId: Int
    $ruleEventId: Int
    $ruleEventType: String
    $ruleEventOwnerTypes: [String!]
    $rulePurchaserType: String
  ) {
    createPromoCodes(
      numPromoCodes: $numPromoCodes
      curatorGroupId: $curatorGroupId
      campaignId: $campaignId
      type: $type
      codePrefix: $codePrefix
      includePostfix: $includePostfix
      generationReasonId: $generationReasonId
      percentageDiscount: $percentageDiscount
      customPrices: $customPrices
      validFrom: $validFrom
      expiresAt: $expiresAt
      maxUses: $maxUses
      maxUsesPerUser: $maxUsesPerUser
      ruleTypes: $ruleTypes
      ruleDayOfWeek: $ruleDayOfWeek
      ruleDaysOfWeek: $ruleDaysOfWeek
      ruleCityId: $ruleCityId
      ruleCityIds: $ruleCityIds
      ruleCountryId: $ruleCountryId
      ruleEventId: $ruleEventId
      ruleEventType: $ruleEventType
      ruleEventOwnerTypes: $ruleEventOwnerTypes
      rulePurchaserType: $rulePurchaserType
    ) {
      success
      promoCode {
        id
        code
        type
        typeName
        validFrom
        expiresAt
        campaign {
          id
          title
        }
        generationReason {
          id
          description
        }
        percentageDiscount
        customPrices {
          numTickets
          price
        }
        numTimesUsed
        numTicketsPurchased
        status
        createdBy {
          id
          firstName
          lastName
          email
        }
        createdAt
      }
    }
  }
`;

export const UPDATE_PROMO_CODE = gql`
  mutation updatePromoCode(
    $promoCodeId: Int!
    $campaignId: Int
    $type: String
    $generationReasonId: Int
    $percentageDiscount: Int
    $customPrices: [PromoCodeCustomPriceInput!]
    $validFrom: String
    $expiresAt: String
    $maxUses: Int
    $maxUsesPerUser: Int
    $ruleTypes: [String!]
    $ruleDayOfWeek: String
    $ruleDaysOfWeek: [String!]
    $ruleCityId: Int
    $ruleCityIds: [Int!]
    $ruleCountryId: Int
    $ruleEventId: Int
    $ruleEventType: String
    $ruleEventOwnerTypes: [String!]
    $rulePurchaserType: String
  ) {
    updatePromoCode(
      promoCodeId: $promoCodeId
      campaignId: $campaignId
      type: $type
      generationReasonId: $generationReasonId
      percentageDiscount: $percentageDiscount
      customPrices: $customPrices
      validFrom: $validFrom
      expiresAt: $expiresAt
      maxUses: $maxUses
      maxUsesPerUser: $maxUsesPerUser
      ruleTypes: $ruleTypes
      ruleDayOfWeek: $ruleDayOfWeek
      ruleDaysOfWeek: $ruleDaysOfWeek
      ruleCityId: $ruleCityId
      ruleCityIds: $ruleCityIds
      ruleCountryId: $ruleCountryId
      ruleEventId: $ruleEventId
      ruleEventType: $ruleEventType
      ruleEventOwnerTypes: $ruleEventOwnerTypes
      rulePurchaserType: $rulePurchaserType
    ) {
      success
      promoCode {
        id
        code
        type
        typeName
        validFrom
        expiresAt
        rules {
          id
          ruleType
          ruleDayOfWeek
          ruleDaysOfWeek
          ruleCity {
            id
            title
          }
          ruleCities {
            id
            title
          }
          ruleCountry {
            id
            title
          }
          ruleEvent {
            id
            localStartsAt
            city {
              id
              title
            }
          }
          ruleEventType
          ruleEventOwnerTypes
          rulePurchaserType
        }
        campaign {
          id
          title
        }
        generationReason {
          id
          description
        }
        percentageDiscount
        customPrices {
          numTickets
          price
        }
        maxUses
        maxUsesPerUser
        numTimesUsed
        numTicketsPurchased
        status
        createdBy {
          id
          firstName
          lastName
          email
        }
        createdAt
      }
    }
  }
`;
