import { useMutation } from 'react-apollo';

import { CreateCityData, UpdateCityData } from 'app/typings/cities';
import { CREATE_CITY, UPDATE_CITY } from 'app/admin/graphql/cities/mutations';

export const CreateCity = () => {
  return useMutation<CreateCityData>(CREATE_CITY)[0];
};

export const UpdateCity = () => {
  return useMutation<UpdateCityData>(UPDATE_CITY)[0];
};
