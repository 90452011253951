import gql from 'graphql-tag';

export const GET_BOOKING_REQUESTS_FOR_ADMIN = gql`
  query GetBookingRequestsFromAdmin(
    $bookingRequestSearch: String
    $city: String
    $cityIds: String
    $status: String
    $availabilityDates: String
    $artistId: Int
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
  ) {
    bookingRequests(
      bookingRequestSearch: $bookingRequestSearch
      city: $city
      cityIds: $cityIds
      status: $status
      availabilityDates: $availabilityDates
      artistId: $artistId
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      bookingRequests {
        id
        city {
          id
          title
          cachedSlug
        }
        artist {
          id
          title
          email
        }
        availabilityStart
        availabilityEnd
        bandSetup
        notes
        status
        createdAt
      }
      metadata {
        totalRecords
      }
    }
  }
`;
