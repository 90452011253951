import gql from 'graphql-tag';

export const ARCHIVE_BUSINESS_OWNER = gql`
  mutation archiveBusinessOwner($id: Int!) {
    archiveBusinessOwner(id: $id) {
      success
      businessOwner {
        id
      }
    }
  }
`;

export const CREATE_BUSINESS_OWNER = gql`
  mutation createBusinessOwner($title: String!) {
    createBusinessOwner(title: $title) {
      success
      businessOwner {
        id
        title
      }
    }
  }
`;

export const UPDATE_BUSINESS_OWNER = gql`
  mutation updateBusinessOwner($businessOwnerId: Int!, $title: String!) {
    updateBusinessOwner(businessOwnerId: $businessOwnerId, title: $title) {
      success
      businessOwner {
        id
        title
      }
    }
  }
`;
