import google from 'app/lib/google';

interface Props {
  inputPayloadKey: string;
  filterPayloadKey: string;
}

const analytics = ({ inputPayloadKey, filterPayloadKey }: Props) => {
  return {
    pushDataLayer: () => {
      // TODO: Fix intermittent GTM error within TagManager.dataLayer call causing pages to crash locally,
      // commented out until we fix that
      // google.dataLayer({
      //  event: 'segment_datalayer_push',
      //});
    },
    inputPayload: (payload: string | undefined) => {
      google.dataLayer({
        [inputPayloadKey]: payload,
      });
    },
    filterPayload: (payload: any) => {
      google.dataLayer({
        [filterPayloadKey]: payload,
      });
    },
  };
};

export default analytics;
