import React from 'react';
import { useIntl } from 'react-intl';

import { useCurrentTheme } from 'app/shared/theme';
import { SOFAR_PRIVACY_POLICY_URL } from 'app/shared/utils/siteUrls';
import { Typography } from 'app/shared/components/atoms';
import { NavigationLink as Link } from 'app/shared/components/atoms';
import { BasePageBox } from 'app/shared/components/molecules';

export const BottomSection = () => {
  const { formatMessage } = useIntl();
  const { colors } = useCurrentTheme();
  const year = new Date().getFullYear();

  return (
    <BasePageBox py={6}>
      <Typography
        color={colors.primary.white.base}
        pr={1}
        fontSize="fontSize50"
      >
        &copy; {year} Sofar Sounds, LTD |
      </Typography>
      <Link
        variant="tertiary"
        href={SOFAR_PRIVACY_POLICY_URL}
        data-qaid="footer-privacy-policy"
        textDecoration="none"
        fontSize="fontSize50"
      >
        {formatMessage({ id: 'footer.privacy' })}
      </Link>{' '}
      <Typography
        color={colors.primary.white.base}
        pr={1}
        fontSize="fontSize50"
      >
        |
      </Typography>
      <Link
        variant="tertiary"
        href="/terms_and_conditions"
        data-qaid="footer-terms"
        textDecoration="none"
        fontSize="fontSize50"
      >
        {formatMessage({ id: 'footer.terms' })}
      </Link>{' '}
      <Typography
        color={colors.primary.white.base}
        pr={1}
        fontSize="fontSize50"
      >
        |
      </Typography>
      <Link
        variant="tertiary"
        href="/sofar-sitemap"
        data-qaid="footer-sitemap"
        textDecoration="none"
        fontSize="fontSize50"
      >
        {formatMessage({ id: 'footer.sitemap' })}
      </Link>
    </BasePageBox>
  );
};
