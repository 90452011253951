import gql from 'graphql-tag';

export const GET_CURATOR_GROUP = gql`
  query GetCuratorGroup($id: Int!) {
    curatorGroup(id: $id) {
      id
      name
      status
      curatorGroupType
      createdAt
      useFromEmailForAllEmails
      fromEmail
      eventPrice
      price
      currencySymbol
      mainArtistOps {
        id
        firstName
        lastName
        email
      }
      mainCrewOps {
        id
        firstName
        lastName
        email
      }
      mainVenueOps {
        id
        firstName
        lastName
        email
      }
      city {
        id
        title
        cachedSlug
        latitude
        longitude
        timezone
        defaultEventPrice
        country {
          id
          title
        }
      }
      users {
        id
        firstName
        lastName
        email
        roles {
          key
          name
          resourceId
          resourceType
        }
      }
    }
  }
`;

export const GET_CURATOR_GROUPS = gql`
  query GetCuratorGroups(
    $curatorGroupSearch: String
    $curatorGroup: String
    $city: String
    $curatorGroupType: String
    $status: String
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    curatorGroups(
      curatorGroupSearch: $curatorGroupSearch
      curatorGroup: $curatorGroup
      city: $city
      curatorGroupType: $curatorGroupType
      status: $status
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      curatorGroups {
        id
        name
        status
        curatorGroupType
        createdAt
        city {
          id
          title
          cachedSlug
          latitude
          longitude
        }
      }
      metadata {
        currentPage
        totalRecords
      }
    }
  }
`;

export const GET_CURATOR_GROUPS_LITE = gql`
  query GetCuratorGroupsLite($isLiteQuery: Boolean) {
    curatorGroups(isLiteQuery: $isLiteQuery) {
      curatorGroups {
        id
        name
      }
    }
  }
`;
