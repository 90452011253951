import gql from 'graphql-tag';

export const GET_ARTIST_EVENTS_INFO = gql`
  query GetArtistEventsInfo(
    $id: Int!
    $scope: String
    $perPage: Int
    $page: Int
  ) {
    artistEventsInfo(id: $id, scope: $scope, perPage: $perPage, page: $page) {
      upcomingEvents {
        events {
          id
          localStartsAt
          eventType
          cancelled
          city {
            title
            cachedSlug
            country {
              title
            }
          }
          artists {
            title
          }
          venue {
            venueName
            address
          }
          performances {
            artist {
              id
            }
            isArtistSelfBooked
          }
        }
        metadata {
          totalRecords
          currentPage
        }
      }
      pastEvents {
        events {
          id
          localStartsAt
          eventType
          cancelled
          city {
            title
            cachedSlug
            country {
              title
            }
          }
          artists {
            title
          }
          venue {
            venueName
            address
          }
          performances {
            artist {
              id
            }
            isArtistSelfBooked
          }
        }
        metadata {
          totalRecords
          currentPage
        }
      }
      bookingRequests {
        bookingRequests {
          id
          city {
            title
            cachedSlug
            country {
              title
            }
          }
          availabilityStart
          availabilityEnd
        }
        metadata {
          totalRecords
          currentPage
        }
      }
      performanceOffers {
        events {
          id
          status
          localStartsAt
          eventType
          cancelled
          position
          city {
            title
            cachedSlug
            country {
              title
            }
          }
          venue {
            venueName
            address
          }
        }
        metadata {
          totalRecords
          currentPage
        }
      }
    }
  }
`;

export const GET_EVENTS_FOR_EVENT_PLANNER = gql`
  query GetEventsForEventPlanner(
    $eventSearch: String
    $city: String
    $cityIds: String
    $businessOwner: String
    $neighborhood: String
    $date: String
    $numTicketsAvailableForSale: String
    $theme: String
    $eventCollection: String
    $tag: String
    $offerId: Int
    $openPositions: String
    $artistIds: String
    $venueIds: String
    $curatorGroup: String
    $roleAvailable: String
    $isStaffedByCurrentUser: Boolean
    $eventIds: [String]
    $excludeSoldOut: Boolean
    $excludePresale: Boolean
    $excludeNonPresale: Boolean
    $excludeCancelled: Boolean
    $indoorOutdoor: String
    $isByob: Boolean
    $maxPaInputs: String
    $maxSlotsTaken: Int
    $notYetBooked: String
    $bookings: String
    $upcoming: Boolean
    $upcomingOrCurrent: Boolean
    $published: Boolean
    $globallyPromoted: Boolean
    $type: String
    $eventOrganizedAs: String
    $attendeeTicketingFlow: String
    $status: String
    $minRemainingSpaces: Int
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    events(
      eventSearch: $eventSearch
      city: $city
      cityIds: $cityIds
      businessOwner: $businessOwner
      neighborhood: $neighborhood
      date: $date
      numTicketsAvailableForSale: $numTicketsAvailableForSale
      theme: $theme
      eventCollection: $eventCollection
      tag: $tag
      offerId: $offerId
      openPositions: $openPositions
      artistIds: $artistIds
      venueIds: $venueIds
      curatorGroup: $curatorGroup
      roleAvailable: $roleAvailable
      isStaffedByCurrentUser: $isStaffedByCurrentUser
      eventIds: $eventIds
      excludeSoldOut: $excludeSoldOut
      excludePresale: $excludePresale
      excludeNonPresale: $excludeNonPresale
      excludeCancelled: $excludeCancelled
      indoorOutdoor: $indoorOutdoor
      isByob: $isByob
      maxPaInputs: $maxPaInputs
      maxSlotsTaken: $maxSlotsTaken
      notYetBooked: $notYetBooked
      bookings: $bookings
      globallyPromoted: $globallyPromoted
      upcoming: $upcoming
      upcomingOrCurrent: $upcomingOrCurrent
      published: $published
      type: $type
      eventOrganizedAs: $eventOrganizedAs
      attendeeTicketingFlow: $attendeeTicketingFlow
      status: $status
      minRemainingSpaces: $minRemainingSpaces
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      events {
        id
        guestsArriveAt
        localStartsAt
        startsAt
        crewLoadInAt
        endsAt
        cachedSlug
        theme {
          id
          title
        }
        imageUrl
        isPurchasable
        isAvailable
        isAppliable
        attendeeFlow
        isPublished
        isGloballyPromoted
        isVenueConfirmed
        type
        status
        onPresale
        haveAnyArtistsIndicatedSpecificAvailabilityOnEventDate
        city {
          id
          title
          timezone
          cachedSlug
          country {
            id
            title
            currencySymbol
            countryCode
            currency
          }
        }
        neighborhood {
          id
          title
        }
        venue {
          id
          venueName
          address
          neighborhood {
            id
            title
          }
          capacity
          imageUrl
          mapUrl
          numTicketsAvailableForSale
        }
        performances {
          id
          artist {
            id
            title
            imageUrl
          }
          position
          artistConfirmed
          isArtistSelfBooked
        }
        performanceOffers {
          id
          position
          status
          artist {
            id
            title
            imageUrl
          }
          artistSetup {
            id
            setupName
            audioSupport
          }
          offer {
            id
            name
            status
            isMultiSlot
          }
        }
        venueOffers {
          id
          status
          venue {
            id
            address
            venueName
            imageUrl
            capacity
            neighborhood {
              id
              title
            }
          }
          offer {
            id
            name
            status
            customEmailMessage
          }
        }
        isSoldOut
        numTicketsAvailableForSale
        eventAttendeeGuestsConfirmedCount
        vipAttendeeGuestsCount
        totalGuestsConfirmedCount
        revenue
        currencySymbol
        eventOrganizedAs
        curatorGroup {
          id
          name
        }
        staff {
          id
          role {
            key
          }
          user {
            id
            firstName
            lastName
            email
          }
        }
        eventCollections {
          id
          title
          createdAt
        }
        eventFormatExperiment {
          id
          title
        }
        commentsCount
        maxPaInputs {
          key
          name
        }
        isFilmed
        isSpecialEvent
        specialEventUrl
        campaign {
          id
          title
          partner {
            id
            title
          }
        }
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_EVENTS_FOR_CREW_PORTAL = gql`
  query GetEventsForCrewPortal(
    $city: String
    $cityIds: String
    $neighborhood: String
    $date: String
    $type: String
    $eventOrganizedAs: String
    $roleAvailable: String
    $isStaffedByCurrentUser: Boolean
    $upcomingOrCurrent: Boolean
    $published: Boolean
    $status: String
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
  ) {
    events(
      city: $city
      cityIds: $cityIds
      neighborhood: $neighborhood
      date: $date
      type: $type
      eventOrganizedAs: $eventOrganizedAs
      roleAvailable: $roleAvailable
      isStaffedByCurrentUser: $isStaffedByCurrentUser
      upcomingOrCurrent: $upcomingOrCurrent
      published: $published
      status: $status
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      events {
        id
        numTicketsAvailableForSale
        guestsArriveAt
        localStartsAt
        startsAt
        crewLoadInAt
        endsAt
        cancelled
        theme {
          id
          title
        }
        imageUrl
        isPublished
        isVenueConfirmed
        internalVenueNotes
        type
        city {
          id
          title
          timezone
          cachedSlug
          country {
            id
            title
            currencySymbol
            countryCode
            currency
          }
        }
        venue {
          id
          venueName
          address
          neighborhood {
            id
            title
          }
          mapUrl
          capacity
        }
        currencySymbol
        eventOrganizedAs
        staff {
          id
          role {
            key
          }
          user {
            id
            firstName
            lastName
          }
        }
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_EVENTS_FOR_OFFER_CREATE = gql`
  query GetEventsForOfferCreate(
    $city: String
    $excludeArtistIds: String
    $maxPaInputs: String
    $maxSlotsTaken: Int
    $neighborhood: String
    $numTicketsAvailableForSale: String
    $openPositions: String
    $skipPagination: Boolean
    $status: String
    $theme: String
    $type: String
    $upcoming: Boolean
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
  ) {
    events(
      city: $city
      excludeArtistIds: $excludeArtistIds
      maxPaInputs: $maxPaInputs
      maxSlotsTaken: $maxSlotsTaken
      neighborhood: $neighborhood
      numTicketsAvailableForSale: $numTicketsAvailableForSale
      openPositions: $openPositions
      skipPagination: $skipPagination
      status: $status
      theme: $theme
      type: $type
      upcoming: $upcoming
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      events {
        id
        localStartsAt
        type
        city {
          id
          title
        }
        neighborhood {
          id
          title
        }
        venue {
          id
          neighborhood {
            id
            title
          }
        }
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_EVENTS_FOR_GUESTLIST_TRANSFER_ATTENDEE = gql`
  query GetEventsForGuestlistTransferAttendee(
    $city: String
    $upcoming: Boolean
    $published: Boolean
    $type: String
    $attendeeTicketingFlow: String
    $minRemainingSpaces: Int
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    events(
      city: $city
      upcoming: $upcoming
      published: $published
      type: $type
      attendeeTicketingFlow: $attendeeTicketingFlow
      minRemainingSpaces: $minRemainingSpaces
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      events {
        id
        localStartsAt
        type
        city {
          id
          title
        }
        neighborhood {
          id
          title
        }
        venue {
          id
          neighborhood {
            id
            title
          }
        }
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_EVENTS_FOR_CITY_EDIT = gql`
  query GetEventsForCityEdit(
    $city: String
    $upcoming: Boolean
    $eventOrganizedAs: String
    $type: String
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    events(
      city: $city
      upcoming: $upcoming
      eventOrganizedAs: $eventOrganizedAs
      type: $type
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      events {
        id
        localStartsAt
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_EVENTS_FOR_BOOKING_REQUEST_ACCEPT = gql`
  query GetEventsForBookingRequestAccept(
    $city: String
    $upcoming: Boolean
    $type: String
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    events(
      city: $city
      upcoming: $upcoming
      type: $type
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      events {
        id
        localStartsAt
        startsAt
        venue {
          id
          venueName
          address
        }
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_EVENT_FOR_ADMIN = gql`
  query GetEventForAdmin($id: ID!, $includeDraftEvents: Boolean) {
    event(id: $id, includeDraftEvents: $includeDraftEvents) {
      id
      numTicketsAvailableForSale
      type
      cachedSlug
      cancelled
      cancellations {
        id
        updatedAt
        reasonDescription
        createdBy {
          id
          firstName
          lastName
          email
        }
        cancellationReason {
          id
          description
        }
      }
      localStartsAt
      crewLoadInAt
      artistLoadInAt
      guestsArriveAt
      onSaleAt
      startsAt
      endsAt
      artistCompensation
      eventArtistCompensation
      internalVenueNotes
      externalVenueNotes
      totalAttendeesCount
      totalAttendeesConfirmedCount
      totalAttendeesUnconfirmedCount
      eventAttendeesAppliedCount
      eventAttendeesGuestlistedCount
      eventAttendeesCantGoCount
      eventAttendeesConfirmedCount
      eventAttendeeGuestsAppliedCount
      eventAttendeeGuestsGuestlistedCount
      eventAttendeeGuestsCantGoCount
      eventAttendeeGuestsConfirmedCount
      vipAttendeeGuestsCount
      staffDayOfShowCount
      totalHeadcount
      onPresale
      remainingSpaces
      price
      earlyBirdOriginalPrice
      cityTicketPrice
      eventPrice
      currencySymbol
      eventBookingFee
      attendeeFlow
      isFilmed
      isEarlyBird
      theme {
        id
        title
        notes
      }
      status
      isVenueConfirmed
      isPublished
      isGloballyPromoted
      isPurchasable
      isAvailable
      isAppliable
      isAutoInviteEnabled
      isSoldOut
      isSpecialEvent
      specialEventUrl
      isGloballyPromoted
      isArtistSelfBookingEnabled
      sofarOperatedMarketType
      formatType
      businessOwner {
        id
        title
      }
      showExternalEventNotes
      externalEventNotes
      externalVenueNotes
      headline
      audioNotes
      mcNotes
      seatingNotes
      suppliesNeededNotes
      timingNotes
      revealAddressEmailMessage
      inviteEmailMessage
      imageUrl
      seating {
        key
        name
      }
      eventFormatExperiment {
        id
        title
      }
      maxTicketsPerAttendee
      maximumPromoCodeUses
      maximumPercentagePromoCodeUses
      maximumGuaranteedPromoCodeUses
      maximumCustomPromoCodeUses
      totalBookingFee
      revenue
      ticketRevenue
      dealAmount
      sponsorNotes
      sponsorPostShowSummary
      venueBudget
      venueBudgetDescription
      useFromEmailForAllEmails
      fromEmail
      showInNearbyPrimaryCities
      partnerPromotionTitle
      partnerPromotionDescription
      partnerPromotionButtonText
      partnerPromotionButtonUrl
      partnerPromotionSectionEnabled
      mainArtistOps {
        id
        firstName
        lastName
        email
      }
      mainCrewOps {
        id
        firstName
        lastName
        email
      }
      mainVenueOps {
        id
        firstName
        lastName
        email
      }
      properties {
        id
        propertyGroupId
        key
        name
        iconName
        position
        infoUrl
      }
      merchandisingProperties {
        id
        propertyGroupId
        propertyGroupName
        key
        name
        iconName
        position
      }
      city {
        id
        title
        timezone
        cachedSlug
        hasTieredPrice
        currencySymbol
        bookingFee
        country {
          id
          title
          bookingFee
        }
        maximumPromoCodeUses
        maximumPercentagePromoCodeUses
        maximumGuaranteedPromoCodeUses
        maximumCustomPromoCodeUses
        useFromEmailForAllEmails
        fromEmail
        mainArtistOps {
          id
          firstName
          lastName
          email
        }
        mainCrewOps {
          id
          firstName
          lastName
          email
        }
        mainVenueOps {
          id
          firstName
          lastName
          email
        }
      }
      neighborhood {
        id
        title
      }
      performances {
        promoCodes {
          id
          code
        }
        id
        position
        artistConfirmed
        artistSetup {
          id
          isPrimarySetup
          setupName
          numPerformers
          audioSupport
          additionalSupportNotes
          instrumentNotes
          createdAt
          lastUpdatedAt
        }
        releaseForm {
          id
          url
          filename
          documentId
          documentType
          createdAt
        }
        termsAndConditionsForm {
          id
          url
          filename
          documentId
          documentType
          createdAt
        }
        artist {
          id
          title
          imageUrl
          homeCityDescription
          preferredPronouns {
            id
            key
            name
          }
          description
          contactInfos {
            id
            firstName
            lastName
            email
            phoneNumber
            position
            isDayOfShowContact
            contactRelationship {
              id
              name
            }
          }
          socialUrls {
            urlWebsite
            urlFacebook
            urlInstagram
            urlSpotify
            urlSupport
          }
          isPolicyCompliant
        }
      }
      venue {
        id
        venueName
        address
        address2
        capacity
        closestStation
        mapUrl
        imageUrl
        gearStorage
        hasTapWater
        noiseRestrictions
        externalVenueNotes
        audioNotes
        internalVenueNotes
        loadInNotes
        stageSetupNotes
        mcNotes
        seatingNotes
        suppliesNeededNotes
        timingNotes
        checkInProcessNotes
        inviteEmailMessage
        revealAddressEmailMessage
        seating {
          key
          name
        }
        recommendedCrewCount
        neighborhood {
          id
          title
        }
        properties {
          id
          propertyGroupId
          key
          name
          iconName
          position
          infoUrl
        }
        tags {
          id
          name
        }
        venueCategories {
          id
          name
        }
        socialUrls {
          urlTwitter
          urlFacebook
          urlWebsite
          urlInstagram
        }
        contactInfos {
          id
          firstName
          lastName
          email
          phoneNumber
          position
          isDayOfShowContact
          contactRelationship {
            id
            name
          }
        }
        promoCodes {
          id
          code
        }
      }
      eventOrganizedAs
      curatorGroup {
        id
        name
      }
      maxPaInputs {
        key
        name
      }
      staff {
        id
        user {
          id
          firstName
          lastName
          email
          mobile {
            countryCode
            numberBody
          }
        }
        role {
          key
          name
        }
      }
      eventCollections {
        id
        title
        status
        description
        eventDescription
        eventHeadline
        isPrimaryCollection
        createdAt
        images {
          header {
            ratio16x9 {
              id
              ratio
              filename
              fileLocation
            }
          }
        }
        merchandisingProperties {
          id
          propertyGroupId
          propertyGroupName
          key
          name
          iconName
          position
        }
      }
      competition {
        id
        title
        uid
      }
      images {
        header {
          ratio1x1 {
            id
            ratio
            filename
            fileLocation
          }
          ratio4x3 {
            id
            ratio
            filename
            fileLocation
          }
          ratio16x9 {
            id
            ratio
            filename
            fileLocation
          }
        }
        partnerPromotion {
          imagePurpose
          ratio16x9 {
            id
            ratio
            filename
            fileLocation
          }
        }
      }
      campaign {
        id
        title
        promoCodesWithCampaignCount
        pastEventsWithCampaignCount
        upcomingEventsWithCampaignCount
        accountManagerUser {
          id
          firstName
          lastName
          email
          mobile {
            countryCode
            numberBody
          }
        }
        salesRepUser {
          id
          firstName
          lastName
          email
          mobile {
            countryCode
            numberBody
          }
        }
        partner {
          id
          title
          description
          address
          address2
          partnersCampaignsCount
          contactInfos {
            id
            firstName
            lastName
            email
            phoneNumber
            position
            isDayOfShowContact
            contactRelationship {
              id
              name
            }
          }
        }
        agency {
          id
          title
          contactInfos {
            id
            firstName
            lastName
            email
            phoneNumber
            position
            isDayOfShowContact
            contactRelationship {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_EVENT_FOR_CREW_PORTAL = gql`
  query GetEventForCrewPortal($id: ID!, $includeDraftEvents: Boolean) {
    event(id: $id, includeDraftEvents: $includeDraftEvents) {
      id
      numTicketsAvailableForSale
      type
      cachedSlug
      localStartsAt
      crewLoadInAt
      artistLoadInAt
      guestsArriveAt
      onSaleAt
      startsAt
      endsAt
      cancelled
      timingNotes
      internalVenueNotes
      externalVenueNotes
      totalAttendeesCount
      totalAttendeesConfirmedCount
      totalAttendeesUnconfirmedCount
      eventAttendeesAppliedCount
      eventAttendeesGuestlistedCount
      eventAttendeesCantGoCount
      eventAttendeesConfirmedCount
      eventAttendeeGuestsAppliedCount
      eventAttendeeGuestsGuestlistedCount
      eventAttendeeGuestsCantGoCount
      eventAttendeeGuestsConfirmedCount
      vipAttendeeGuestsCount
      staffDayOfShowCount
      totalHeadcount
      onPresale
      remainingSpaces
      price
      cityTicketPrice
      eventPrice
      currencySymbol
      attendeeFlow
      isFilmed
      theme {
        id
        title
        notes
      }
      status
      isVenueConfirmed
      isPublished
      isGloballyPromoted
      isPurchasable
      isAvailable
      isAppliable
      isAutoInviteEnabled
      isSoldOut
      isSpecialEvent
      specialEventUrl
      isGloballyPromoted
      isArtistSelfBookingEnabled
      sofarOperatedMarketType
      businessOwner {
        id
        title
      }
      showExternalEventNotes
      externalEventNotes
      externalVenueNotes
      revealAddressEmailMessage
      inviteEmailMessage
      imageUrl
      seating {
        key
        name
      }
      maxTicketsPerAttendee
      maximumPromoCodeUses
      maximumPercentagePromoCodeUses
      maximumGuaranteedPromoCodeUses
      maximumCustomPromoCodeUses
      revenue
      dealAmount
      sponsorNotes
      sponsorPostShowSummary
      venueBudget
      venueBudgetDescription
      useFromEmailForAllEmails
      fromEmail
      showInNearbyPrimaryCities
      mcNotes
      seatingNotes
      suppliesNeededNotes
      mainArtistOps {
        id
        firstName
        lastName
        email
      }
      mainCrewOps {
        id
        firstName
        lastName
        email
      }
      mainVenueOps {
        id
        firstName
        lastName
        email
      }
      properties {
        id
        propertyGroupId
        key
        name
        iconName
        position
        infoUrl
      }
      city {
        id
        title
        timezone
        cachedSlug
        hasTieredPrice
        currencySymbol
        country {
          id
          title
        }
        maximumPromoCodeUses
        maximumPercentagePromoCodeUses
        maximumGuaranteedPromoCodeUses
        maximumCustomPromoCodeUses
        useFromEmailForAllEmails
        fromEmail
        mainArtistOps {
          id
          firstName
          lastName
          email
        }
        mainCrewOps {
          id
          firstName
          lastName
          email
        }
        mainVenueOps {
          id
          firstName
          lastName
          email
        }
      }
      neighborhood {
        id
        title
      }
      performances {
        id
        position
        artistConfirmed
        artistSetup {
          id
          isPrimarySetup
          setupName
          numPerformers
          isPrimarySetup
          audioSupport
          additionalSupportNotes
          instrumentNotes
          createdAt
          lastUpdatedAt
        }
        releaseForm {
          id
          url
          filename
          documentId
          documentType
          createdAt
        }
        termsAndConditionsForm {
          id
          url
          filename
          documentId
          documentType
          createdAt
        }
        artist {
          id
          title
          imageUrl
          homeCityDescription
          closestSofarCityTitle
          pastEventsCount
          preferredPronouns {
            id
            key
            name
          }
          description
          contactInfos {
            id
            firstName
            lastName
            email
            phoneNumber
            position
            isDayOfShowContact
            contactRelationship {
              id
              name
            }
          }
          socialUrls {
            urlFacebook
            urlTwitter
            urlWebsite
            urlSoundcloud
            urlYoutube
            urlInstagram
            urlBandcamp
            urlSpotify
            urlSupport
          }
          isPolicyCompliant
        }
      }
      venue {
        id
        venueName
        address
        address2
        capacity
        closestStation
        mapUrl
        imageUrl
        gearStorage
        hasTapWater
        loadInNotes
        noiseRestrictions
        parkingNotes
        externalVenueNotes
        internalVenueNotes
        inviteEmailMessage
        pastEventsCount
        revealAddressEmailMessage
        recommendedCrewCount
        bathroomNotes
        checkInProcessNotes
        merchTablePlacementNotes
        stageSetupNotes
        seating {
          key
          name
        }
        neighborhood {
          id
          title
        }
        properties {
          id
          propertyGroupId
          key
          name
          iconName
          position
          infoUrl
        }
        tags {
          id
          name
        }
        venueCategories {
          id
          name
        }
        socialUrls {
          urlTwitter
          urlFacebook
          urlWebsite
          urlInstagram
        }
        contactInfos {
          id
          firstName
          lastName
          email
          phoneNumber
          position
          isDayOfShowContact
          contactRelationship {
            id
            name
          }
        }
        venuePhotos {
          id
          title
          description
          imageUrl
          updatedAt
          createdBy {
            firstName
            lastName
          }
        }
      }
      eventOrganizedAs
      curatorGroup {
        id
        name
      }
      audioNotes
      isAudioStaffMemberActivated
      isGearRunnerStaffMemberActivated
      maxPaInputs {
        key
        name
      }
      staff {
        id
        user {
          id
          firstName
          lastName
          email
          mobile {
            countryCode
            numberBody
          }
        }
        role {
          key
          name
        }
      }
      competition {
        id
        title
        uid
      }
      campaign {
        id
        title
        promoCodesWithCampaignCount
        pastEventsWithCampaignCount
        upcomingEventsWithCampaignCount
        accountManagerUser {
          id
          firstName
          lastName
          email
          mobile {
            countryCode
            numberBody
          }
        }
        salesRepUser {
          id
          firstName
          lastName
          email
          mobile {
            countryCode
            numberBody
          }
        }
        partner {
          id
          title
          description
          address
          address2
          partnersCampaignsCount
          contactInfos {
            id
            firstName
            lastName
            email
            phoneNumber
            position
            isDayOfShowContact
            contactRelationship {
              id
              name
            }
          }
        }
        agency {
          id
          title
          contactInfos {
            id
            firstName
            lastName
            email
            phoneNumber
            position
            isDayOfShowContact
            contactRelationship {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_EVENT_FOR_EVENT_SELECTOR = gql`
  query GetEventForEventSelector($id: ID, $cachedSlug: String) {
    event(id: $id, cachedSlug: $cachedSlug) {
      id
      localStartsAt
      type
      venue {
        id
        venueName
        address
        neighborhood {
          id
          title
        }
      }
      city {
        id
        title
      }
    }
  }
`;

export const GET_EVENTS_FOR_VENUE_EDIT = gql`
  query GetEventsForVenueEdit(
    $venueIds: String
    $upcoming: Boolean
    $eventOrganizedAs: String
    $type: String
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    events(
      venueIds: $venueIds
      upcoming: $upcoming
      eventOrganizedAs: $eventOrganizedAs
      type: $type
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      events {
        id
        localStartsAt
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;
