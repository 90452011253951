import gql from 'graphql-tag';

export const GET_NEIGHBORHOODS = gql`
  query GetNeighborhoods($city: String, $cityIds: String) {
    neighborhoods(city: $city, cityIds: $cityIds) {
      neighborhoods {
        id
        title
        cachedSlug
        city {
          id
          title
          cachedSlug
        }
      }
    }
  }
`;
