import { useQuery } from 'react-apollo';

import {
  GetEventEmailPreviewContentData,
  GetEventEmailPreviewContentVariables,
  GetEventEmailsData,
  GetEventEmailsVariables,
} from 'app/typings/emails';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_EVENT_EMAIL_PREVIEW_CONTENT,
  GET_EVENT_EMAILS,
} from 'app/admin/graphql/emails/queries';

export const GetEventEmails = (params: GetEventEmailsVariables = {}) => {
  return useQuery<GetEventEmailsData>(GET_EVENT_EMAILS, queryParams(params));
};

export const GetEventEmailPreviewContent = (
  params: GetEventEmailPreviewContentVariables = {}
) => {
  return useQuery<GetEventEmailPreviewContentData>(
    GET_EVENT_EMAIL_PREVIEW_CONTENT,
    queryParams(params)
  );
};
