import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Helmet } from 'react-helmet-async';
import styled, { css } from 'styled-components';

import { setFirstSeenAt } from 'app/shared/utils/firstSeenAt';
import { get } from 'app/shared/utils/get';
import useScrollDirection from 'app/shared/utils/useScrollDirection';
import { UPDATE_SESSION } from 'app/shared/graphql/sessions/mutations';
import { AuthContext } from 'app/shared/context/Auth';
import { WithNavbarPaddingProps } from 'app/shared/components/styleUtils/manualCSS/withNavbarPadding';
import { ThemeWrapper } from 'app/shared/components/atoms';
import ErrorBoundary from 'app/shared/components/atoms/ErrorBoundary';
import {
  Breadcrumb,
  Breadcrumbs,
  PageBanner,
} from 'app/shared/components/molecules';
import Footer from 'app/shared/components/organisms/Footer';
import Navbar, { NavbarProps } from 'app/shared/components/organisms/Navbar';
import { GlobalError } from 'app/shared/components/pages/Status';

interface Props {
  children: any;
  noNavbarSpacing?: boolean;
  navbarProps?: NavbarProps;
  breadcrumbProps?: Breadcrumb[];
  footerProps?: any;
  'data-qaid'?: string;
  centerContent?: boolean;
  scrollDisabled?: boolean;
  renderAboveNavComponent?: () => void;
}

interface CenterProp {
  centerContent?: boolean;
  topMargin?: number;
  children: any;
  'data-qaid'?: string;
}

interface ScrollProp {
  scrollDisabled: boolean;
}

interface StickyContainerProps {
  theme: any;
  isScrollingDown?: boolean;
  stickyContainerHeight: number;
  ref: any;
}

export const Layout = styled.div<WithNavbarPaddingProps & ScrollProp>`
  ${({ scrollDisabled }) => css`
    ${scrollDisabled &&
      css`
        overflow-y: hidden;
        height: 100%;
        position: fixed;
      `}
    padding-top: 0;
    width: 100%;
    overflow-x: hidden;
  `}
`;

export const StickyContainer = styled.div<StickyContainerProps>`
  ${({ theme, isScrollingDown, stickyContainerHeight }) => css`
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 100;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.3, 0, 0.2, 1);
    transition-duration: 400ms;

    ${isScrollingDown &&
      css`
        top: -${stickyContainerHeight}px;
      `}

    ${theme.media.md`
      position: relative;

      ${isScrollingDown &&
        css`
          top: 0;
        `}
    `}
  `}
`;

export const CenterContainer = styled.div<CenterProp>`
  ${({ centerContent }) => css`
    margin-top: 0;
    padding-top: 0;
    display: block;

    ${centerContent &&
      css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
  `}
`;

const MainLayout: React.FC<Props> = ({
  children,
  navbarProps,
  breadcrumbProps,
  footerProps,
  centerContent,
  renderAboveNavComponent,
  noNavbarSpacing = true,
  'data-qaid': dataQaid = 'main-layout',
  scrollDisabled = false,
  ...rest
}) => {
  const authContext = useContext(AuthContext);
  const [displayBannerMessage, setDisplayBannerMessage] = useState(true);
  const [updateSession] = useMutation(UPDATE_SESSION);
  const scrollDirection = useScrollDirection();
  const stickyContainerRef = useRef();
  const stickyContainerHeight = get(
    stickyContainerRef,
    'current.clientHeight',
    0
  );
  const footerRef = useRef<HTMLDivElement>();

  const authContextFinishedLoading = authContext && !authContext.loading;

  useEffect(() => {
    if (authContextFinishedLoading) {
      setFirstSeenAt(authContext);
    }
  }, [authContextFinishedLoading, authContext]);

  function handleBannerDismiss() {
    setDisplayBannerMessage(false);
    updateSession({
      variables: {
        hideBannerMessage: true,
      },
    });
  }

  return (
    <Layout
      data-qaid={dataQaid}
      stickToNavbar={noNavbarSpacing}
      scrollDisabled={scrollDisabled}
      {...rest}
    >
      <Helmet>
        <link
          rel="preload"
          href="/src/fonts/sofarsounds-icon-font.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="true"
        />
      </Helmet>
      <StickyContainer
        ref={stickyContainerRef}
        stickyContainerHeight={stickyContainerHeight}
        isScrollingDown={scrollDirection === 'down'}
      >
        {renderAboveNavComponent && renderAboveNavComponent()}

        <ThemeWrapper themeName="common">
          {displayBannerMessage && authContext?.sessionMessage && (
            <PageBanner
              text={authContext.sessionMessage.text}
              link={authContext.sessionMessage.link}
              linkText={authContext.sessionMessage.linkText}
              onBannerDismiss={handleBannerDismiss}
            />
          )}
          <Navbar {...navbarProps} invertColors={true} />
          {breadcrumbProps && <Breadcrumbs breadcrumbLinks={breadcrumbProps} />}
        </ThemeWrapper>
      </StickyContainer>
      <CenterContainer centerContent={centerContent}>
        {children}
      </CenterContainer>
      {authContextFinishedLoading && (
        <Footer {...footerProps} footerRef={footerRef} />
      )}
    </Layout>
  );
};

const GlobalErrorComponent = () => {
  return <GlobalError noLayout={true} />;
};

export default (props: Props) => {
  return (
    <ErrorBoundary ErrorComponent={GlobalErrorComponent}>
      <MainLayout {...props} />
    </ErrorBoundary>
  );
};
