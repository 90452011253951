import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useAnalyticsContext } from 'app/shared/utils';

interface SEOProps {
  title: string;
  description?: string;
  image?: string;
  ogTitle?: string;
  ogDescription?: string;
  ldJson?: string | object;
  customTitleTemplate?: string;
  canonicalUrlPath?: string;
  noIndex?: boolean;
  noFollow?: boolean;
}

const defaultSEO = {
  title: 'Secret gigs and intimate concerts',
  description:
    'Gigs in intimate spaces around the world. Sofar Sounds is a global movement which brings the magic back to live music. Sign up today to host, play or attend.',
  image:
    '//dax2lgcd0wbaz.cloudfront.net/assets/sofar-share-13e30a152beb75c98c298a8ade6a511fe8514405783b2c38a4bdc116c1bfa2c6.jpg',
  siteName: 'Sofar Sounds',
  url: 'https://www.sofarsounds.com',
};

export const SEO: React.FC<SEOProps> = ({
  title,
  description = defaultSEO.description,
  image,
  ogTitle,
  ogDescription,
  ldJson,
  customTitleTemplate,
  canonicalUrlPath,
  noIndex = false,
  noFollow = false,
}) => {
  const titleTemplate = customTitleTemplate || `%s | ${defaultSEO.siteName}`;

  const { segmentPage } = useAnalyticsContext();
  useEffect(() => {
    segmentPage(title);
  }, [segmentPage, title]);

  const noIndexNoFollowTag = () => {
    if (noIndex && noFollow) {
      return <meta name="robots" content="noindex, nofollow" />;
    } else if (noIndex) {
      return <meta name="robots" content="noindex" />;
    } else if (noFollow) {
      return <meta name="robots" content="nofollow" />;
    }

    return null;
  };

  return (
    <Helmet
      prioritizeSeoTags={!noIndex && !noFollow}
      titleTemplate={titleTemplate}
      defaultTitle={defaultSEO.title}
      defer={false}
    >
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:site_name" content={defaultSEO.siteName} />
      <meta property="og:description" content={ogDescription || description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:url" content={location.href} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_GB" />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:alt" content={title} />
      {noIndexNoFollowTag()}
      {ldJson && <script type="application/ld+json">{ldJson}</script>}

      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={`${process.env.PUBLIC_PATH}favicon.ico`}
      />
      {canonicalUrlPath && (
        <link
          rel="canonical"
          href={`https://www.sofarsounds.com${canonicalUrlPath}`}
        />
      )}
    </Helmet>
  );
};

export default SEO;
