import { useMutation } from 'react-apollo';

import {
  CreateContinentData,
  DeleteContinentData,
  UpdateContinentData,
} from 'app/typings/continents';
import {
  CREATE_CONTINENT,
  DELETE_CONTINENT,
  UPDATE_CONTINENT,
} from 'app/admin/graphql/continents/mutations';

export const CreateContinent = () => {
  return useMutation<CreateContinentData>(CREATE_CONTINENT)[0];
};

export const DeleteContinent = () => {
  return useMutation<DeleteContinentData>(DELETE_CONTINENT)[0];
};

export const UpdateContinent = () => {
  return useMutation<UpdateContinentData>(UPDATE_CONTINENT)[0];
};
