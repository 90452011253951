import React from 'react';

interface Props {
  data: object;
  'data-qaid'?: string;
}

const JsonLD: React.FC<Props> = ({ data, 'data-qaid': qaid = '' }) => (
  <script
    data-qaid={qaid}
    type="application/ld+json"
    dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
  />
);

export default JsonLD;
