import { useQuery } from 'react-apollo';

import {
  GetArtistApplicationData,
  GetArtistApplicationsData,
  GetArtistApplicationsVariables,
  GetArtistApplicationVariables,
} from 'app/typings/artistApplications';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_ARTIST_APPLICATION,
  GET_ARTIST_APPLICATIONS,
} from 'app/admin/graphql/artistApplications/queries';

export const GetArtistApplication = (
  params: GetArtistApplicationVariables = {}
) => {
  return useQuery<GetArtistApplicationData>(
    GET_ARTIST_APPLICATION,
    queryParams(params)
  );
};

export const GetArtistApplications = (
  params: GetArtistApplicationsVariables = {}
) => {
  return useQuery<GetArtistApplicationsData>(
    GET_ARTIST_APPLICATIONS,
    queryParams(params)
  );
};
