import { useLazyQuery, useQuery } from 'react-apollo';

import {
  GetCampaignsData,
  GetCampaignsFullInfoData,
  GetCampaignsFullInfoVariables,
  GetCampaignsVariables,
} from 'app/typings/campaigns';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_FULL_INFO,
} from 'app/admin/graphql/campaigns/queries';

export const GetCampaigns = (params: GetCampaignsVariables = {}) => {
  return useQuery<GetCampaignsData>(GET_CAMPAIGNS, queryParams(params));
};

export const GetCampaignsLazy = (params: GetCampaignsVariables = {}) => {
  return useLazyQuery<GetCampaignsData>(GET_CAMPAIGNS, queryParams(params));
};

export const GetCampaignsFullInfo = (
  params: GetCampaignsFullInfoVariables = {}
) => {
  return useQuery<GetCampaignsFullInfoData>(
    GET_CAMPAIGNS_FULL_INFO,
    queryParams(params)
  );
};
