import gql from 'graphql-tag';

export const GET_AGENCIES = gql`
  query GetAgencies($archivedStatus: String, $skipPagination: Boolean) {
    agencies(archivedStatus: $archivedStatus, skipPagination: $skipPagination) {
      agencies {
        id
        title
      }
    }
  }
`;
