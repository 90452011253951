import React from 'react';

import { useAnalyticsContext } from 'app/shared/utils';
import { Typography } from 'app/shared/components/atoms';
import ThemeWrapper from 'app/shared/components/atoms/ThemeWrapper';
import { NavigationButtonWithCommonTheme } from 'app/shared/components/molecules/NavigationButtonWithCommonTheme';
import SEO from 'app/shared/components/molecules/SEO';
import Layout from 'app/shared/layouts/Main';

import GenericErrorScreen from './GenericErrorScreen';

const GlobalErrorContent: React.FC = () => {
  return (
    <ThemeWrapper themeName="common">
      <div>
        <GenericErrorScreen>
          <Typography textAlign="center">
            Something&apos;s gone wrong. We&apos;re looking into it - please try
            again!
          </Typography>
          <NavigationButtonWithCommonTheme href="/">
            Back to Homepage
          </NavigationButtonWithCommonTheme>
        </GenericErrorScreen>
      </div>
    </ThemeWrapper>
  );
};

export const GlobalError: React.FC<{
  noLayout?: boolean;
  error?: any;
}> = ({ noLayout = false, error }) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();

  trackAnalyticsEvent('Oh No Page Viewed', {
    title: '500 page',
    error,
    url: window.location.href,
  });

  return (
    <ThemeWrapper themeName="manualCSS">
      {noLayout ? (
        <div data-qaid="500-page">
          <GlobalErrorContent />
        </div>
      ) : (
        <Layout data-qaid="500-page">
          <SEO title="Something went wrong" noIndex />
          <GlobalErrorContent />
        </Layout>
      )}
    </ThemeWrapper>
  );
};
