import {
  addMinutes as dateFnsAddMinutes,
  format as dateFnsFormat,
} from 'date-fns';
import { format as dateFnsTzFormat, utcToZonedTime } from 'date-fns-tz';

export interface DateParts {
  weekday: string;
  month: string;
  day: string;
  year: string;
}

const DATETIME_FORMATS = {
  date: 'd', // 3
  shortWeekday: 'EEE', // Thu
  longWeekday: 'EEEE', // Thursday
  shortMonth: 'MMM', // Oct
  longMonth: 'MMMM', // October
  longYear: 'yyyy', // 2022
  shortMonthAndDate: 'MMM dd', // Oct 03
  shortMonthAndLongYear: 'MMM yyyy', // Oct 2022
  shortDayOfWeekAndDate: 'EEE d', // Fri 7
  hoursAndMinutesWithAMPM: 'h:mma', // 7:00PM
  shortMonthDateAndYear: 'MMM d, yyyy', // Oct 7, 2022
  yearMonthDate: 'yyyy-MM-dd', // 2022-09-07
  hoursAndMinutesWithSpacedAMPM: 'h:mm a', // 7:00 PM
  hoursAndMinutesWithoutAMPM: 'HH:mm', // 07:00
  longMonthDateAndYear: 'MMMM dd, yyyy', // October 07, 2022
  shortDateAndLongMonth: 'd MMMM', // 3 October (UK/global)
  longMonthAndShortDate: 'MMMM d', // October 3 (US/CA)
  fullMonthDateWithOrdinalAndYear: 'MMMM do, yyyy', // October 7th, 2022
  shortWeekdayDateWithOrdinalAndShortMonth: 'EEE do MMM', // Fri 5th Nov
  shortWeekdayShortMonthDateAndYear: 'EEE, MMM d, yyyy', // Fri, Nov 7, 2022
  shortWeekdayDateShortMonth: 'EEE d MMM', // Fri 7 Nov
  longWeekdayShortMonthDateAndYear: 'EEEE, MMM d, yyyy', // Friday, Nov 7, 2022
  longWeekDayDateWithOrdinalAndLongMonth: 'EEEE do MMMM', // Friday 5th November
  longWeekdayDateLongMonthAndYear: 'EEEE, dd MMMM yyyy', // Friday, 07 November 2022
  longWeekdayDateLongMonth: 'EEEE d MMMM', // Friday 7 November
  longWeekdayCommaDateLongMonth: 'EEEE, d MMMM', // Friday, 7 November (US/CA)
  longWeekdayCommaLongMonthDate: 'EEEE, MMMM d', // Friday, November 7 (UK/global)
  yearMonthDateHoursMinutesAndSeconds: 'yyyy-MM-dd HH:mm:ss', // 2022-09-07 19:00:00
  longWeekdayDateWithOrdinalLongMonthYear: 'EEEE do MMMM yyyy', // Friday 5th November 2022
  shortWeekdayShortMonthDateHoursAndMinutesWithTimezone: 'EEE, MMM d, h:mm a', // Fri, Nov 7, 7:00 PM
  longMonthDateHoursAndMinutes: 'MMMM d, yyyy, HH:mm', // April 3, 2023, 15:28
  shortMonthDateYearHoursAndMinutesWithSpacedAMPMWithTimezone:
    'MMM d, yyyy, h:mm a zzz', // Nov 7, 2022, 7:00 PM GMT
  longWeekdayDateWithOrdinalFullMonthHoursMinutesWithAMPM: 'EEEE do MMMM h:mma', // Friday 5th November 7:00PM
  longWeekdayLongMonthDateAndYear: 'EEEE, MMMM d, yyyy', // Friday, November 7, 2022
  yearMonthAndDateWithHoursMinutesSecondsAMPMWithTimezone:
    'yyyy-MM-dd HH:mm:ss zzz', // 2022-11-07 07:00:00 GMT
};

// Given a timezone string, ex: 'America/New_York'
// Returns a number with the current year in that local timezone
export const getCurrentYearInTimeZone = (timeZone: string): number => {
  return Number.parseInt(
    dateFormatter(new Date(Date.now()), 'longYear', timeZone)
  );
};

// Given a UTC datetime string, ex: 2022-09-26T20:00:00Z
// Returns the individual parts of the date:
// weekday, day, month, year
// Note: the input date will be treated as UTC
// Output will look similar to:
// [
//   {type: 'weekday', value: 'Thu'},
//   {type: 'literal', value: ', '},
//   {type: 'day', value: '22'},
//   {type: 'literal', value: ' '},
//   {type: 'month', value: 'Sept'},
//   {type: 'literal', value: ' '},
//   {type: 'year', value: '2022'},
// ]
const dateFormatToParts = (date: Date | string, utc = true) => {
  const formatOptions: any = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    timeZone: utc ? 'UTC' : undefined,
  };

  return new Intl.DateTimeFormat('en-GB', formatOptions).formatToParts(
    new Date(date)
  );
};

// See DATETIME_FORMATS
// Given a datetime string, e.g. '2022-09-26T20:00:00Z'
// A predefined formatting string, e.g. 'hoursAndMinutesWithAMPM', or unicode format, e.g. 'yyyy'
// And an optional timezone, e.g. 'America/New_York'
// Returns the formatted datetime
// If timezone arg is passed, it returns the localized and formatted datetime
// If timezone arg is not passed it only formats the datetime
export const dateFormatter = (
  date: Date | string,
  formatOption: string,
  timeZone?: string
) => {
  const unicodeFormat = DATETIME_FORMATS[formatOption] || formatOption;

  if (timeZone) {
    const zonedDateTime = utcToZonedTime(new Date(date), timeZone);
    return dateFnsTzFormat(zonedDateTime, unicodeFormat, { timeZone });
  }

  let d = new Date(date);
  const offset = d.getTimezoneOffset();
  const adjust = dateFnsAddMinutes(d, offset);
  return dateFnsFormat(adjust, unicodeFormat);
};

export const getDateParts = (date: Date | string, utc = true): DateParts => {
  const dateParts = dateFormatToParts(date, utc);

  return {
    weekday: dateParts[0].value,
    day: dateParts[2].value,
    month: dateParts[4].value,
    year: dateParts[6].value,
  };
};

// Given a datetime string, e.g. '2022-11-16T20:00:00Z'
// Returns e.g. '2022-11-16' for passing to backend
export const getDateSubmitVariable = (dateStr?: string) =>
  dateStr && dateStr.split('T')[0];

export const getDatesSubmitVariable = (dateStrs?: string[]) => {
  return (
    dateStrs &&
    dateStrs.map((dateStr: string) => getDateSubmitVariable(dateStr))
  );
};

// Given a time str e.g. '19:30:00'
// Returns e.g. '2001-01-01T19:30:00Z' for use by timepicker component
export const getTimeFormInitialValue = (timeStr?: string) =>
  timeStr && `2001-01-01T${timeStr}Z`;

// Given a datetime object returned by timepicker component
// Returns e.g. '19:30:00' for passing to backend
export const getTimeSubmitVariable = (rawTime?: any) => {
  const timeParts = rawTime && String(rawTime).match(/(\d\d:\d\d):\d\d/);
  return timeParts && timeParts[1] && `${timeParts[1]}:00`;
};

// Used to keep frontend in sync with event_date_time_display_service in backend
export const uses12HourClock = (countryCode: any) => {
  const countries = ['US', 'CA'];

  return countries.includes(countryCode);
};

const YEAR_IN_MONTHS = 12;
const WEEK_IN_DAYS = 7;
const DAY_IN_MS = 24 * 60 * 60 * 1000;
const padWithZero = (num: number) => (num < 10 ? `0${num}` : num);
const datetimeUtc = (date: string) => `${date} 0:00:00 UTC`;

export const getCalendarMonthConfig = (month: number, year: number) => {
  const startDate = new Date(datetimeUtc(`${year}-${padWithZero(month)}-01`));
  const startDayOfWeek = startDate.getUTCDay();
  const endOfPriorMonth = new Date(
    startDate.getTime() - DAY_IN_MS
  ).getUTCDate();
  const nextMonth =
    month === YEAR_IN_MONTHS
      ? `${year + 1}-01`
      : `${year}-${padWithZero(month + 1)}`;
  const priorMonth =
    month === 1
      ? `${year - 1}-${YEAR_IN_MONTHS}`
      : `${year}-${padWithZero(month - 1)}`;
  const nextMonthStartDate = new Date(datetimeUtc(`${nextMonth}-01`));
  const endOfCurrentMonth = new Date(
    nextMonthStartDate.getTime() - DAY_IN_MS
  ).getUTCDate();
  const numRows = Math.ceil(
    (startDayOfWeek + endOfCurrentMonth) / WEEK_IN_DAYS
  );
  const dates = [...Array(numRows * WEEK_IN_DAYS).keys()].map((index: number) =>
    index < startDayOfWeek
      ? `${priorMonth}-${endOfPriorMonth - startDayOfWeek + index + 1}`
      : index > startDayOfWeek + endOfCurrentMonth - 1
      ? `${nextMonth}-${index - startDayOfWeek - endOfCurrentMonth + 1}`
      : `${year}-${month}-${index - startDayOfWeek + 1}`
  );
  return {
    numRows,
    dates,
  };
};
