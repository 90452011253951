import { useQuery } from 'react-apollo';

import {
  GetGenerationReasonsData,
  GetGenerationReasonsVariables,
  GetPromoCodeData,
  GetPromoCodesData,
  GetPromoCodesVariables,
  GetPromoCodeTypesData,
  GetPromoCodeTypesVariables,
  GetPromoCodeVariables,
} from 'app/typings/promoCodes';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_GENERATION_REASONS,
  GET_PROMO_CODE,
  GET_PROMO_CODE_TYPES,
  GET_PROMO_CODES,
} from 'app/admin/graphql/promoCodes/queries';

export const GetGenerationReasons = (
  params: GetGenerationReasonsVariables = {}
) => {
  return useQuery<GetGenerationReasonsData>(
    GET_GENERATION_REASONS,
    queryParams(params)
  );
};

export const GetPromoCode = (params: GetPromoCodeVariables = {}) => {
  return useQuery<GetPromoCodeData>(GET_PROMO_CODE, queryParams(params));
};

export const GetPromoCodes = (params: GetPromoCodesVariables = {}) => {
  return useQuery<GetPromoCodesData>(GET_PROMO_CODES, queryParams(params));
};

export const GetPromoCodeTypes = (params: GetPromoCodeTypesVariables = {}) => {
  return useQuery<GetPromoCodeTypesData>(
    GET_PROMO_CODE_TYPES,
    queryParams(params)
  );
};
