import { ObjectImagePurpose } from 'app/typings';

type ImagePurpose = ObjectImagePurpose;

export const getCurrentImageForEachRatio = (ratiosAndImages?: ImagePurpose) => {
  if (ratiosAndImages) {
    const images = Object.keys(ratiosAndImages)
      .map((key: string) => {
        if (key.startsWith('ratio')) {
          const imagesForRatio = ratiosAndImages[key];
          if (imagesForRatio && imagesForRatio.length > 0) {
            return imagesForRatio.slice(-1)[0];
          }
        }
        return null;
      })
      .filter((value: any) => !!value);
    return images;
  }
  return [];
};
