import { useMutation } from 'react-apollo';

import {
  CreatePrimaryRoleData,
  DeletePrimaryRoleData,
  UpdatePrimaryRoleData,
} from 'app/typings/roles';
import {
  CREATE_PRIMARY_ROLE,
  DELETE_PRIMARY_ROLE,
  UPDATE_PRIMARY_ROLE,
} from 'app/admin/graphql/roles/mutations';

export const CreatePrimaryRole = () => {
  return useMutation<CreatePrimaryRoleData>(CREATE_PRIMARY_ROLE)[0];
};

export const DeletePrimaryRole = () => {
  return useMutation<DeletePrimaryRoleData>(DELETE_PRIMARY_ROLE)[0];
};

export const UpdatePrimaryRole = () => {
  return useMutation<UpdatePrimaryRoleData>(UPDATE_PRIMARY_ROLE)[0];
};
