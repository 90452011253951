import gql from 'graphql-tag';

export const CREATE_EVENT_COLLECTION = gql`
  mutation createEventCollection(
    $title: String!
    $description: String
    $eventIds: [Int!]
  ) {
    createEventCollection(
      title: $title
      description: $description
      eventIds: $eventIds
    ) {
      eventCollection {
        id
      }
      success
    }
  }
`;

export const UPDATE_EVENT_COLLECTION = gql`
  mutation updateEventCollection(
    $cachedSlug: String!
    $description: String
    $eventCollectionId: Int!
    $eventIds: [Int!]
    $status: String!
    $title: String!
    $eventHeadline: String
    $eventDescription: String
    $merchandisingProperties: [Int!]
    $whatToExpect: String
    $cityDisplayType: String!
    $selectedCityIds: [Int!]
  ) {
    updateEventCollection(
      cachedSlug: $cachedSlug
      description: $description
      eventCollectionId: $eventCollectionId
      eventIds: $eventIds
      status: $status
      title: $title
      eventHeadline: $eventHeadline
      eventDescription: $eventDescription
      merchandisingProperties: $merchandisingProperties
      whatToExpect: $whatToExpect
      cityDisplayType: $cityDisplayType
      selectedCityIds: $selectedCityIds
    ) {
      eventCollection {
        id
        title
        description
        cachedSlug
        collectionType
        eventHeadline
        eventDescription
        merchandisingProperties {
          id
          propertyGroupId
          propertyGroupName
          key
          name
          iconName
          position
        }
        whatToExpect
        eventsCount
        status
        cities {
          id
          title
          cachedSlug
          timezone
        }
        events {
          id
          startsAt
          localStartsAt
          neighborhood {
            id
            title
          }
          city {
            id
            cachedSlug
          }
        }
        images {
          header {
            ratio1x1 {
              id
              ratio
              filename
              fileLocation
            }
          }
        }
      }
      success
    }
  }
`;

export const UPDATE_EVENT_COLLECTION_POSITIONS = gql`
  mutation updateEventCollectionPositions(
    $eventCollectionPositions: [EventCollectionPositionInput!]!
    $cityId: Int!
  ) {
    updateEventCollectionPositions(
      eventCollectionPositions: $eventCollectionPositions
      cityId: $cityId
    ) {
      success
      city {
        id
        eventCollections {
          id
          title
          description
          position
          cachedSlug
          upcomingEventsCount
          status
          events {
            id
            isPublished
            startsAt
            localStartsAt
            city {
              id
              title
            }
            venue {
              id
              neighborhood {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;
