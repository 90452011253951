import gql from 'graphql-tag';

export const GET_EMAIL_TYPES = gql`
  query GetEmailTypes($includeOnlyEventEmails: Boolean) {
    emailTypes(includeOnlyEventEmails: $includeOnlyEventEmails) {
      emailTypes {
        key
        name
      }
      emailGroups {
        key
        name
      }
    }
  }
`;
