import gql from 'graphql-tag';

import { citySocialUrls } from 'app/shared/utils/socialLinks';

export const GET_CITY_BASIC_INFO = gql`
  query GetCityBasicInfo($id: Int!) {
    city(id: $id) {
      id
      title
      titlesByLocale {
        locale
        title
      }
      cachedSlug
      country {
        id
        title
        bookingFee
        displayBookingFee
      }
      businessOwner {
        id
        title
      }
      neighborhoodsFullInfo {
        id
        cachedSlug
        title
        venuesCount
      }
      latitude
      longitude
      timezone
      currencySymbol
      aliases
      cityStatus
      cityOperatingModel
      sofarOperatedMarketType
      imageUrl
      isAcceptingArtistRequests
      socialUrls {
        ${citySocialUrls.join(' ')}
      }
      inviteEmailMessage
      revealAddressEmailMessage
      isAutomatedEmailEnabled
      artistApplicationNotificationEmails
      isArtistApplicationNotificationEnabled
      useFromEmailForAllEmails
      fromEmail
      mainArtistOps {
        id
        firstName
        lastName
        email
      }
      mainCrewOps {
        id
        firstName
        lastName
        email
      }
      mainVenueOps {
        id
        firstName
        lastName
        email
      }
      tieredPrice {
        priceSun
        priceMon
        priceTue
        priceWed
        priceThu
        priceFri
        priceSat
      }
      defaultEventPrice
      maxTicketsPerAttendee
      isAutoInviteEnabled
      inviteFirstDayPercentage
      inviteSubsequentDaysPercentage
      isAutoStopApplyEnabled
      maximumPromoCodeUses
      maximumGuaranteedPromoCodeUses
      maximumCustomPromoCodeUses
      maximumPercentagePromoCodeUses
      sendPromoCodesToFirstTimers
      isUnluckyEmailPromoCodeEnabled
      topGlobalCity
      topCity
      noindex
      artistsCount
      venuesCount
      pastEventsCount
      createdAt
      bookingFee
      displayBookingFee
      isTieredArtistCompensationEnabled
      description
      whatToExpect
      nearbySecondaryCities {
        id
        title
      }
      eventCollections {
        id
        title
        description
        position
        cachedSlug
        upcomingEventsInCityCount
        status
        events {
          id
          isPublished
          startsAt
          localStartsAt
          city {
            id
            title
          }
          venue {
            id
            neighborhood {
              id
              title
            }
          }
        }
      }
    }
  }
`;
