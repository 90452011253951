import gql from 'graphql-tag';

export const GET_INTERNAL_USER_BASIC_INFO = gql`
  query GetInternalUserBasicInfo($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      homeCity {
        id
        title
        country {
          id
          title
        }
      }
      mobile {
        countryCodeIso3166
        countryCode
        numberBody
      }
      slackUserId
      primaryRoles {
        key
        name
        resourceType
      }
      roles {
        key
        name
        resourceId
        resourceType
        resourceName
      }
      associatedArtists {
        id
        title
      }
      eventsStaffedSummaries {
        primaryRole {
          key
          name
        }
        numEventsStaffed
      }
      hrisId
      hrisSystem {
        key
        name
      }
      additionalEmail
      currentSignInAt
      createdAt
      signInCount
      acknowledgedGdprAgreementAt
      acknowledgedCuratorAgreementAt
      acknowledgedCuratorAgreement2At
      declinedCuratorAgreement2At
    }
  }
`;

export const GET_USER_BASIC_INFO = gql`
  query GetUserBasicInfo($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      homeCity {
        id
        title
        country {
          id
          title
        }
      }
      cityOther
      isInternal
      gender
      birthYear
      mobile {
        countryCodeIso3166
        countryCode
        numberBody
      }
      associatedArtists {
        id
        title
      }
      numTicketsPurchased
      eventsAttendedInfo {
        eventsAttended {
          eventId
          eventStartsAt
          city {
            title
            cachedSlug
          }
          isGuestlisted
          canGo
          cantGo
          isPaid
          noShow
          numTicketsPurchased
          paymentAmount
          paymentAmountCurrencySymbol
          externalConfirmationId
        }
        eventsAttendedSummaryInfo {
          numGuestlisted
          numCanGo
          numCantGo
          numPaid
          numNoShow
          numTicketsPurchased
        }
      }
      competitionsEntered {
        id
        title
        closesAt
        currentState
        isWinner
      }
      latitude
      longitude
      howHeardAbout
      signUpLastTouchUtmMedium
      signUpLastTouchUtmSource
      signUpLastTouchUtmCampaign
      signUpLastTouchReferrer
      generalNotes
      isUnluckyEmailEnabled
      currentSignInAt
      createdAt
      signInCount
      isGuest
    }
  }
`;

export const GET_USER_FOR_CREW_DIRECTORY = gql`
  query GetUserForCrewDirectory($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      mobile {
        countryCodeIso3166
        countryCode
        numberBody
      }
      slackUserId
      cityStaffedEventCounts {
        city {
          id
          title
        }
        staffedEventsCount
      }
      crewStatus
    }
  }
`;

export const GET_USER_STAFFED_EVENTS_PAST = gql`
  query GetUserStaffedEventsPast($id: Int!, $page: Int, $perPage: Int) {
    user(id: $id) {
      id
      pastStaffedEvents(page: $page, perPage: $perPage) {
        events {
          id
          cancelled
          city {
            id
            title
          }
          localStartsAt
          providedFeedback
          staff {
            id
            role {
              key
              name
            }
            userAddedAt
          }
          theme {
            id
            title
          }
          type
        }
        metadata {
          totalRecords
          currentPage
        }
      }
    }
  }
`;

export const GET_USER_STAFFED_EVENTS_UPCOMING = gql`
  query GetUserStaffedEventsUpcoming($id: Int!, $page: Int, $perPage: Int) {
    user(id: $id) {
      id
      upcomingStaffedEvents(page: $page, perPage: $perPage) {
        events {
          id
          cancelled
          city {
            id
            title
          }
          localStartsAt
          providedFeedback
          staff {
            id
            role {
              key
              name
            }
            userAddedAt
          }
          theme {
            id
            title
          }
          type
        }
        metadata {
          totalRecords
          currentPage
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers(
    $userSearch: String
    $city: String
    $role: String
    $permission: String
    $permissionCity: String
    $associatedWithArtists: Boolean
    $age: String
    $availableOnDate: String
    $loginActivity: String
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    users(
      userSearch: $userSearch
      city: $city
      role: $role
      permission: $permission
      permissionCity: $permissionCity
      associatedWithArtists: $associatedWithArtists
      age: $age
      availableOnDate: $availableOnDate
      loginActivity: $loginActivity
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      users {
        id
        firstName
        lastName
        email
        homeCity {
          id
          title
          country {
            id
            title
          }
        }
        cityOther
        gender
        birthYear
        currentSignInAt
        createdAt
        signInCount
      }
      metadata {
        totalRecords
      }
    }
  }
`;

// NOTE: Only send eventRole with this particular query if also sending validEventStaffForEventId
export const GET_USERS_FOR_ADD_STAFF_TO_EVENT = gql`
  query GetUsersForAddStaffToEvent(
    $permission: String
    $permissionCity: String
    $availableOnDate: String
    $skipPagination: Boolean
    $isLiteQuery: Boolean
    $validEventStaffForEventId: Int
    $eventRole: String
  ) {
    users(
      permission: $permission
      permissionCity: $permissionCity
      availableOnDate: $availableOnDate
      skipPagination: $skipPagination
      isLiteQuery: $isLiteQuery
      validEventStaffForEventId: $validEventStaffForEventId
      eventRole: $eventRole
    ) {
      users {
        id
        firstName
        lastName
        email
      }
      metadata {
        totalRecords
      }
    }
  }
`;

export const GET_USERS_FOR_CREW_DIRECTORY = gql`
  query GetUsersForCrewDirectory(
    $userSearch: String
    $eventRole: String
    $eventRoleCity: String
    $eventRoleBusinessOwner: String
    $isCrew: Boolean
    $crewStatus: String
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
  ) {
    users(
      userSearch: $userSearch
      eventRole: $eventRole
      eventRoleCity: $eventRoleCity
      eventRoleBusinessOwner: $eventRoleBusinessOwner
      isCrew: $isCrew
      crewStatus: $crewStatus
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      users {
        id
        firstName
        lastName
        email
        upcomingStaffedEventsCount
        pastStaffedEventsCount
        lastStaffedEventAt
        crewRoleCities {
          id
          title
        }
        homeCity {
          id
          title
        }
        crewStatus
      }
      metadata {
        totalRecords
      }
    }
  }
`;

export const GET_USERS_FOR_FROM_EMAIL_SETTINGS_EDIT = gql`
  query GetUsersForFromEmailSettingsEdit(
    $role: String
    $roleCity: String
    $roleCuratorGroup: String
    $page: Int
    $perPage: Int
    $isLiteQuery: Boolean
  ) {
    users(
      role: $role
      roleCity: $roleCity
      roleCuratorGroup: $roleCuratorGroup
      page: $page
      perPage: $perPage
      isLiteQuery: $isLiteQuery
    ) {
      users {
        id
        firstName
        lastName
        email
      }
      metadata {
        totalRecords
      }
    }
  }
`;

export const GET_USERS_FOR_INTERNAL_USER_DIRECTORY = gql`
  query GetUsersForInternalUserDirectory(
    $userSearch: String
    $role: String
    $roleCity: String
    $roleCuratorGroup: String
    $eventsStaffed: String
    $associatedWithArtists: Boolean
    $loginActivity: String
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
  ) {
    users(
      userSearch: $userSearch
      role: $role
      roleCity: $roleCity
      roleCuratorGroup: $roleCuratorGroup
      eventsStaffed: $eventsStaffed
      associatedWithArtists: $associatedWithArtists
      loginActivity: $loginActivity
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      users {
        id
        firstName
        lastName
        email
        homeCity {
          id
          title
          country {
            id
            title
          }
        }
        primaryRoles {
          key
          name
          shortName
          resourceType
        }
        hrisId
        hrisSystem {
          key
          name
        }
        currentSignInAt
        createdAt
        signInCount
      }
      metadata {
        totalRecords
      }
    }
  }
`;

export const GET_USERS_FOR_USER_TYPEAHEAD = gql`
  query GetUsersForUserTypeahead(
    $userSearch: String
    $page: Int
    $perPage: Int
    $isLiteQuery: Boolean
  ) {
    users(
      userSearch: $userSearch
      page: $page
      perPage: $perPage
      isLiteQuery: $isLiteQuery
    ) {
      users {
        id
        firstName
        lastName
        email
      }
      metadata {
        totalRecords
      }
    }
  }
`;
