import gql from 'graphql-tag';

export const GET_VENUE_APPLICATIONS = gql`
  query GetVenueApplications(
    $city: String
    $cityIds: String
    $archivedStatus: String
    $capacity: String
    $venueApplicationSearch: String
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
  ) {
    venueApplications(
      city: $city
      cityIds: $cityIds
      archivedStatus: $archivedStatus
      capacity: $capacity
      venueApplicationSearch: $venueApplicationSearch
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      venueApplications {
        id
        firstName
        lastName
        address
        emailAddress
        phoneNumber
        capacity
        city {
          id
          title
        }
        neighbourhood
        venueType
        capacity
        archived
        venue {
          id
        }
        showByob
        showPet
        accessible
        isOutdoor
        createdAt
      }
      metadata {
        totalRecords
      }
    }
  }
`;
