import gql from 'graphql-tag';

export const GET_THEMES_LITE = gql`
  query GetThemesLite($isLiteQuery: Boolean) {
    themes(isLiteQuery: $isLiteQuery) {
      themes {
        id
        title
      }
    }
  }
`;

export const GET_THEMES = gql`
  query GetThemes(
    $themeSearch: String
    $archivedStatus: String
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
  ) {
    themes(
      themeSearch: $themeSearch
      archivedStatus: $archivedStatus
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      themes {
        id
        title
        notes
        isArchived
        pastEventsWithThemeCount
        upcomingEventsWithThemeCount
        createdAt
      }
      metadata {
        currentPage
        totalRecords
      }
    }
  }
`;
