import { Artist } from 'app/typings/artists';
import { ArtistSetup } from 'app/typings/artistSetups';
import { BookingPreference } from 'app/typings/bookingPreferences';
import { TouringCity } from 'app/typings/bookingRequests';
import { City } from 'app/typings/cities';
import { SharedGetDataParameters } from 'app/typings/generics';
import { SocialUrls } from 'app/typings/socialUrls';
import { User } from 'app/typings/users';

export interface ArtistApplication {
  __typename?: 'ArtistApplication';
  id: number;
  city?: City;
  cityId?: number;
  touringCities?: TouringCity[];
  touringFrom?: string;
  touringUntil?: string;
  artistName?: string;
  reachedOut?: boolean;
  interested?: boolean;
  votesApprovedCount?: number;
  votesNotApprovedCount?: number;
  userHasVoted?: boolean;
  emailAddress?: string;
  portfolioUrl1?: string;
  portfolioUrl2?: string;
  portfolioUrl3?: string;
  referrer?: User;
  socialUrls?: SocialUrls;
  homeCityDescription?: string;
  homeCity?: string;
  homeState?: string;
  homeCountryCode?: string;
  homeCityLatitude?: number | null;
  homeCityLongitude?: number | null;
  artistSetups?: ArtistSetup[];
  bookingPreferences?: BookingPreference[];
  archived?: boolean;
  decisionMadeAt?: string;
  decisionMadeByUser?: User;
  artist?: Artist;
  isAvailableLastMinute: boolean;
  createdAt?: string;
  customEmailMessage?: string;
  status?: string;
  updatedAt?: string;
}

export interface GetArtistApplicationVariables extends SharedGetDataParameters {
  id?: number;
}

export interface GetArtistApplicationData {
  artistApplication: ArtistApplication;
}

export interface GetArtistApplicationsVariables
  extends SharedGetDataParameters {
  cityOfApplication?: string;
  cityIdsOfApplication?: string;
  closestSofarCity?: string;
  createdDate?: string;
  businessOwner?: string;
  status?: string;
  artistApplicationSearch?: string | null;
  orderBy?: string;
  orderDirection?: string;
  page?: number;
  perPage?: number;
}

export interface GetArtistApplicationsData {
  __typename?: string;
  artistApplications: {
    artistApplications: ArtistApplication[];
    metadata: {
      totalRecords: number;
    };
  };
}

export interface AcceptArtistApplicationData {
  artist: Artist;
  success: boolean;
}

export interface RejectArtistApplicationData {
  success: boolean;
}

export interface GetArtistApplicationsOldParameters
  extends SharedGetDataParameters {
  city?: string;
  archivedStatus?: string;
  contactStatus?: string;
  artistApplicationSearch?: string | null;
  orderBy?: string;
  orderDirection?: string;
  page?: number;
  perPage?: number;
}

export interface GetArtistApplicationsOldDataResponse {
  __typename?: string;
  artistApplicationsOld: {
    artistApplicationsOld: ArtistApplication[];
    metadata: {
      totalRecords: number;
    };
  };
}

export interface CreateArtistApplicationOldParameters {
  emailAddress: string;
  artistName: string;
  homeCityDescription: string;
  homeCity: string;
  homeState: string;
  homeCountryCode: string;
  homeCityLatitude: number | null;
  homeCityLongitude: number | null;
  cityId?: number;
  touringCities?: TouringCity[];
  interested?: boolean;
  portfolioUrl1: string;
  portfolioUrl2?: string;
  portfolioUrl3?: string;
  urlWebsite?: string;
  urlFacebook?: string;
  urlInstagram?: string;
  urlTwitter?: string;
  urlSpotify?: string;
  urlSupport?: string;
  urlYoutube?: string;
}

export interface CreateArtistApplicationParameters {
  emailAddress: string;
  artistName: string;
  phoneNumber: string;
  homeCityDescription: string;
  homeCity: string;
  homeState: string;
  homeCountryCode: string;
  homeCityLatitude: number | null;
  homeCityLongitude: number | null;
  cityId?: number;
  portfolioUrl1: string;
  portfolioUrl2?: string;
  portfolioUrl3?: string;
  socialUrls?: SocialUrls;
}

export interface CreateArtistFromArtistApplicationApiResponse {
  success: boolean;
  artist: Artist;
}

export interface ArchiveArtistApplicationApiResponse {
  success: boolean;
  artistApplication: ArtistApplication;
}

export interface UpdateArtistApplicationContactStatusApiResponse {
  success: boolean;
  artistApplication: ArtistApplication;
}

export interface UpdateArtistApplicationReferrerData {
  success: boolean;
  artistApplication: ArtistApplication;
}

export interface CreateArtistApplicationVoteParameters {
  id: number;
  approved: boolean;
}

export interface CreateArtistApplicationVoteApiResponse {
  success: boolean;
  artistApplication: ArtistApplication;
}

export interface ValidateArtistApplicationData {
  validateArtistApplication: {
    success: boolean;
    failedValidations: string[];
  };
}

export interface ValidateArtistApplicationVariables {
  artistName?: string;
  cityId?: string;
  email?: string;
  skip?: boolean;
}

export interface ArtistApplicationFormData {
  artistName: string;
  emailAddress: string;
  mailingList?: boolean;
  homeCityDescription: string;
  homeCity: string;
  homeState: string;
  homeCountryCode: string;
  homeCityLatitude: number | null;
  homeCityLongitude: number | null;
  cityId?: number;
  touringCities?: TouringCity[];
  portfolioUrl1: string;
  portfolioUrl2?: string;
  portfolioUrl3?: string;
  websiteUrl?: string;
  facebookUrl?: string;
  twitterUrl?: string;
  youtubeUrl?: string;
  instagramUrl?: string;
  spotifyUrl?: string;
  supportUrl?: string;
}

export interface CreateArtistApplicationOld {
  id: number;
  artistName: string;
  emailAddress: string;
}

export interface CreateArtistApplication {
  id: number;
  artistName: string;
  emailAddress: string;
}

export interface CreateArtistApplicationOldApiResponse {
  createArtistApplicationOld: CreateArtistApplicationOld;
}

export interface CreateArtistApplicationData {
  createArtistApplication: CreateArtistApplication;
}

export enum ArtistApplicationStatus {
  OPEN = 'open',
}

export enum ArtistApplicationMainDecision {
  ACCEPT_WITH_BOOKING = 'acceptWithBooking',
  ACCEPT_WITHOUT_BOOKING = 'acceptWithoutBooking',
  REJECT_OR_SOFT_REJECT = 'rejectOrSoftReject',
}

export enum ArtistApplicationRejectDecision {
  REJECT = 'reject',
  SOFT_REJECT = 'softReject',
  SOFT_REJECT_REAPPLY = 'softRejectAndReapply',
}

export enum ArtistApplicationOfferDecision {
  CREATE_NEW_OFFER = 'createNewOffer',
  ADD_TO_EXISTING_OFFER = 'addToExistingOffer',
}
