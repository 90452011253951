import { useQuery } from 'react-apollo';

import {
  GetEmailTypesData,
  GetEmailTypesVariables,
} from 'app/typings/emailTypes';
import { queryParams } from 'app/shared/utils/queries';
import { GET_EMAIL_TYPES } from 'app/admin/graphql/emailTypes/queries';

export const GetEmailTypes = (params: GetEmailTypesVariables = {}) => {
  return useQuery<GetEmailTypesData>(GET_EMAIL_TYPES, queryParams(params));
};
