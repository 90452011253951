import { useMutation } from 'react-apollo';

import {
  CreateBroadcastData,
  DeleteBroadcastData,
  UpdateBroadcastData,
} from 'app/typings/broadcasts';
import {
  CREATE_BROADCAST,
  DELETE_BROADCAST,
  UPDATE_BROADCAST,
} from 'app/admin/graphql/broadcasts/mutations';

export const CreateBroadcast = () => {
  return useMutation<CreateBroadcastData>(CREATE_BROADCAST)[0];
};

export const DeleteBroadcast = () => {
  return useMutation<DeleteBroadcastData>(DELETE_BROADCAST)[0];
};

export const UpdateBroadcast = () => {
  return useMutation<UpdateBroadcastData>(UPDATE_BROADCAST)[0];
};
