import { useQuery } from 'react-apollo';

import {
  GetArtistMergeInfoData,
  GetArtistMergeInfoVariables,
  GetDuplicateArtistsData,
  GetDuplicateArtistsVariables,
  GetDuplicatedArtistsForTitleData,
  GetDuplicatedArtistsForTitleVariables,
} from 'app/typings/artistMerges';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_ARTIST_MERGE_INFO,
  GET_DUPLICATE_ARTISTS,
  GET_DUPLICATED_ARTISTS_FOR_TITLE,
} from 'app/admin/graphql/artistMerges/queries';

export const GetArtistMergeInfo = (
  params: GetArtistMergeInfoVariables = {}
) => {
  return useQuery<GetArtistMergeInfoData>(
    GET_ARTIST_MERGE_INFO,
    queryParams(params)
  );
};

export const GetDuplicateArtists = (
  params: GetDuplicateArtistsVariables = {}
) => {
  return useQuery<GetDuplicateArtistsData>(
    GET_DUPLICATE_ARTISTS,
    queryParams(params)
  );
};

export const GetDuplicatedArtistsForTitle = (
  params: GetDuplicatedArtistsForTitleVariables = {}
) => {
  return useQuery<GetDuplicatedArtistsForTitleData>(
    GET_DUPLICATED_ARTISTS_FOR_TITLE,
    queryParams(params)
  );
};
