import gql from 'graphql-tag';

export const CREATE_CONTINENT = gql`
  mutation createContinent($title: String, $continentCode: String) {
    createContinent(title: $title, continentCode: $continentCode) {
      success
      continent {
        id
        title
        continentCode
        cachedSlug
        countriesCount
      }
    }
  }
`;

export const DELETE_CONTINENT = gql`
  mutation deleteContinent($continentId: Int!) {
    deleteContinent(continentId: $continentId) {
      success
    }
  }
`;

export const UPDATE_CONTINENT = gql`
  mutation updateContinent($continentId: Int!, $title: String) {
    updateContinent(continentId: $continentId, title: $title) {
      success
      continent {
        id
        title
        continentCode
        cachedSlug
        countriesCount
      }
    }
  }
`;
