import { useQuery } from 'react-apollo';

import {
  GetBookingRequestsData,
  GetBookingRequestsVariables,
} from 'app/typings/bookingRequests';
import { queryParams } from 'app/shared/utils/queries';
import { GET_BOOKING_REQUESTS_FOR_ADMIN } from 'app/admin/graphql/bookingRequests/queries';

export const GetBookingRequests = (
  params: GetBookingRequestsVariables = {}
) => {
  return useQuery<GetBookingRequestsData>(
    GET_BOOKING_REQUESTS_FOR_ADMIN,
    queryParams(params)
  );
};
