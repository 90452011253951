import { useQuery } from 'react-apollo';

import {
  GetFeedbackPhrasesData,
  GetFeedbackPhrasesVariables,
  GetFeedbacksData,
  GetFeedbacksVariables,
} from 'app/typings/feedbacks';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_FEEDBACK_PHRASES,
  GET_FEEDBACKS,
} from 'app/admin/graphql/feedbacks/queries';

export const GetFeedbacks = (params: GetFeedbacksVariables = {}) => {
  return useQuery<GetFeedbacksData>(GET_FEEDBACKS, queryParams(params));
};

export const GetFeedbackPhrases = (
  params: GetFeedbackPhrasesVariables = {}
) => {
  return useQuery<GetFeedbackPhrasesData>(
    GET_FEEDBACK_PHRASES,
    queryParams(params)
  );
};
