import { useQuery } from 'react-apollo';

import {
  GetEmailMessageData,
  GetEmailMessagesData,
  GetEmailMessagesVariables,
  GetEmailMessageVariables,
} from 'app/typings/emailMessages';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_EMAIL_MESSAGE,
  GET_EMAIL_MESSAGES,
} from 'app/admin/graphql/emailMessages/queries';

export const GetEmailMessages = (params: GetEmailMessagesVariables = {}) => {
  return useQuery<GetEmailMessagesData>(
    GET_EMAIL_MESSAGES,
    queryParams(params)
  );
};

export const GetEmailMessage = (params: GetEmailMessageVariables = {}) => {
  return useQuery<GetEmailMessageData>(GET_EMAIL_MESSAGE, queryParams(params));
};
