import gql from 'graphql-tag';

export const CREATE_EVENTS = gql`
  mutation createEvents(
    $eventType: String!
    $eventStatus: String!
    $attendeeTicketingFlow: String
    $campaignId: Int
    $dates: [String!]!
    $city: String!
    $neighborhood: String
    $venueId: Int
    $setupInfo: String
    $additionalNotes: String
    $onSaleAt: String
    $isPurchasable: Boolean
    $formatType: String
  ) {
    createEvents(
      eventType: $eventType
      eventStatus: $eventStatus
      attendeeTicketingFlow: $attendeeTicketingFlow
      campaignId: $campaignId
      dates: $dates
      city: $city
      neighborhood: $neighborhood
      venueId: $venueId
      setupInfo: $setupInfo
      additionalNotes: $additionalNotes
      onSaleAt: $onSaleAt
      isPurchasable: $isPurchasable
      formatType: $formatType
    ) {
      events {
        id
        startsAt
        isPurchasable
        isPublished
        isGloballyPromoted
        type
        formatType
        neighborhood {
          id
          title
        }
        city {
          id
          title
          country {
            id
            title
            currencySymbol
            countryCode
            currency
          }
        }
        venue {
          id
          venueName
          address
          venueType
          neighborhood {
            id
            title
          }
          capacity
          imageUrl
          mapUrl
          numTicketsAvailableForSale
          properties {
            id
            propertyGroupId
            key
            name
            iconName
            position
            infoUrl
          }
        }
      }
      success
    }
  }
`;

export const CREATE_EVENT_STAFF_MEMBER = gql`
  mutation createEventStaffMember($eventId: Int!, $roleKey: String!) {
    createEventStaffMember(eventId: $eventId, roleKey: $roleKey) {
      success
      event {
        id
        staff {
          id
          role {
            name
            key
          }
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export const DELETE_EVENT_STAFF_MEMBER = gql`
  mutation deleteEventStaffMember($eventStaffMemberId: Int!) {
    deleteEventStaffMember(eventStaffMemberId: $eventStaffMemberId) {
      success
      event {
        id
        staff {
          id
          role {
            name
            key
          }
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent(
    $eventId: Int!
    $venueId: Int
    $venueOfferId: Int
    $isVenueConfirmed: Boolean
    $isPublished: Boolean
    $isGloballyPromoted: Boolean
    $isPurchasable: Boolean
    $isAppliable: Boolean
    $isAutoInviteEnabled: Boolean
    $cancelled: Boolean
    $cancellationReasonDescription: String
    $cancellationReasonId: Int
    $isArtistSelfBookingEnabled: Boolean
    $sofarOperatedMarketType: String
    $formatType: String
    $businessOwnerId: Int
    $properties: [Int!]
    $processProperties: Boolean
    $merchandisingProperties: [Int!]
    $audioNotes: String
    $internalVenueNotes: String
    $mcNotes: String
    $seatingNotes: String
    $suppliesNeededNotes: String
    $timingNotes: String
    $maxPaInputs: String
    $numTicketsAvailableForSale: String
    $maxTicketsPerAttendee: Int
    $maximumPromoCodeUses: Int
    $maximumGuaranteedPromoCodeUses: Int
    $maximumCustomPromoCodeUses: Int
    $maximumPercentagePromoCodeUses: Int
    $isFilmed: Boolean
    $ticketPrice: Int
    $eventBookingFee: Int
    $theme: String
    $competitionsToUnassociate: [Int!]
    $competitionsToAssociate: [Int!]
    $localCrewLoadInAt: String
    $localArtistLoadInAt: String
    $localGuestsArriveAt: String
    $localStartsAt: String
    $localEndsAt: String
    $eventDate: String
    $onSaleAt: String
    $eventArtistCompensation: Int
    $showInNearbyPrimaryCities: Boolean
    $externalEventNotes: String
    $externalVenueNotes: String
    $inviteEmailMessage: String
    $revealAddressEmailMessage: String
    $primaryEventCollectionId: Int
    $headline: String
    $seating: String
    $isSpecialEvent: Boolean
    $specialEventUrl: String
    $eventFormatExperimentId: Int
    $dealAmount: Int
    $campaignId: Int
    $partnerPromotionTitle: String
    $partnerPromotionDescription: String
    $partnerPromotionButtonText: String
    $partnerPromotionButtonUrl: String
    $partnerPromotionSectionEnabled: Boolean
    $venueBudget: Int
    $venueBudgetDescription: String
    $sponsorNotes: String
    $sponsorPostShowSummary: String
  ) {
    updateEvent(
      eventId: $eventId
      venueId: $venueId
      venueOfferId: $venueOfferId
      isVenueConfirmed: $isVenueConfirmed
      isPublished: $isPublished
      isGloballyPromoted: $isGloballyPromoted
      isPurchasable: $isPurchasable
      isAppliable: $isAppliable
      isAutoInviteEnabled: $isAutoInviteEnabled
      cancelled: $cancelled
      cancellationReasonDescription: $cancellationReasonDescription
      cancellationReasonId: $cancellationReasonId
      isArtistSelfBookingEnabled: $isArtistSelfBookingEnabled
      sofarOperatedMarketType: $sofarOperatedMarketType
      formatType: $formatType
      businessOwnerId: $businessOwnerId
      properties: $properties
      processProperties: $processProperties
      merchandisingProperties: $merchandisingProperties
      audioNotes: $audioNotes
      internalVenueNotes: $internalVenueNotes
      mcNotes: $mcNotes
      seatingNotes: $seatingNotes
      suppliesNeededNotes: $suppliesNeededNotes
      timingNotes: $timingNotes
      maxPaInputs: $maxPaInputs
      numTicketsAvailableForSale: $numTicketsAvailableForSale
      maxTicketsPerAttendee: $maxTicketsPerAttendee
      maximumPromoCodeUses: $maximumPromoCodeUses
      maximumGuaranteedPromoCodeUses: $maximumGuaranteedPromoCodeUses
      maximumCustomPromoCodeUses: $maximumCustomPromoCodeUses
      maximumPercentagePromoCodeUses: $maximumPercentagePromoCodeUses
      isFilmed: $isFilmed
      eventPrice: $ticketPrice
      eventBookingFee: $eventBookingFee
      theme: $theme
      competitionsToUnassociate: $competitionsToUnassociate
      competitionsToAssociate: $competitionsToAssociate
      localCrewLoadInAt: $localCrewLoadInAt
      localArtistLoadInAt: $localArtistLoadInAt
      localGuestsArriveAt: $localGuestsArriveAt
      localStartsAt: $localStartsAt
      localEndsAt: $localEndsAt
      eventDate: $eventDate
      onSaleAt: $onSaleAt
      eventArtistCompensation: $eventArtistCompensation
      showInNearbyPrimaryCities: $showInNearbyPrimaryCities
      externalEventNotes: $externalEventNotes
      externalVenueNotes: $externalVenueNotes
      inviteEmailMessage: $inviteEmailMessage
      revealAddressEmailMessage: $revealAddressEmailMessage
      primaryEventCollectionId: $primaryEventCollectionId
      headline: $headline
      seating: $seating
      isSpecialEvent: $isSpecialEvent
      specialEventUrl: $specialEventUrl
      eventFormatExperimentId: $eventFormatExperimentId
      dealAmount: $dealAmount
      campaignId: $campaignId
      partnerPromotionTitle: $partnerPromotionTitle
      partnerPromotionDescription: $partnerPromotionDescription
      partnerPromotionButtonText: $partnerPromotionButtonText
      partnerPromotionButtonUrl: $partnerPromotionButtonUrl
      partnerPromotionSectionEnabled: $partnerPromotionSectionEnabled
      venueBudget: $venueBudget
      venueBudgetDescription: $venueBudgetDescription
      sponsorNotes: $sponsorNotes
      sponsorPostShowSummary: $sponsorPostShowSummary
    ) {
      success
      event {
        id
        cancelled
        isPublished
        isGloballyPromoted
        status
        numTicketsAvailableForSale
        crewLoadInAt
        artistLoadInAt
        guestsArriveAt
        startsAt
        endsAt
        localStartsAt
        onSaleAt
        artistCompensation
        eventArtistCompensation
        isPurchasable
        isAppliable
        isAutoInviteEnabled
        isArtistSelfBookingEnabled
        sofarOperatedMarketType
        formatType
        businessOwner {
          id
          title
        }
        numTicketsAvailableForSale
        maxTicketsPerAttendee
        maximumPromoCodeUses
        maximumPercentagePromoCodeUses
        maximumGuaranteedPromoCodeUses
        maximumCustomPromoCodeUses
        isFilmed
        price
        eventPrice
        eventBookingFee
        isVenueConfirmed
        audioNotes
        internalVenueNotes
        mcNotes
        seatingNotes
        suppliesNeededNotes
        neighborhood {
          id
          title
        }
        timingNotes
        theme {
          id
          title
        }
        showInNearbyPrimaryCities
        externalEventNotes
        externalVenueNotes
        headline
        inviteEmailMessage
        revealAddressEmailMessage
        imageUrl
        partnerPromotionTitle
        partnerPromotionDescription
        partnerPromotionButtonText
        partnerPromotionButtonUrl
        partnerPromotionSectionEnabled
        seating {
          key
          name
        }
        eventFormatExperiment {
          id
          title
        }
        isSpecialEvent
        specialEventUrl
        dealAmount
        sponsorNotes
        sponsorPostShowSummary
        venueBudget
        venueBudgetDescription
        maxPaInputs {
          key
          name
        }
        staff {
          id
          role {
            key
          }
        }
        properties {
          id
          propertyGroupId
          key
          name
          iconName
          position
          infoUrl
        }
        eventCollections {
          id
          title
          status
          description
          eventDescription
          eventHeadline
          isPrimaryCollection
          createdAt
          images {
            header {
              ratio16x9 {
                id
                ratio
                filename
                fileLocation
              }
            }
          }
          merchandisingProperties {
            id
            propertyGroupId
            propertyGroupName
            key
            name
            iconName
            position
          }
        }
        merchandisingProperties {
          id
          propertyGroupId
          propertyGroupName
          key
          name
          iconName
          position
        }
        venue {
          id
          address
          venueName
          localCrewLoadInAt
          localArtistLoadInAt
          localGuestsArriveAt
          localStartsAt
          localEndsAt
          capacity
        }
        venueOffers {
          id
          status
          venue {
            id
            address
            venueName
            imageUrl
            capacity
            neighborhood {
              id
              title
            }
            city {
              id
              title
            }
          }
          offer {
            id
            name
            status
            customEmailMessage
          }
        }
        competition {
          id
          title
          uid
        }
        campaign {
          id
          title
          promoCodesWithCampaignCount
          pastEventsWithCampaignCount
          upcomingEventsWithCampaignCount
          accountManagerUser {
            id
            firstName
            lastName
            email
            mobile {
              countryCode
              numberBody
            }
          }
          salesRepUser {
            id
            firstName
            lastName
            email
            mobile {
              countryCode
              numberBody
            }
          }
          partner {
            id
            title
            description
            address
            address2
            partnersCampaignsCount
            contactInfos {
              id
              firstName
              lastName
              email
              phoneNumber
              position
              isDayOfShowContact
              contactRelationship {
                id
                name
              }
            }
          }
          agency {
            id
            title
            contactInfos {
              id
              firstName
              lastName
              email
              phoneNumber
              position
              isDayOfShowContact
              contactRelationship {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_EVENT_STAFF_MEMBER = gql`
  mutation updateEventStaffMember(
    $eventId: Int!
    $roleKey: String!
    $userId: Int
    $skipMaxSignUpsRestriction: Boolean
    $skipAuthorizationRestriction: Boolean
    $skipOverrideRestriction: Boolean
  ) {
    updateEventStaffMember(
      eventId: $eventId
      roleKey: $roleKey
      userId: $userId
      skipMaxSignUpsRestriction: $skipMaxSignUpsRestriction
      skipAuthorizationRestriction: $skipAuthorizationRestriction
      skipOverrideRestriction: $skipOverrideRestriction
    ) {
      success
      event {
        id
        staff {
          id
          role {
            name
            key
          }
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;
