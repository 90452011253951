import gql from 'graphql-tag';

export const ACKNOWLEDGE_USER_AGREEMENT = gql`
  mutation acknowledgeUserAgreement($agreementName: String!, $action: String!) {
    acknowledgeUserAgreement(agreementName: $agreementName, action: $action) {
      success
      user {
        id
        acknowledgedGdprAgreementAt
        acknowledgedCuratorAgreementAt
        acknowledgedCuratorAgreement2At
        declinedCuratorAgreement2At
      }
    }
  }
`;

export const UPDATE_USER_FROM_ADMIN = gql`
  mutation updateUser(
    $userId: Int!
    $email: String
    $isUnluckyEmailEnabled: Boolean
    $firstName: String
    $lastName: String
    $homeCityId: String
    $cityOther: String
    $mobile: MobileAttributes
    $latitude: String
    $longitude: String
    $gender: String
    $birthYear: String
    $generalNotes: String
    $howHeardAbout: String
    $artistIdToAssociate: Int
    $artistIdsToUnassociate: [Int!]
    $slackUserId: String
    $hrisId: String
    $hrisSystem: String
    $additionalEmail: String
  ) {
    updateUser(
      userId: $userId
      email: $email
      isUnluckyEmailEnabled: $isUnluckyEmailEnabled
      firstName: $firstName
      lastName: $lastName
      homeCityId: $homeCityId
      cityOther: $cityOther
      mobile: $mobile
      latitude: $latitude
      longitude: $longitude
      gender: $gender
      birthYear: $birthYear
      generalNotes: $generalNotes
      howHeardAbout: $howHeardAbout
      artistIdToAssociate: $artistIdToAssociate
      artistIdsToUnassociate: $artistIdsToUnassociate
      slackUserId: $slackUserId
      hrisId: $hrisId
      hrisSystem: $hrisSystem
      additionalEmail: $additionalEmail
    ) {
      success
      user {
        id
        firstName
        lastName
        email
        isUnluckyEmailEnabled
        homeCity {
          id
          title
          country {
            id
            title
          }
        }
        cityOther
        gender
        birthYear
        mobile {
          countryCode
          numberBody
        }
        slackUserId
        associatedArtists {
          id
          title
        }
        latitude
        longitude
        generalNotes
        howHeardAbout
        visitorType
        hrisId
        hrisSystem {
          key
          name
        }
        additionalEmail
      }
    }
  }
`;

export const UPDATE_USER_FROM_CREW_DIRECTORY = gql`
  mutation updateUser(
    $userId: Int!
    $email: String
    $firstName: String
    $lastName: String
    $mobile: MobileAttributes
    $slackUserId: String
  ) {
    updateUser(
      userId: $userId
      email: $email
      firstName: $firstName
      lastName: $lastName
      mobile: $mobile
      slackUserId: $slackUserId
    ) {
      success
      user {
        id
        firstName
        lastName
        email
        mobile {
          countryCodeIso3166
          countryCode
          numberBody
        }
        slackUserId
      }
    }
  }
`;

export const UPDATE_USER_ROLES = gql`
  mutation updateUserRoles(
    $id: Int!
    $roleToAdd: String
    $roleToRemove: String
    $removeAllRoles: Boolean
    $resourceId: Int
    $resourceType: String
  ) {
    updateUserRoles(
      id: $id
      roleToAdd: $roleToAdd
      roleToRemove: $roleToRemove
      removeAllRoles: $removeAllRoles
      resourceId: $resourceId
      resourceType: $resourceType
    ) {
      success
      user {
        id
        primaryRoles {
          key
          name
          resourceType
        }
        roles {
          key
          name
          resourceId
          resourceType
          resourceName
        }
      }
    }
  }
`;
