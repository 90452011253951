import gql from 'graphql-tag';

export const CREATE_ARTIST = gql`
  mutation createArtist(
    $title: String!
    $email: String
    $homeCityDescription: String
    $homeCity: String
    $homeState: String
    $homeCountryCode: String
    $homeCityLatitude: Float
    $homeCityLongitude: Float
    $closestSofarCityId: Int
    $description: String
    $preferredPronoun: String
    $status: Int
    $processTags: Boolean
    $tags: [Int!]
    $processEligibilities: Boolean
    $eligibilities: [String!]
    $performingRightsOrganisationId: Int
    $contactInfos: [MutateContactInfo!]
    $signedStatus: String
    $userIdToAssociate: Int
  ) {
    createArtist(
      title: $title
      email: $email
      homeCityDescription: $homeCityDescription
      homeCity: $homeCity
      homeState: $homeState
      homeCountryCode: $homeCountryCode
      homeCityLatitude: $homeCityLatitude
      homeCityLongitude: $homeCityLongitude
      closestSofarCityId: $closestSofarCityId
      description: $description
      preferredPronoun: $preferredPronoun
      status: $status
      processTags: $processTags
      tags: $tags
      processEligibilities: $processEligibilities
      eligibilities: $eligibilities
      performingRightsOrganisationId: $performingRightsOrganisationId
      contactInfos: $contactInfos
      signedStatus: $signedStatus
      userIdToAssociate: $userIdToAssociate
    ) {
      artist {
        id
      }
      success
    }
  }
`;
