import gql from 'graphql-tag';

export const CREATE_CANCELLATION = gql`
  mutation createCancellation(
    $cancellableId: Int!
    $cancellableType: String!
    $cancellationReasonId: Int!
    $eventId: Int!
    $reasonDescription: String
  ) {
    createCancellation(
      cancellableId: $cancellableId
      cancellableType: $cancellableType
      cancellationReasonId: $cancellationReasonId
      eventId: $eventId
      reasonDescription: $reasonDescription
    ) {
      success
      event {
        id
      }
    }
  }
`;
