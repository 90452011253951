import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import useDisableScroll from 'app/shared/utils/useDisableScroll';
import useOutsideClick from 'app/shared/utils/useOutsideClick';
import { Box, Popper, PopperProps } from 'app/shared/components/atoms';

interface HoverDropdownProps extends PopperProps {
  label?: string;
  renderLabel?: (arg?: any) => any;
  children: any;
  hasFlyoutContainer?: boolean;
  disableScrollWhenOpen?: boolean;
  invertColor?: boolean;
  'data-qaid'?: string;
  id?: string;
  className?: string;
  isLastChild?: boolean;
  closeDropdownOnPageScroll?: boolean;
}

export const HoverDropdown: React.FC<HoverDropdownProps> = ({
  label,
  renderLabel,
  children,
  hasFlyoutContainer = true,
  disableScrollWhenOpen = false,
  closeDropdownOnPageScroll = false,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  offset,
  keepInViewPort,
  'data-qaid': qaId,
  id,
  className,
  isLastChild = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<any>(null);

  useDisableScroll(isOpen, disableScrollWhenOpen);

  const handleScroll = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  if (closeDropdownOnPageScroll) {
    window.addEventListener('scroll', handleScroll);
  }

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  return (
    <Box
      className={className}
      display="inline-block"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onClick={() => setIsOpen(!isOpen)}
    >
      <Box
        cursor="pointer"
        display="flex"
        id={id}
        ref={ref}
        onClick={() => setIsOpen(!isOpen)}
        data-qaid={qaId}
      >
        {renderLabel ? renderLabel(isOpen) : label}
      </Box>
      {isOpen &&
        createPortal(
          <Popper
            offset={offset}
            anchorEl={ref}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            keepInViewPort={keepInViewPort}
            isLastChild={isLastChild}
            width="218px"
          >
            {hasFlyoutContainer ? (
              <Box data-qaid={`${qaId}-flyout`} w="100%">
                {children}
              </Box>
            ) : (
              children
            )}
          </Popper>,
          document.body
        )}
      {isOpen && <Box position="absolute" cursor="pointer" />}
    </Box>
  );
};
