import gql from 'graphql-tag';

export const ADD_ARTIST_TO_OFFER = gql`
  mutation addArtistToOffer(
    $offerId: Int!
    $artistId: Int!
    $attribution: String
  ) {
    addArtistToOffer(
      offerId: $offerId
      artistId: $artistId
      attribution: $attribution
    ) {
      success
      offer {
        id
        name
        status
        createdAt
        createdBy {
          id
          firstName
          lastName
          email
        }
        city {
          id
          title
        }
        events {
          id
          localStartsAt
          neighborhood {
            id
            title
          }
          venue {
            id
            neighborhood {
              id
              title
            }
          }
        }
        artists {
          id
          title
          imageUrl
          homeCityDescription
        }
        artistsAndEventsBooked {
          artistId
          artistEvents {
            eventId
            isBooked
          }
        }
        artistsAndEventsNotBooked {
          artistId
          artistEvents {
            eventId
          }
        }
        artistIdsOpen
        artistIdsDeclined
        numArtistsTotal
        numEventPositionsTotal
      }
    }
  }
`;

export const CLOSE_OFFER = gql`
  mutation closeOffer($id: Int!) {
    closeOffer(id: $id) {
      success
      offer {
        id
        name
        status
      }
    }
  }
`;

export const CREATE_OFFER_FOR_ARTISTS = gql`
  mutation createOfferForArtists(
    $artistIds: [Int!]!
    $attribution: String
    $availableSpecificDateArtistIds: [Int!]
    $availableGeneralArtistIds: [Int!]
    $cityId: Int!
    $eventPositions: [EventPositionInput!]!
    $name: String
    $recommendedArtistIds: [Int!]
  ) {
    createOfferForArtists(
      artistIds: $artistIds
      attribution: $attribution
      availableSpecificDateArtistIds: $availableSpecificDateArtistIds
      availableGeneralArtistIds: $availableGeneralArtistIds
      cityId: $cityId
      eventPositions: $eventPositions
      name: $name
      recommendedArtistIds: $recommendedArtistIds
    ) {
      success
      offer {
        id
        name
        status
        performanceOffers {
          id
          artist {
            id
            title
            imageUrl
          }
          event {
            id
          }
          status
        }
      }
    }
  }
`;

export const CREATE_OFFER_FOR_VENUES = gql`
  mutation createOfferForVenues(
    $cityId: Int!
    $venueIds: [Int!]!
    $eventIds: [Int!]!
    $customEmailMessage: String
  ) {
    createOfferForVenues(
      cityId: $cityId
      venueIds: $venueIds
      eventIds: $eventIds
      customEmailMessage: $customEmailMessage
    ) {
      success
      offer {
        id
        status
        customEmailMessage
        venueOffers {
          offer {
            id
          }
          id
          status
          venue {
            id
            address
            venueName
            imageUrl
            capacity
            neighborhood {
              id
              title
            }
            city {
              id
              title
            }
          }
          event {
            id
          }
        }
      }
    }
  }
`;

export const ADD_VENUES_TO_OFFER = gql`
  mutation addVenuesToOffer(
    $eventId: Int!
    $offerId: Int!
    $venueIds: [Int!]!
  ) {
    addVenuesToOffer(
      eventId: $eventId
      offerId: $offerId
      venueIds: $venueIds
    ) {
      success
      offer {
        id
        status
        customEmailMessage
        venueOffers {
          offer {
            id
          }
          id
          status
          venue {
            id
            address
            venueName
            imageUrl
            capacity
            neighborhood {
              id
              title
            }
            city {
              id
              title
            }
          }
          event {
            id
          }
        }
      }
    }
  }
`;
