import Cookies from 'js-cookie';
import moment from 'moment';

import { AuthContextValues } from 'app/shared/context/Auth';

function firstSeenAtMoment() {
  const firstSeenAtCookie = Cookies.get('first_seen_at');

  if (firstSeenAtCookie && firstSeenAtCookie.length > 0) {
    return moment(firstSeenAtCookie);
  }

  return undefined;
}

export function isNewVisitor() {
  const firstSeenAt = firstSeenAtMoment();
  const oneDayAgo = moment().subtract(1, 'day');

  return !firstSeenAt || firstSeenAt.isAfter(oneDayAgo);
}

export function setFirstSeenAt(authContext: AuthContextValues) {
  const { user } = authContext;

  // Not logged in, and cookie is either unset or contains a blank string:
  if (!user && !firstSeenAtMoment()) {
    Cookies.set('first_seen_at', moment().toISOString(), { expires: 365 });
  }

  // Logged in, but cookie (or lack thereof) reflects a 'new' user:
  if (!!user && isNewVisitor()) {
    const oneWeekAgo = moment().subtract(1, 'week');
    Cookies.set('first_seen_at', oneWeekAgo.toISOString(), { expires: 365 });
  }
}
