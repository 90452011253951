export const getLogoWidth = (
  isMobile: boolean,
  mobileWidth: string,
  desktopWidth: string
) => {
  if (isMobile) {
    return mobileWidth;
  } else {
    return desktopWidth;
  }
};
