import React from 'react';
import { useIntl } from 'react-intl';

import { AuthFormType } from 'app/typings';
import { useCurrentTheme } from 'app/shared/theme';
import { useNavbarContext } from 'app/shared/context';
import { Box } from 'app/shared/components/atoms';
import { HoverDropdown } from 'app/shared/components/molecules';
import { ActionButton } from 'app/shared/components/molecules';
import { ReactComponent as Person } from 'icons/person.svg';

import { RenderLink } from './RenderLink';

const NavbarAuthMenuItem: React.FC = () => {
  const { colors } = useCurrentTheme();
  const intl = useIntl();

  const { setCurrentTabSelected, setIsAuthModalOpen } = useNavbarContext();
  const handleButtonClick = (tabName: AuthFormType) => {
    setCurrentTabSelected(tabName);
    setIsAuthModalOpen(true);
  };

  return (
    <Box flexDirection="column" mr={2}>
      <HoverDropdown
        offset={{ horizontal: 16 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        closeDropdownOnPageScroll={true}
        renderLabel={() => (
          <RenderLink
            title={intl.formatMessage({ id: 'navbar.signIn' })}
            icon={Person}
            dataQaid="navbar-sign-in"
            invertColors
          />
        )}
      >
        <Box
          backgroundColor={colors.primary.black.base}
          as="ul"
          p={4}
          bt={colors.accent.primaryAccent.base}
          bw="2px"
        >
          <ActionButton
            fullWidth
            data-qaid="navbar-auth-dropdown-log-in"
            variant="primary"
            mb={4}
            onClick={() => handleButtonClick('signIn')}
          >
            {intl.formatMessage({ id: 'navbar.signIn' })}
          </ActionButton>
          <ActionButton
            fullWidth
            data-qaid="navbar-auth-dropdown-sign-up"
            variant="tertiary"
            onClick={() => handleButtonClick('signUp')}
          >
            {intl.formatMessage({ id: 'shared.signUp' })}
          </ActionButton>
        </Box>
      </HoverDropdown>
    </Box>
  );
};

export default NavbarAuthMenuItem;
