import gql from 'graphql-tag';

import { citySocialUrls } from 'app/shared/utils/socialLinks';

export const CREATE_CITY = gql`
  mutation createCity(
    $titles: [TitleByLocaleInput!]
    $countryId: Int
    $businessOwnerId: Int
    $latitude: Float
    $longitude: Float
    $cityOperatingModel: String
    $sofarOperatedMarketType: String
    $aliases: String
    $socialUrls: SocialUrlsInput
  ) {
    createCity(
      titles: $titles
      countryId: $countryId
      businessOwnerId: $businessOwnerId
      latitude: $latitude
      longitude: $longitude
      cityOperatingModel: $cityOperatingModel
      sofarOperatedMarketType: $sofarOperatedMarketType
      aliases: $aliases
      socialUrls: $socialUrls
    ) {
      success
      city {
        id
        title
        cachedSlug
        country {
          id
          title
        }
        businessOwner {
          id
          title
        }
        neighborhoods {
          id
          title
        }
        latitude
        longitude
        cityOperatingModel
        sofarOperatedMarketType
        timezone
        aliases
        cityStatus
        socialUrls {
          ${citySocialUrls.join(' ')}
        }
        artistsCount
        venuesCount
        pastEventsCount
        createdAt
      }
    }
  }
`;

export const UPDATE_CITY = gql`
  mutation updateCity(
    $cityId: Int!
    $titles: [TitleByLocaleInput!]
    $countryId: Int
    $businessOwnerId: Int
    $latitude: Float
    $longitude: Float
    $cityStatus: String
    $cityOperatingModel: String
    $sofarOperatedMarketType: String
    $isAcceptingArtistRequests: Boolean
    $cachedSlug: String
    $aliases: String
    $socialUrls: SocialUrlsInput
    $inviteEmailMessage: String
    $revealAddressEmailMessage: String
    $isAutomatedEmailEnabled: Boolean
    $artistApplicationNotificationEmails: [String!]
    $isArtistApplicationNotificationEnabled: Boolean
    $useFromEmailForAllEmails: Boolean
    $fromEmail: String
    $artistOpsId: Int
    $crewOpsId: Int
    $venueOpsId: Int
    $tieredPrice: CityTieredPriceInput
    $defaultEventPrice: Int
    $maxTicketsPerAttendee: Int
    $isAutoInviteEnabled: Boolean
    $inviteFirstDayPercentage: Int
    $inviteSubsequentDaysPercentage: Int
    $isAutoStopApplyEnabled: Boolean
    $maximumPromoCodeUses: Int
    $maximumGuaranteedPromoCodeUses: Int
    $maximumCustomPromoCodeUses: Int
    $maximumPercentagePromoCodeUses: Int
    $sendPromoCodesToFirstTimers: Boolean
    $isUnluckyEmailPromoCodeEnabled: Boolean
    $topGlobalCity: Boolean
    $topCity: Boolean
    $noindex: Boolean
    $bookingFee: Int
    $displayBookingFee: Boolean
    $isTieredArtistCompensationEnabled: Boolean
    $applyMethod: String
    $selectedValues: [String!]
    $nearbySecondaryCitiesIds: [Int!]
    $description: String
    $whatToExpect: String
  ) {
    updateCity(
      cityId: $cityId
      titles: $titles
      countryId: $countryId
      businessOwnerId: $businessOwnerId
      latitude: $latitude
      longitude: $longitude
      cityStatus: $cityStatus
      cityOperatingModel: $cityOperatingModel
      sofarOperatedMarketType: $sofarOperatedMarketType
      isAcceptingArtistRequests: $isAcceptingArtistRequests
      cachedSlug: $cachedSlug
      aliases: $aliases
      socialUrls: $socialUrls
      inviteEmailMessage: $inviteEmailMessage
      revealAddressEmailMessage: $revealAddressEmailMessage
      isAutomatedEmailEnabled: $isAutomatedEmailEnabled
      artistApplicationNotificationEmails: $artistApplicationNotificationEmails
      isArtistApplicationNotificationEnabled: $isArtistApplicationNotificationEnabled
      useFromEmailForAllEmails: $useFromEmailForAllEmails
      fromEmail: $fromEmail
      artistOpsId: $artistOpsId
      crewOpsId: $crewOpsId
      venueOpsId: $venueOpsId
      tieredPrice: $tieredPrice
      defaultEventPrice: $defaultEventPrice
      maxTicketsPerAttendee: $maxTicketsPerAttendee
      isAutoInviteEnabled: $isAutoInviteEnabled
      inviteFirstDayPercentage: $inviteFirstDayPercentage
      inviteSubsequentDaysPercentage: $inviteSubsequentDaysPercentage
      isAutoStopApplyEnabled: $isAutoStopApplyEnabled
      maximumPromoCodeUses: $maximumPromoCodeUses
      maximumGuaranteedPromoCodeUses: $maximumGuaranteedPromoCodeUses
      maximumCustomPromoCodeUses: $maximumCustomPromoCodeUses
      maximumPercentagePromoCodeUses: $maximumPercentagePromoCodeUses
      sendPromoCodesToFirstTimers: $sendPromoCodesToFirstTimers
      isUnluckyEmailPromoCodeEnabled: $isUnluckyEmailPromoCodeEnabled
      topGlobalCity: $topGlobalCity
      topCity: $topCity
      noindex: $noindex
      bookingFee: $bookingFee
      displayBookingFee: $displayBookingFee
      isTieredArtistCompensationEnabled: $isTieredArtistCompensationEnabled
      applyMethod: $applyMethod
      selectedValues: $selectedValues
      nearbySecondaryCitiesIds: $nearbySecondaryCitiesIds
      description: $description
      whatToExpect: $whatToExpect
    ) {
      success
      city {
        id
        title
        titlesByLocale {
          locale
          title
        }
        cachedSlug
        country {
          id
          title
        }
        businessOwner {
          id
          title
        }
        neighborhoods {
          id
          title
        }
        latitude
        longitude
        timezone
        aliases
        cityStatus
        cityOperatingModel
        sofarOperatedMarketType
        isAcceptingArtistRequests
        socialUrls {
          ${citySocialUrls.join(' ')}
        }
        artistsCount
        venuesCount
        pastEventsCount
        createdAt
        bookingFee
        isTieredArtistCompensationEnabled
        nearbySecondaryCities {
          id
          title
        }
      }
    }
  }
`;
