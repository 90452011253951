import React from 'react';

import { MenuData, ParentMenu } from 'app/typings/navbar';
import { useCurrentTheme } from 'app/shared/theme';
import { useNavbarContext } from 'app/shared/context';
import { Box, ListItem } from 'app/shared/components/atoms';
import { NavigationLink as Link } from 'app/shared/components/atoms';
import { HoverDropdown } from 'app/shared/components/molecules';

import NavbarAuthMenuItem from './NavbarAuthMenuItem';
import { RenderLink } from './RenderLink';

interface ParentMenuItemProps {
  parentMenuItem: ParentMenu;
  index: number;
  totalParentMenuItems: number;
  'data-qaid': string;
  invertColors?: boolean;
}

export const ParentMenuItem: React.FC<ParentMenuItemProps> = ({
  parentMenuItem,
  index,
  totalParentMenuItems,
  'data-qaid': qaId,
  invertColors = false,
}: ParentMenuItemProps) => {
  const { isMobileMenuOpen } = useNavbarContext();
  const { colors } = useCurrentTheme();

  const {
    linkStyle,
    childMenu,
    title,
    key,
    openInNewTab,
    link,
    onClick,
    parentItemType,
  } = parentMenuItem;

  if (linkStyle == 'alwaysOnLink' && isMobileMenuOpen) {
    return null;
  }
  const isActive =
    typeof window !== 'undefined' && window.location.pathname === link;

  if (parentItemType === 'auth') {
    return <NavbarAuthMenuItem />;
  }

  if (childMenu && childMenu.length > 1) {
    const isActive =
      typeof window !== 'undefined' &&
      window.location.pathname &&
      childMenu
        ?.map((childItem: any) => {
          return childItem.link;
        })
        .includes(window.location.pathname)
        ? true
        : false;

    const isLastChild = index == totalParentMenuItems - 1;
    return (
      <Box flexDirection="column">
        <HoverDropdown
          offset={{ horizontal: isLastChild ? 16 : -16 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: isLastChild ? 'right' : 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: isLastChild ? 'right' : 'left',
          }}
          keepInViewPort={true}
          key={index}
          isLastChild={isLastChild}
          data-qaid={`${qaId}-${index}`}
          renderLabel={(isOpen: boolean) => (
            <RenderLink
              title={title}
              dataQaid={key}
              isActive={isActive || isOpen}
              openInNewTab={openInNewTab}
              invertColors={invertColors}
            />
          )}
        >
          <Box
            backgroundColor={colors.primary.black.base}
            as="ul"
            px={0}
            bt={colors.accent.primaryAccent.base}
            bw="2px"
          >
            {childMenu.map((opt: MenuData, childMenuIndex: number) => {
              if (opt.display == false) {
                return null;
              }

              const target = opt.openInNewTab ? '_blank' : '_self';
              const rel = opt.openInNewTab ? 'noopener' : undefined;

              return (
                <Link
                  key={opt.key}
                  data-qaid={opt.key}
                  href={opt.link ? opt.link : '#'}
                  onClick={opt.onClick}
                  target={target}
                  rel={rel}
                  textDecoration="none"
                  variant="tertiary"
                  lineHeight="100%"
                  w="-webkit-fill-available"
                >
                  <ListItem
                    key={`${opt.key}=${childMenuIndex}`}
                    px={4}
                    py={2}
                    invertColors={true}
                    isLastChild={childMenuIndex == childMenu.length - 1}
                  >
                    {opt.title}
                  </ListItem>
                </Link>
              );
            })}
          </Box>
        </HoverDropdown>
      </Box>
    );
  } else if (childMenu && childMenu.length === 1) {
    return (
      <RenderLink
        href={childMenu[0].link}
        onClick={childMenu[0].onClick}
        openInNewTab={childMenu[0].openInNewTab}
        data-qaid={key}
        key={index}
        title={title}
        invertColors={invertColors}
        isActive={isActive}
      />
    );
  } else {
    const isActive =
      typeof window !== 'undefined' && window.location.pathname === link;
    return (
      <RenderLink
        href={link}
        onClick={onClick}
        data-qaid={key}
        key={index}
        title={title}
        invertColors={invertColors}
        isActive={isActive}
      />
    );
  }
};
