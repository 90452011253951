import gql from 'graphql-tag';

export const ARCHIVE_VENUE_APPLICATION = gql`
  mutation archiveVenueApplication($id: Int!) {
    archiveVenueApplication(id: $id) {
      success
      venueApplication {
        id
      }
    }
  }
`;

export const CREATE_VENUE_FROM_VENUE_APPLICATION = gql`
  mutation createVenueFromVenueApplication($id: Int!) {
    createVenueFromVenueApplication(id: $id) {
      success
      venue {
        id
      }
    }
  }
`;
