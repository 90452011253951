import { useQuery } from 'react-apollo';

import { GetThemesData, GetThemesVariables } from 'app/typings/themes';
import { queryParams } from 'app/shared/utils/queries';
import { GET_THEMES, GET_THEMES_LITE } from 'app/admin/graphql/themes/queries';

const liteQueryParams = (params: any) =>
  Object.assign(params, { isLiteQuery: true });

export const GetThemes = (params: GetThemesVariables = {}) => {
  return useQuery<GetThemesData>(GET_THEMES, queryParams(params));
};

export const GetThemesLite = (params: GetThemesVariables = {}) => {
  return useQuery<GetThemesData>(
    GET_THEMES_LITE,
    queryParams(liteQueryParams(params))
  );
};
