import { useMutation } from 'react-apollo';

import {
  CreateArtistFeedbackData,
  CreateEventFeedbackData,
  DeleteFeedbackData,
} from 'app/typings/feedbacks';
import {
  CREATE_ARTIST_FEEDBACK,
  CREATE_EVENT_FEEDBACK,
  DELETE_FEEDBACK,
} from 'app/admin/graphql/feedbacks/mutations';

export const CreateArtistFeedback = () => {
  return useMutation<CreateArtistFeedbackData>(CREATE_ARTIST_FEEDBACK)[0];
};

export const CreateEventFeedback = () => {
  return useMutation<CreateEventFeedbackData>(CREATE_EVENT_FEEDBACK)[0];
};

export const DeleteFeedback = () => {
  return useMutation<DeleteFeedbackData>(DELETE_FEEDBACK)[0];
};
