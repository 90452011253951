import { EventAttendee } from 'app/typings/eventAttendees';
import { VipAttendee } from 'app/typings/vipAttendees';

export const eventAttendeeStatuses = {
  applied: 'Applied',
  guestlisted: 'Invited',
  confirmed: 'Confirmed',
  cant_go: "Can't Go",
  expired: 'Expired',
};

export const eventAttendeeStatusAliases = {
  confirmed: ['confirmed', 'reserved'],
};

export const getStatus = (rawStatus: string) => {
  const statusFromAlias = Object.keys(
    eventAttendeeStatusAliases
  ).find((status: string) =>
    eventAttendeeStatusAliases[status].includes(rawStatus)
  );
  return statusFromAlias || rawStatus;
};

export const getDisplayStatus = (rawStatus: string) =>
  eventAttendeeStatuses[getStatus(rawStatus)];

export const eventAttendeeFullName = (
  eventAttendee?: EventAttendee,
  intl?: any
) =>
  eventAttendee && eventAttendee.fullName
    ? eventAttendee.fullName
    : intl.formatMessage({
        id: 'unknown',
      });

export const vipAttendeeFullName = (vipAttendee?: VipAttendee, intl?: any) =>
  vipAttendee && vipAttendee.fullName
    ? vipAttendee.fullName
    : intl.formatMessage({
        id: 'unknown',
      });
