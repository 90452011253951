import React, { useContext } from 'react';

import { useNavbarContext } from 'app/shared/context';
import { AuthContext } from 'app/shared/context/Auth';
import { ThemeWrapper } from 'app/shared/components/atoms';
import { AuthModal } from 'app/shared/components/organisms';

import { NavbarProps } from '.';

export const NavbarAuthModal: React.FC<NavbarProps> = ({ eventId, city }) => {
  const { signIn } = useContext(AuthContext);
  const {
    currentTabSelected,
    setCurrentTabSelected,
    isAuthModalOpen,
    setIsAuthModalOpen,
  } = useNavbarContext();

  const successfulSignIn = () => {
    setIsAuthModalOpen(false);
    signIn();
  };

  return (
    <ThemeWrapper themeName="common">
      <AuthModal
        data-qaid="navbar-auth-modal"
        portalRootId="navbar-portal-root"
        isOpen={isAuthModalOpen}
        setIsOpen={setIsAuthModalOpen}
        onSuccess={successfulSignIn}
        currentTabSelected={currentTabSelected}
        setCurrentTabSelected={setCurrentTabSelected}
        parentContainer="Navbar"
        eventId={eventId}
        city={city}
      />
    </ThemeWrapper>
  );
};
