import { useQuery } from 'react-apollo';

import {
  GetEventFormatExperimentsData,
  GetEventFormatExperimentsVariables,
} from 'app/typings/eventFormatExperiments';
import { queryParams } from 'app/shared/utils/queries';
import { GET_EVENT_FORMAT_EXPERIMENTS } from 'app/admin/graphql/eventFormatExperiments/queries';

export const GetEventFormatExperiments = (
  params: GetEventFormatExperimentsVariables = {}
) => {
  return useQuery<GetEventFormatExperimentsData>(
    GET_EVENT_FORMAT_EXPERIMENTS,
    queryParams(params)
  );
};
