import gql from 'graphql-tag';

export const CREATE_COMMENT = gql`
  mutation createComment(
    $commentableId: Int!
    $commentableType: String!
    $body: String!
  ) {
    createComment(
      commentableId: $commentableId
      commentableType: $commentableType
      body: $body
    ) {
      success
      comment {
        id
      }
      commentsCount
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation deleteComment($commentId: Int!) {
    deleteComment(commentId: $commentId) {
      success
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation updateComment($id: Int!, $body: String!) {
    updateComment(id: $id, body: $body) {
      success
      comment {
        id
        body
      }
    }
  }
`;
