import { useMutation } from 'react-apollo';

import { CreateCommentData } from 'app/typings/comments';
import { CREATE_COMMENT } from 'app/admin/graphql/comments/mutations';

export const CreateComment = () => {
  return useMutation<CreateCommentData>(CREATE_COMMENT)[0];
};

// export const DeleteComment = () => {
//   return useMutation<DeleteCommentData>(DELETE_COMMENT)[0];
// };

// export const UpdateComment = () => {
//   return useMutation<UpdateCommentData>(UPDATE_COMMENT)[0];
// };
