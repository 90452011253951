import gql from 'graphql-tag';

export const UPDATE_GLOBAL_SETTINGS = gql`
  mutation updateGlobalSettings(
    $globalSettingInputValues: [GlobalSettingInput!]!
  ) {
    updateGlobalSettings(globalSettingInputValues: $globalSettingInputValues) {
      success
      globalSettings {
        id
        key
        title
        value
      }
    }
  }
`;
