import { useMutation } from 'react-apollo';

import {
  ArchivePartnerData,
  CreatePartnerData,
  UpdatePartnerData,
} from 'app/typings/partners';
import {
  ARCHIVE_PARTNER,
  CREATE_PARTNER,
  UPDATE_PARTNER,
} from 'app/admin/graphql/partners/mutations';

export const ArchivePartner = () => {
  return useMutation<ArchivePartnerData>(ARCHIVE_PARTNER)[0];
};

export const CreatePartner = () => {
  return useMutation<CreatePartnerData>(CREATE_PARTNER)[0];
};

export const UpdatePartner = () => {
  return useMutation<UpdatePartnerData>(UPDATE_PARTNER)[0];
};
