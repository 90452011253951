import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { Box, JsonLD, Typography } from 'app/shared/components/atoms';
import { NavigationLink as Link } from 'app/shared/components/atoms';
import { BasePageBox } from 'app/shared/components/molecules';

export interface Breadcrumb {
  text: string;
  url?: string;
}

interface BreadcrumbsProps {
  breadcrumbLinks?: Breadcrumb[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbLinks,
}) => {
  const { colors } = useCurrentTheme();

  if (!breadcrumbLinks) {
    return null;
  }

  const breadcrumbs = breadcrumbLinks.map((crumb, index) => {
    const crumbDivider =
      index == breadcrumbLinks.length - 1 ? null : (
        <Typography
          as="span"
          fontSize="fontSize75"
          color={colors.primary.white.base}
          m={0}
          py={0}
          px={2}
          data-qaid="breadcrumb-separator"
          lineHeight="100%"
          aria-hidden
        >
          /
        </Typography>
      );

    return (
      <Box as="li" display="contents" key={index}>
        {crumb.url ? (
          <Link
            href={crumb.url}
            fontSize="fontSize75"
            data-qaid={`breadcrumb-item-${index}`}
            variant="tertiary"
            // TODO: remove textDecoration prop when this is no longer used on manualCSS pages
            textDecoration="underline !important"
            lineHeight="100%"
          >
            {crumb.text}
          </Link>
        ) : (
          <Typography
            as="span"
            fontSize="fontSize75"
            data-qaid={`breadcrumb-item-${index}`}
            color={colors.primary.white.base}
            lineHeight="100%"
          >
            {crumb.text}
          </Typography>
        )}
        {crumbDivider}
      </Box>
    );
  });

  const breadcrumbsJson = breadcrumbLinks.map((breadcrumb: Breadcrumb, i) => {
    if (breadcrumb.url) {
      return {
        '@type': 'ListItem',
        position: i + 1,
        name: breadcrumb.text,
        item: `https://www.sofarsounds.com${breadcrumb.url}`,
      };
    } else {
      return {
        '@type': 'ListItem',
        position: i + 1,
        name: breadcrumb.text,
      };
    }
  });

  return (
    <nav>
      <BasePageBox
        bb={colors.accent.primaryAccent.base}
        backgroundColor={colors.primary.black.base}
        py={4}
        alignItems="center"
        justifyContent="flex-start"
        data-qaid="breadcrumbs"
        aria-label="Breadcrumb"
        as="ol"
      >
        {breadcrumbs}
      </BasePageBox>
      <JsonLD
        key="breadcrumbs-json"
        data-qaid="breadcrumbs-snippet"
        data={{
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: breadcrumbsJson,
        }}
      />
    </nav>
  );
};
