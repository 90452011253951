import gql from 'graphql-tag';

export const CREATE_COUNTRY = gql`
  mutation createCountry(
    $titles: [TitleByLocaleInput!]
    $continentId: Int
    $countryCode: String
    $aliases: String
    $currency: String
    $currencySymbol: String
    $exchangeRateWithUsd: Float
  ) {
    createCountry(
      titles: $titles
      continentId: $continentId
      countryCode: $countryCode
      aliases: $aliases
      currency: $currency
      currencySymbol: $currencySymbol
      exchangeRateWithUsd: $exchangeRateWithUsd
    ) {
      success
      country {
        id
        title
        titlesByLocale {
          locale
          title
        }
        continent {
          id
          title
        }
        countryCode
        citiesCount
        cachedSlug
        aliases
        currency
        currencySymbol
        bookingFee
        isTieredArtistCompensationEnabled
        artistCompensation
        artistCompensationTier1
        artistCompensationTier2
        artistCompensationTier3
        artistCompensationTier4
        artistCompensationTier5
        exchangeRateWithUsd
        artistsCount
        venuesCount
        pastEventsCount
      }
    }
  }
`;

export const DELETE_COUNTRY = gql`
  mutation deleteCountry($countryId: Int!) {
    deleteCountry(countryId: $countryId) {
      success
    }
  }
`;

export const UPDATE_COUNTRY = gql`
  mutation updateCountry(
    $countryId: Int!
    $titles: [TitleByLocaleInput!]
    $continentId: Int
    $aliases: String
    $currency: String
    $currencySymbol: String
    $bookingFee: Int
    $displayBookingFee: Boolean
    $isTieredArtistCompensationEnabled: Boolean
    $artistCompensation: Int
    $artistCompensationTier1: Int
    $artistCompensationTier2: Int
    $artistCompensationTier3: Int
    $artistCompensationTier4: Int
    $artistCompensationTier5: Int
    $exchangeRateWithUsd: Float
  ) {
    updateCountry(
      countryId: $countryId
      titles: $titles
      continentId: $continentId
      aliases: $aliases
      currency: $currency
      currencySymbol: $currencySymbol
      bookingFee: $bookingFee
      displayBookingFee: $displayBookingFee
      isTieredArtistCompensationEnabled: $isTieredArtistCompensationEnabled
      artistCompensation: $artistCompensation
      artistCompensationTier1: $artistCompensationTier1
      artistCompensationTier2: $artistCompensationTier2
      artistCompensationTier3: $artistCompensationTier3
      artistCompensationTier4: $artistCompensationTier4
      artistCompensationTier5: $artistCompensationTier5
      exchangeRateWithUsd: $exchangeRateWithUsd
    ) {
      success
      country {
        id
        title
        titlesByLocale {
          locale
          title
        }
        continent {
          id
          title
        }
        countryCode
        citiesCount
        cachedSlug
        aliases
        currency
        currencySymbol
        bookingFee
        displayBookingFee
        isTieredArtistCompensationEnabled
        artistCompensation
        artistCompensationTier1
        artistCompensationTier2
        artistCompensationTier3
        artistCompensationTier4
        artistCompensationTier5
        exchangeRateWithUsd
        artistsCount
        venuesCount
        pastEventsCount
      }
    }
  }
`;
