import { useContext } from 'react';

import { get } from 'app/shared/utils/get';
import { PermissionsContext } from 'app/shared/context/Permissions';

const cityIdsWithPermission = (permissions: any[], permissionName: string) =>
  permissions
    .filter(
      (p: any) =>
        p.name === permissionName &&
        (p.resourceType === 'City' || p.associatedObjectResourceType === 'City')
    )
    .map((p: any) => {
      if (p.associatedObjectResourceType === 'City') {
        return Number(p.associatedObjectResourceId);
      } else {
        return Number(p.resourceId);
      }
    });

const fieldsForCities = (
  dataCities: any,
  cityIds: number[],
  fieldName: string
) => {
  if (cityIds) {
    let cities =
      get(dataCities, 'cities.cities', undefined) ||
      get(dataCities, 'cities', []);
    return cities
      .filter((city: any) => cityIds.includes(city.id))
      .map((city: any) => get(city, fieldName, undefined));
  }
  return [];
};

const CityPermissionInfo = (permissionName: string) => {
  const permissionsContext = useContext(PermissionsContext);
  const hasByCityPermission = permissionsContext.hasPermission(permissionName);

  let cityIdsToLimitBy: number[] | undefined = undefined;

  if (
    hasByCityPermission &&
    permissionsContext &&
    permissionsContext.permissions
  ) {
    cityIdsToLimitBy = cityIdsWithPermission(
      permissionsContext && permissionsContext.permissions,
      permissionName
    );
  }

  return {
    hasByCityPermission,
    cityIdsToLimitBy,
  };
};

export const CityInfoForLimitingByCity = (
  permissionName: string,
  dataCities?: any
) => {
  const { hasByCityPermission, cityIdsToLimitBy } = CityPermissionInfo(
    permissionName
  );

  let cityTitlesToLimitBy: string[] | undefined = undefined;
  let countryCodesToLimitBy: string[] | undefined = undefined;

  if (dataCities && cityIdsToLimitBy && cityIdsToLimitBy.length > 0) {
    cityTitlesToLimitBy = fieldsForCities(
      dataCities,
      cityIdsToLimitBy,
      'title'
    );
    countryCodesToLimitBy = [
      ...new Set<string>(
        fieldsForCities(
          dataCities,
          cityIdsToLimitBy,
          'country.countryCode'
        ).filter((code: string | undefined) => code !== undefined)
      ),
    ];
  }

  return {
    hasByCityPermission,
    cityIdsToLimitBy,
    cityTitlesToLimitBy,
    countryCodesToLimitBy,
  };
};

export const HasPermissionForCity = (
  cityId: number | undefined,
  permissionName: string
) => {
  const permissionsContext = useContext(PermissionsContext);
  const hasByCityPermission = permissionsContext.hasPermission(permissionName);

  let canAccess = true;

  if (
    hasByCityPermission &&
    permissionsContext &&
    permissionsContext.permissions &&
    cityId
  ) {
    const cityIdsToLimitBy = cityIdsWithPermission(
      permissionsContext && permissionsContext.permissions,
      permissionName
    );
    canAccess = cityIdsToLimitBy.includes(cityId);
  }

  return canAccess;
};
