import { validDomainsForFromEmail } from 'app/admin/utils/events';

export const validateInvalidDomainForFromEmail = (email: string) => {
  return validDomainsForFromEmail.some((validDomain: string) => {
    const reg = new RegExp(`.+@${validDomain}$`);
    return !!email.match(reg);
  });
};

export const emailMessageStatusLabels = {
  bounced: 'Bounced',
  deferred: 'Deferred',
  invalid: 'Invalid',
  rejected: 'Rejected',
  sent: 'Sent',
  'soft-bounced': 'Soft Bounced',
};
