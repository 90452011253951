import gql from 'graphql-tag';

export const ARCHIVE_CREW_MESSAGE = gql`
  mutation archiveCrewMessage($id: Int!) {
    archiveCrewMessage(id: $id) {
      success
      crewMessage {
        id
        body
        createdAt
        createdBy {
          id
          firstName
          lastName
          email
        }
        isArchived
      }
    }
  }
`;

export const CREATE_CREW_MESSAGE = gql`
  mutation createCrewMessage($body: String!, $eventId: Int!) {
    createCrewMessage(body: $body, eventId: $eventId) {
      success
      crewMessage {
        id
        body
        createdAt
        createdBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const UPDATE_STAFF_MEMBER = gql`
  mutation updateStaffMember(
    $staffMemberId: Int!
    $staffMemberAvailabilityDates: [String!]
  ) {
    updateStaffMember(
      staffMemberId: $staffMemberId
      staffMemberAvailabilityDates: $staffMemberAvailabilityDates
    ) {
      success
      staffMember {
        id
        staffMemberAvailabilityDates {
          date
          isAvailable
        }
      }
    }
  }
`;
