import { useQuery } from 'react-apollo';

import {
  GetInternalUserBasicInfoData,
  GetInternalUserBasicInfoVariables,
  GetUserBasicInfoData,
  GetUserBasicInfoVariables,
  GetUsersData,
  GetUsersForAddStaffToEventVariables,
  GetUsersForCrewDirectoryData,
  GetUsersForCrewDirectoryVariables,
  GetUsersInternalVariables,
  GetUsersVariables,
} from 'app/typings/users';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_INTERNAL_USER_BASIC_INFO,
  GET_USER_BASIC_INFO,
  GET_USER_FOR_CREW_DIRECTORY,
  GET_USER_STAFFED_EVENTS_PAST,
  GET_USER_STAFFED_EVENTS_UPCOMING,
  GET_USERS,
  GET_USERS_FOR_ADD_STAFF_TO_EVENT,
  GET_USERS_FOR_CREW_DIRECTORY,
  GET_USERS_FOR_FROM_EMAIL_SETTINGS_EDIT,
  GET_USERS_FOR_INTERNAL_USER_DIRECTORY,
  GET_USERS_FOR_USER_TYPEAHEAD,
} from 'app/admin/graphql/users/queries';

const liteQueryParams = (params: any) =>
  Object.assign(params, { isLiteQuery: true });

export const GetInternalUserBasicInfo = (
  params: GetInternalUserBasicInfoVariables = {}
) => {
  return useQuery<GetInternalUserBasicInfoData>(
    GET_INTERNAL_USER_BASIC_INFO,
    queryParams(params)
  );
};

export const GetUserBasicInfo = (params: GetUserBasicInfoVariables = {}) => {
  return useQuery<GetUserBasicInfoData>(
    GET_USER_BASIC_INFO,
    queryParams(params)
  );
};

export const GetUserForCrewDirectory = (
  params: GetUserBasicInfoVariables = {}
) => {
  return useQuery<GetUserBasicInfoData>(
    GET_USER_FOR_CREW_DIRECTORY,
    queryParams(params)
  );
};

export const GetUserStaffedEventsPast = (
  params: GetUserBasicInfoVariables = {}
) => {
  return useQuery<GetUserBasicInfoData>(
    GET_USER_STAFFED_EVENTS_PAST,
    queryParams(params)
  );
};

export const GetUserStaffedEventsUpcoming = (
  params: GetUserBasicInfoVariables = {}
) => {
  return useQuery<GetUserBasicInfoData>(
    GET_USER_STAFFED_EVENTS_UPCOMING,
    queryParams(params)
  );
};

export const GetUsers = (params: GetUsersVariables = {}) => {
  return useQuery<GetUsersData>(GET_USERS, queryParams(params));
};

export const GetUsersForAddStaffToEvent = (
  params: GetUsersForAddStaffToEventVariables = {}
) => {
  return useQuery<GetUsersData>(
    GET_USERS_FOR_ADD_STAFF_TO_EVENT,
    queryParams(liteQueryParams(params))
  );
};

export const GetUsersForCrewDirectory = (
  params: GetUsersForCrewDirectoryVariables = {}
) => {
  return useQuery<GetUsersForCrewDirectoryData>(
    GET_USERS_FOR_CREW_DIRECTORY,
    queryParams(params)
  );
};

export const GetUsersForFromEmailSettingsEdit = (
  params: GetUsersInternalVariables = {}
) => {
  return useQuery<GetUsersData>(
    GET_USERS_FOR_FROM_EMAIL_SETTINGS_EDIT,
    queryParams(liteQueryParams(params))
  );
};

export const GetUsersForInternalUserDirectory = (
  params: GetUsersInternalVariables = {}
) => {
  return useQuery<GetUsersData>(
    GET_USERS_FOR_INTERNAL_USER_DIRECTORY,
    queryParams(params)
  );
};

export const GetUsersForUserTypeahead = (params: GetUsersVariables = {}) => {
  return useQuery<GetUsersData>(
    GET_USERS_FOR_USER_TYPEAHEAD,
    queryParams(liteQueryParams(params))
  );
};
