import usePermission from 'app/shared/utils/usePermission';

export enum CrewRoleStatus {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  NOT_APPLICABLE = 'notApplicable',
  FILLED = 'filled',
  FILLED_BY_CURRENT_USER = 'filledByCurrentUser',
}

export const eventStaffConfig = {
  crew_lead: {
    labelKey: 'lead',
    labelShortKey: 'leadShort',
    addByOtherPermissionKey: 'addByOtherCrewLead',
    addBySelfPermissionKey: 'addBySelfCrewLead',
    availablePermissionKey: 'availableCrewLead',
    curatorPermissionKey: 'curatorCrewLead',
    position: 1,
  },
  crew_emcee: {
    labelKey: 'mc',
    labelShortKey: 'mcShort',
    addByOtherPermissionKey: 'addByOtherCrewEmcee',
    addBySelfPermissionKey: 'addBySelfCrewEmcee',
    availablePermissionKey: 'availableCrewEmcee',
    curatorPermissionKey: 'curatorCrewEmcee',
    position: 2,
  },
  day_of_show_support_1: {
    labelKey: 'support',
    labelShortKey: 'supportShort',
    addByOtherPermissionKey: 'addByOtherSupport',
    addBySelfPermissionKey: 'addBySelfSupport',
    availablePermissionKey: 'availableSupport',
    curatorPermissionKey: 'curatorSupport',
    position: 3,
  },
  day_of_show_support_2: {
    labelKey: 'support',
    labelShortKey: 'supportShort',
    addByOtherPermissionKey: 'addByOtherSupport',
    addBySelfPermissionKey: 'addBySelfSupport',
    availablePermissionKey: 'availableSupport',
    curatorPermissionKey: 'curatorSupport',
    position: 4,
  },
  day_of_show_support_3: {
    labelKey: 'support',
    labelShortKey: 'supportShort',
    addByOtherPermissionKey: 'addByOtherSupport',
    addBySelfPermissionKey: 'addBySelfSupport',
    availablePermissionKey: 'availableSupport',
    curatorPermissionKey: 'curatorSupport',
    position: 5,
  },
  day_of_show_support_4: {
    labelKey: 'support',
    labelShortKey: 'supportShort',
    addByOtherPermissionKey: 'addByOtherSupport',
    addBySelfPermissionKey: 'addBySelfSupport',
    availablePermissionKey: 'availableSupport',
    curatorPermissionKey: 'curatorSupport',
    position: 6,
  },
  audio: {
    labelKey: 'audio',
    labelShortKey: 'audioShort',
    addByOtherPermissionKey: 'addByOtherAudioEngineer',
    addBySelfPermissionKey: 'addBySelfAudioEngineer',
    availablePermissionKey: 'availableAudioEngineer',
    curatorPermissionKey: 'curatorAudioEngineer',
    position: 7,
  },
  gear_runner: {
    labelKey: 'gearRunner',
    labelShortKey: 'gearRunnerShort',
    addByOtherPermissionKey: 'addByOtherGearRunner',
    addBySelfPermissionKey: 'addBySelfGearRunner',
    availablePermissionKey: 'availableGearRunner',
    curatorPermissionKey: 'curatorGearRunner',
    position: 8,
  },
  video_1: {
    labelKey: 'video',
    labelShortKey: 'videoShort',
    addByOtherPermissionKey: 'addByOtherVideographer',
    addBySelfPermissionKey: 'addBySelfVideographer',
    availablePermissionKey: 'availableVideographer',
    curatorPermissionKey: 'curatorVideographer',
    position: 9,
  },
  video_2: {
    labelKey: 'video',
    labelShortKey: 'videoShort',
    addByOtherPermissionKey: 'addByOtherVideographer',
    addBySelfPermissionKey: 'addBySelfVideographer',
    availablePermissionKey: 'availableVideographer',
    curatorPermissionKey: 'curatorVideographer',
    position: 10,
  },
  video_3: {
    labelKey: 'video',
    labelShortKey: 'videoShort',
    addByOtherPermissionKey: 'addByOtherVideographer',
    addBySelfPermissionKey: 'addBySelfVideographer',
    availablePermissionKey: 'availableVideographer',
    curatorPermissionKey: 'curatorVideographer',
    position: 11,
  },
  photo: {
    labelKey: 'photo',
    labelShortKey: 'photoShort',
    addByOtherPermissionKey: 'addByOtherPhotographer',
    addBySelfPermissionKey: 'addBySelfPhotographer',
    availablePermissionKey: 'availablePhotographer',
    curatorPermissionKey: 'curatorPhotographer',
    position: 12,
  },
  booker: {
    labelKey: 'booker',
    labelShortKey: 'bookerShort',
    addByOtherPermissionKey: 'booker',
    curatorPermissionKey: 'curatorBooker',
    position: 13,
  },
  moderator: {
    labelKey: 'moderator',
    labelShortKey: 'moderatorShort',
    addByOtherPermissionKey: 'moderator',
    curatorPermissionKey: 'curatorModerator',
    position: 14,
  },
  producer: {
    labelKey: 'producer',
    labelShortKey: 'producerShort',
    addByOtherPermissionKey: 'producer',
    curatorPermissionKey: 'curatorProducer',
    position: 15,
  },
};

export const getEventStaffAddByOtherPermission = (roleKey: string) => {
  if (
    eventStaffConfig[roleKey] &&
    eventStaffConfig[roleKey].addByOtherPermissionKey
  ) {
    return `role.${eventStaffConfig[roleKey].addByOtherPermissionKey}.fill`;
  }
  return;
};

export const getEventStaffAddBySelfPermission = (roleKey: string) => {
  if (
    eventStaffConfig[roleKey] &&
    eventStaffConfig[roleKey].addBySelfPermissionKey
  ) {
    return `role.${eventStaffConfig[roleKey].addBySelfPermissionKey}.fill`;
  }
  return;
};

export const getEventStaffAvailablePermission = (roleKey: string) => {
  if (
    eventStaffConfig[roleKey] &&
    eventStaffConfig[roleKey].availablePermissionKey
  ) {
    return `role.${eventStaffConfig[roleKey].availablePermissionKey}.fill`;
  }
  return;
};

export const getEventStaffCuratorPermission = (roleKey: string) => {
  if (
    eventStaffConfig[roleKey] &&
    eventStaffConfig[roleKey].curatorPermissionKey
  ) {
    return `role.${eventStaffConfig[roleKey].curatorPermissionKey}.fill`;
  }
  return;
};

export const useEventStaffAddBySelfPermissions = () => {
  return {
    crew_lead: usePermission(
      getEventStaffAddBySelfPermission('crew_lead') || ''
    ),
    crew_emcee: usePermission(
      getEventStaffAddBySelfPermission('crew_emcee') || ''
    ),
    day_of_show_support_1: usePermission(
      getEventStaffAddBySelfPermission('day_of_show_support_1') || ''
    ),
    day_of_show_support_2: usePermission(
      getEventStaffAddBySelfPermission('day_of_show_support_2') || ''
    ),
    day_of_show_support_3: usePermission(
      getEventStaffAddBySelfPermission('day_of_show_support_3') || ''
    ),
    day_of_show_support_4: usePermission(
      getEventStaffAddBySelfPermission('day_of_show_support_4') || ''
    ),
    audio: usePermission(getEventStaffAddBySelfPermission('audio') || ''),
    gear_runner: usePermission(
      getEventStaffAddBySelfPermission('gear_runner') || ''
    ),
    video_1: usePermission(getEventStaffAddBySelfPermission('video_1') || ''),
    video_2: usePermission(getEventStaffAddBySelfPermission('video_2') || ''),
    video_3: usePermission(getEventStaffAddBySelfPermission('video_3') || ''),
    photo: usePermission(getEventStaffAddBySelfPermission('photo') || ''),
  };
};
