import gql from 'graphql-tag';

export const REFUND_EVENT_ATTENDEE = gql`
  mutation refundEventAttendee(
    $eventAttendeeId: Int!
    $numTicketsToRemove: Int!
    $refundAmount: Int!
  ) {
    refundEventAttendee(
      eventAttendeeId: $eventAttendeeId
      numTicketsToRemove: $numTicketsToRemove
      refundAmount: $refundAmount
    ) {
      success
      eventAttendee {
        id
        currentState
        fullName
        email
        ticketsCount
        ticketsAttendedCount
        totalTicketCost
        homeCityTitle
        generalNotes
        isTransferred
        appliedPromoCodeCode
        createdAt
        userLastEventAt
        userPastEventsCount
        order {
          id
          charges {
            id
            uid
          }
        }
      }
    }
  }
`;

export const TRANSFER_EVENT_ATTENDEE = gql`
  mutation transferEventAttendee($eventAttendeeId: Int!, $newEventId: Int!) {
    transferEventAttendee(
      eventAttendeeId: $eventAttendeeId
      newEventId: $newEventId
    ) {
      success
      eventAttendee {
        id
        currentState
        fullName
        email
        ticketsCount
        ticketsAttendedCount
        totalTicketCost
        homeCityTitle
        generalNotes
        isTransferred
        appliedPromoCodeCode
        createdAt
        userLastEventAt
        userPastEventsCount
      }
    }
  }
`;

export const UPDATE_EVENT_ATTENDEE_ATTENDANCE = gql`
  mutation updateEventAttendeeAttendance(
    $eventAttendeeId: Int!
    $ticketIdsAttended: [Int!]
  ) {
    updateEventAttendeeAttendance(
      eventAttendeeId: $eventAttendeeId
      ticketIdsAttended: $ticketIdsAttended
    ) {
      success
      eventAttendee {
        id
        currentState
        fullName
        email
        ticketsCount
        ticketsAttendedCount
        totalTicketCost
        homeCityTitle
        generalNotes
        isTransferred
        appliedPromoCodeCode
        createdAt
        userLastEventAt
        userPastEventsCount
        tickets {
          id
          email
          firstName
          lastName
          primary
          hasAttended
        }
      }
    }
  }
`;

export const UPDATE_EVENT_ATTENDEE_STATE = gql`
  mutation updateEventAttendeeState($eventAttendeeId: Int!, $state: String!) {
    updateEventAttendeeState(eventAttendeeId: $eventAttendeeId, state: $state) {
      success
      eventAttendee {
        id
        currentState
        fullName
        email
        ticketsCount
        ticketsAttendedCount
        totalTicketCost
        homeCityTitle
        generalNotes
        isTransferred
        appliedPromoCodeCode
        createdAt
        userLastEventAt
        userPastEventsCount
      }
    }
  }
`;

export const UPDATE_EVENT_ATTENDEE_TICKETS = gql`
  mutation updateEventAttendeeTickets(
    $eventAttendeeId: Int!
    $numTickets: Int!
  ) {
    updateEventAttendeeTickets(
      eventAttendeeId: $eventAttendeeId
      numTickets: $numTickets
    ) {
      success
      eventAttendee {
        id
        currentState
        fullName
        email
        ticketsCount
        ticketsAttendedCount
        totalTicketCost
        homeCityTitle
        generalNotes
        isTransferred
        appliedPromoCodeCode
        createdAt
        userLastEventAt
        userPastEventsCount
      }
    }
  }
`;
