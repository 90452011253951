import gql from 'graphql-tag';

export const GET_USER_ABILITIES = gql`
  query GetUserAbilities {
    userAbilities {
      abilities {
        key
        name
        description
        resourceType
        contextName
      }
      abilityContexts {
        key
        name
      }
    }
  }
`;
