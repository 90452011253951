import React from 'react';

import { useAnalyticsContext } from 'app/shared/utils';
import { Typography } from 'app/shared/components/atoms';
import ThemeWrapper from 'app/shared/components/atoms/ThemeWrapper';
import SEO from 'app/shared/components/molecules/SEO';
import Layout from 'app/shared/layouts/Main';

import GenericErrorScreen from './GenericErrorScreen';

const NotFoundContent: React.FC = () => {
  return (
    <ThemeWrapper themeName="common">
      <GenericErrorScreen>
        <Typography textAlign="center">
          We couldn&apos;t find the page you were looking for!
        </Typography>
      </GenericErrorScreen>
    </ThemeWrapper>
  );
};

export const NotFound: React.FC<{
  noLayout?: boolean;
}> = ({ noLayout = false }) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();

  trackAnalyticsEvent('Oh No Page Viewed', {
    title: '404 page',
    url: window.location.href,
  });

  return (
    <ThemeWrapper themeName="manualCSS">
      {noLayout ? (
        <div data-qaid="404-page">
          <NotFoundContent />
        </div>
      ) : (
        <Layout data-qaid="404-page">
          <SEO title="Page not found" noIndex />
          <NotFoundContent />
        </Layout>
      )}
    </ThemeWrapper>
  );
};
