import { useMutation } from 'react-apollo';

import {
  AcceptBookingRequestData,
  DeclineBookingRequestData,
} from 'app/typings/bookingRequests';
import {
  ACCEPT_BOOKING_REQUEST,
  DECLINE_BOOKING_REQUEST,
} from 'app/admin/graphql/bookingRequests/mutations';

export const AcceptBookingRequest = () => {
  return useMutation<AcceptBookingRequestData>(ACCEPT_BOOKING_REQUEST)[0];
};

export const DeclineBookingRequest = () => {
  return useMutation<DeclineBookingRequestData>(DECLINE_BOOKING_REQUEST)[0];
};
