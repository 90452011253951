import { useCallback, useState } from 'react';

import { City, CityForClp, Event } from 'app/typings';
import {
  EventForCityPage,
  EventForEventCollection,
  EventForEventGrid,
  EventForFilmstripSection,
  OnGetEventsQueryCompletedType,
} from 'app/typings';
import { EventForEventPage } from 'app/typings';
import { useVisaAuth } from 'app/shared/utils/visa';

import { useAnalyticsContext } from './useAnalyticsContext';

interface Props {
  slug?: string;
  city?: City | CityForClp;
  dataLoaded: boolean;
}

type EventForProductList =
  | EventForEventGrid
  | EventForEventCollection
  | EventForFilmstripSection
  | EventForEventPage
  | EventForCityPage
  | Event;

const getStats = (
  allEvents: EventForProductList[],
  filterCondition: (event: EventForProductList) => boolean | undefined
) => {
  const events = allEvents.filter(filterCondition);
  const length = events.length;

  return {
    length,
    ids: events.map(event => event.id),
    percentage: Math.round((length / allEvents.length) * 100),
  };
};

const getVisibleEventLineupDetailsInfo = (
  trackEventGenres?: boolean,
  trackEventGenresByEventId?: {},
  events?: any
) => {
  let visibleEventLineupDetails: [] = [];

  if (events) {
    if (trackEventGenres) {
      visibleEventLineupDetails = events.map(
        (event: any) => event.genres || 'Secret'
      );
    } else if (
      trackEventGenresByEventId &&
      Object.keys(trackEventGenresByEventId).length > 0
    ) {
      visibleEventLineupDetails = events.map((event: any) =>
        trackEventGenresByEventId[event.id] ? event.genres || 'Secret' : ''
      );
      if (!visibleEventLineupDetails.some((v: string) => v != '')) {
        visibleEventLineupDetails = [];
      }
    }
  }

  const visibleEventLineupDetailsType =
    visibleEventLineupDetails.length > 0 ? 'genre' : '';

  return {
    visibleEventLineupDetails,
    visibleEventLineupDetailsType,
  };
};

export const useProductListAnalytics = ({ slug, city, dataLoaded }: Props) => {
  const [productListViewedEventSent, setProductListViewedEventSent] = useState(
    false
  );

  const { trackAnalyticsEvent } = useAnalyticsContext();

  const { visaPreSaleEligible, sessionLoading } = useVisaAuth({
    skipLoadCardRedemptionsStatus: true,
  });

  const onGetEventsQueryCompleted: OnGetEventsQueryCompletedType = useCallback(
    ({
      events,
      metadata,
      perPage,
      appliedFilterKeys,
      user,
      productListType,
      skipProductListFiltered = false,
      trackEventGenres = false,
      trackEventGenresByEventId = {},
    }) => {
      const { totalRecords, currentPage } = metadata;

      const loaded = !sessionLoading && dataLoaded;

      const shouldTriggerCityPageAnalyticsEvent =
        loaded && totalRecords !== undefined;

      if (!shouldTriggerCityPageAnalyticsEvent) {
        return;
      }

      const purchasableData = getStats(
        events,
        (event: EventForProductList) => event.isPurchasable
      );
      const soldOutData = getStats(
        events,
        (event: EventForProductList) => !event.isPurchasable && !event.cancelled
      );
      const preSaleData = getStats(
        events,
        (event: EventForProductList) => event.onPresale
      );

      const visibleEventIds = events.map(event => event.id);
      const {
        visibleEventLineupDetails,
        visibleEventLineupDetailsType,
      } = getVisibleEventLineupDetailsInfo(
        trackEventGenres,
        trackEventGenresByEventId,
        events
      );

      const analyticsProps = {
        city_slug: slug || city?.cachedSlug,
        is_visa_validated: visaPreSaleEligible,
        sofar_operated_market_type: city?.sofarOperatedMarketType,
        filters_applied: appliedFilterKeys,
        total_records: totalRecords,
        per_page: perPage === Infinity ? 999999999999 : perPage,
        current_page: currentPage,
        visible_events_count: events.length,
        visible_event_ids: visibleEventIds,
        visible_event_lineup_details: visibleEventLineupDetails,
        visible_event_lineup_details_type: visibleEventLineupDetailsType,
        visible_purchasable_events_count: purchasableData.length,
        percentage_purchasable: purchasableData.percentage,
        visible_purchasable_event_ids: purchasableData.ids,
        visible_sold_out_events_count: soldOutData.length,
        percentage_sold_out: soldOutData.percentage,
        visible_sold_out_event_ids: soldOutData.ids,
        visible_visa_presale_events_count: preSaleData.length,
        percentage_visa_presale: preSaleData.percentage,
        visible_visa_presale_event_ids: preSaleData.ids,
        item_ids: visibleEventIds.join(','), // Snapchat needs this, please do not remove
        email: user?.email?.toLowerCase()?.trim(), // Snapchat needs this, please do not remove
        product_list_type: productListType,
        rebrand: true,
      };

      if (productListViewedEventSent) {
        !skipProductListFiltered &&
          trackAnalyticsEvent('Product List Filtered', analyticsProps);
      } else {
        trackAnalyticsEvent(
          'Product List Viewed',
          {
            nonInteraction: 1,
            ...analyticsProps,
          },
          true
        );
        setProductListViewedEventSent(true);
      }
    },
    [
      trackAnalyticsEvent,
      dataLoaded,
      city,
      productListViewedEventSent,
      sessionLoading,
      slug,
      visaPreSaleEligible,
    ]
  );

  return { onGetEventsQueryCompleted };
};
