import { useMutation } from 'react-apollo';

import {
  RefundEventAttendeeData,
  TransferEventAttendeeData,
  UpdateEventAttendeeAttendanceData,
  UpdateEventAttendeeStateData,
  UpdateEventAttendeeTicketsData,
} from 'app/typings/eventAttendees';
import {
  REFUND_EVENT_ATTENDEE,
  TRANSFER_EVENT_ATTENDEE,
  UPDATE_EVENT_ATTENDEE_ATTENDANCE,
  UPDATE_EVENT_ATTENDEE_STATE,
  UPDATE_EVENT_ATTENDEE_TICKETS,
} from 'app/admin/graphql/eventAttendees/mutations';

export const RefundEventAttendee = () => {
  return useMutation<RefundEventAttendeeData>(REFUND_EVENT_ATTENDEE)[0];
};

export const TransferEventAttendee = () => {
  return useMutation<TransferEventAttendeeData>(TRANSFER_EVENT_ATTENDEE)[0];
};

export const UpdateEventAttendeeAttendance = () => {
  return useMutation<UpdateEventAttendeeAttendanceData>(
    UPDATE_EVENT_ATTENDEE_ATTENDANCE
  )[0];
};

export const UpdateEventAttendeeState = () => {
  return useMutation<UpdateEventAttendeeStateData>(
    UPDATE_EVENT_ATTENDEE_STATE
  )[0];
};

export const UpdateEventAttendeeTickets = () => {
  return useMutation<UpdateEventAttendeeTicketsData>(
    UPDATE_EVENT_ATTENDEE_TICKETS
  )[0];
};
