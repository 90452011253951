import { Artist } from 'app/typings/artists';
import { City } from 'app/typings/cities';
import { Event } from 'app/typings/events';
import { SharedGetDataParameters } from 'app/typings/generics';
import { Performance } from 'app/typings/performances';
import { User } from 'app/typings/users';
import { Venue } from 'app/typings/venues';

import { ArtistSetup } from './artistSetups';

export interface ArtistOfferEvent {
  eventId: number;
  isBooked?: boolean;
}

export interface ArtistOfferEvents {
  artistId: number;
  artistEvents: ArtistOfferEvent[];
}

export interface Offer {
  id: number;
  status: string;
  name: string;
  city?: City;
  createdAt?: string;
  createdBy?: User;
  customEmailMessage?: string;
  numArtistsAccepted?: number;
  numArtistsBooked?: number;
  numArtistsDeclined?: number;
  numArtistsOpen?: number;
  numArtistsTotal?: number;
  numEventsTotal?: number;
  numEventPositionsBooked?: number;
  numEventPositionsTotal?: number;
  numPerformancesCurrentlyAccepted?: number;
  performanceOffers?: PerformanceOffer[];
  venueOffers?: VenueOffer[];
  artists?: Artist[];
  events?: Event[];
  artistsAndEventsBooked?: ArtistOfferEvents[];
  artistsAndEventsNotBooked?: ArtistOfferEvents[];
  artistIdsOpen?: number[];
  artistIdsDeclined?: number[];
  isMultiSlot?: boolean;
  __typename?: string;
}

export interface PerformanceOffer {
  id: number;
  status: string;
  createdAt: string;
  declinedAt?: string;
  position?: number;
  performance?: Performance;
  offer?: Offer;
  artist?: Artist;
  event?: Event;
  artistSetup?: ArtistSetup;
}

export interface CreateOfferForArtistsData {
  success: boolean;
  offer: Offer;
}

export interface VenueOffer {
  active: boolean;
  createdAt: string;
  event: Event;
  id: number;
  offer: Offer;
  respondedById: number;
  status: string;
  venue: Venue;
  acceptedAt?: string;
  declinedAt?: string;
}

interface EventPosition {
  eventId?: number;
  position?: number;
}

export interface CreateOfferParameters {
  artistIds: number[];
  eventPositions: EventPosition[];
  name: string;
}

export interface CreateOfferForArtistsData {
  success: boolean;
  offer: Offer;
}

export interface CreateOfferForVenuesData {
  success: boolean;
  offer: Offer;
}

export interface AddVenuesToOfferData {
  success: boolean;
  offer: Offer;
}

export interface CloseOfferData {
  success: boolean;
  offer: Offer;
  performanceOffers: PerformanceOffer[];
}

export interface AddArtistToOfferData {
  success: boolean;
  offer: Offer;
}

export interface GetOffersData {
  __typename?: string;
  offers: {
    offers: Offer[];
    metadata: {
      totalRecords: number;
      currentPage: number;
    };
  };
}

export interface GetArtistOffersApiResponse {
  __typename?: string;
  offers: {
    offers: Offer[];
    metadata: {
      totalRecords: number;
      currentPage: number;
    };
  };
}

export interface GetOfferData {
  __typename?: string;
  offer: Offer;
}

export interface GetOfferVariables extends SharedGetDataParameters {
  id?: number;
}

export interface GetOffersVariables extends SharedGetDataParameters {
  offerType: string;
  offerSearch?: string | null;
  artistIds?: string;
  status?: string;
  hasUpcomingEvents?: boolean;
  createdDate?: string;
  createdById?: number;
  city?: string;
  orderBy?: string;
  orderDirection?: string;
  page?: number;
  perPage?: number;
}

export interface GetArtistOffersVariables extends SharedGetDataParameters {
  upcomingEventArtistId?: number;
  offerType?: string;
  status?: string;
  page?: number;
  perPage?: number;
}

export interface GetOfferForArtistVariables extends SharedGetDataParameters {
  id?: number;
}

export interface GetOfferForArtistData {
  __typename?: string;
  offer: Offer;
}

export interface AcceptDeclinePerformanceOffersData {
  success: boolean;
  performanceOffers: PerformanceOffer[];
}
export interface AcceptDeclineVenueOfferData {
  success: boolean;
  venueOffer: VenueOffer[];
}

export interface GetVenueOfferVariables extends SharedGetDataParameters {
  id?: number;
}

export interface GetVenueOfferData {
  __typename?: string;
  venueOffer: VenueOffer;
}

export enum OfferStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum PerformanceOfferStatus {
  OPEN = 'open',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  BOOKED = 'booked',
  CLOSED = 'closed',
}

export enum VenueOfferStatus {
  OPEN = 'open',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  BOOKED = 'booked',
  CLOSED = 'closed',
}

export interface SingleOfferCardData {
  offerId: number;
  event: Event | undefined;
  eventDate: string;
  artistLoadInAt: string;
  maxPaInputs?: string;
  status: string;
  cityTitle?: string;
  neighborhoodTitle?: string;
}

export interface MultiOfferCardData {
  offerType: string;
  offerId: number;
  eventDate: string;
  status: string;
  dedupedPerfOffers: PerformanceOffer[];
  cityTitle?: string;
}
