import * as Yup from 'yup';

const NOTES_CHARACTER_LIMIT = 500;

export const stripTags = (value: string) => {
  return value ? value.replace(/(<([^>]+)>)/gi, '') : value;
};

export const getNotesFormSchema = (intl: any, characterLimit?: number) =>
  Yup.string()
    .nullable()
    .test(
      'exceeds-character-limit',
      intl.formatMessage(
        {
          id: 'admin.shared.exceedsCharacterLimit',
        },
        {
          characterLimit: characterLimit || NOTES_CHARACTER_LIMIT,
        }
      ),
      function(value: string) {
        return (
          !value ||
          stripTags(value).length < (characterLimit || NOTES_CHARACTER_LIMIT)
        );
      }
    );
