import { useLazyQuery, useQuery } from 'react-apollo';

import { Event, EventPreview, ObjectImageMap } from 'app/typings';
import { BookingRequestsInfo, EventsInfo } from 'app/typings';
import { SharedGetDataParameters } from 'app/typings/generics';
import { queryParams } from 'app/shared/utils/queries';
import {
  GetEventsDataResponse,
  GetEventsForEventPlannerDataResponse,
  GetEventsVariables,
  GetEventVariables,
} from 'app/shared/graphql/events/queryHooks';

import {
  GET_ARTIST_EVENTS_INFO,
  GET_EVENT_FOR_ADMIN,
  GET_EVENT_FOR_CREW_PORTAL,
  GET_EVENT_FOR_EVENT_SELECTOR,
  GET_EVENTS_FOR_BOOKING_REQUEST_ACCEPT,
  GET_EVENTS_FOR_CITY_EDIT,
  GET_EVENTS_FOR_CREW_PORTAL,
  GET_EVENTS_FOR_EVENT_PLANNER,
  GET_EVENTS_FOR_GUESTLIST_TRANSFER_ATTENDEE,
  GET_EVENTS_FOR_OFFER_CREATE,
  GET_EVENTS_FOR_VENUE_EDIT,
} from './queries';

export interface ArtistEventsInfo {
  upcomingEvents: EventsInfo;
  pastEvents: EventsInfo;
  performanceOffers: EventsInfo;
  bookingRequests: BookingRequestsInfo;
}

interface GetArtistEventsInfoVariables extends SharedGetDataParameters {
  id?: number;
  scope?: string;
  perPage?: number;
}

export const GetArtistEventsInfo = (params: GetArtistEventsInfoVariables) => {
  return useQuery<ArtistEventsInfo>(
    GET_ARTIST_EVENTS_INFO,
    queryParams(params)
  );
};

export const GetEventsForEventPlanner = (params: GetEventsVariables) => {
  return useQuery<GetEventsForEventPlannerDataResponse, GetEventsVariables>(
    GET_EVENTS_FOR_EVENT_PLANNER,
    queryParams(params)
  );
};

export const GetEventsForCrewPortal = (params: GetEventsVariables) => {
  return useQuery<GetEventsDataResponse, GetEventsVariables>(
    GET_EVENTS_FOR_CREW_PORTAL,
    queryParams(params)
  );
};

export const GetEventForCrewPortal = (params: GetEventVariables) => {
  return useQuery<GetEventDataResponse, GetEventVariables>(
    GET_EVENT_FOR_CREW_PORTAL,
    queryParams(params)
  );
};

export const GetEventsForOfferCreate = (params: GetEventsVariables) => {
  return useQuery<GetEventsDataResponse>(
    GET_EVENTS_FOR_OFFER_CREATE,
    queryParams(params)
  );
};

export const GetEventsForGuestlistTransferAttendee = (
  params: GetEventsVariables
) => {
  return useQuery<GetEventsDataResponse>(
    GET_EVENTS_FOR_GUESTLIST_TRANSFER_ATTENDEE,
    queryParams(params)
  );
};

export const GetEventsForCityEdit = (params: GetEventsVariables) => {
  return useQuery<GetEventsDataResponse>(
    GET_EVENTS_FOR_CITY_EDIT,
    queryParams(params)
  );
};

export const GetEventsForVenueEdit = (params: GetEventsVariables) => {
  return useQuery<GetEventsDataResponse>(
    GET_EVENTS_FOR_VENUE_EDIT,
    queryParams(params)
  );
};

export const GetEventsForBookingRequestAccept = (
  params: GetEventsVariables
) => {
  return useQuery<GetEventsDataResponse>(
    GET_EVENTS_FOR_BOOKING_REQUEST_ACCEPT,
    queryParams(params)
  );
};

export interface GetEventInfoVariables extends SharedGetDataParameters {
  id?: number;
  includeDraftEvents?: boolean;
}

// TODO: Prune this of superfluous data
// tslint:disable-next-line
export type EventForAdmin = EventPreview;

export interface GetEventInfoDataResponse {
  event: EventForAdmin;
}

export const GetEventInfo = (params: GetEventInfoVariables = {}) => {
  return useQuery<GetEventInfoDataResponse>(
    GET_EVENT_FOR_ADMIN,
    queryParams(params)
  );
};

// TODO: Prune this of superfluous data instead of using default Event
// tslint:disable-next-line
type EventForEventSelector = Event;

export interface GetEventDataResponse {
  event: EventForEventSelector;
}

export const GetEventForEventSelectorLazy = (
  params: GetEventVariables = {}
) => {
  return useLazyQuery<GetEventDataResponse>(
    GET_EVENT_FOR_EVENT_SELECTOR,
    queryParams(params)
  );
};

export interface EventForHeaderImageUploader {
  id: string;
  images: ObjectImageMap;
}
