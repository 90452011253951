import { useMutation } from 'react-apollo';

import {
  CreateCuratorGroupData,
  UpdateCuratorGroupData,
} from 'app/typings/curatorGroups';
import {
  CREATE_CURATOR_GROUP,
  UPDATE_CURATOR_GROUP,
} from 'app/admin/graphql/curatorGroups/mutations';

export const CreateCuratorGroup = () => {
  return useMutation<CreateCuratorGroupData>(CREATE_CURATOR_GROUP)[0];
};

export const UpdateCuratorGroup = () => {
  return useMutation<UpdateCuratorGroupData>(UPDATE_CURATOR_GROUP)[0];
};
