import gql from 'graphql-tag';

export const GET_EVENT_COLLECTION_FOR_ADMIN = gql`
  query GetEventCollectionForAdmin($id: Int, $cachedSlug: String) {
    eventCollection(id: $id, cachedSlug: $cachedSlug) {
      id
      title
      description
      cachedSlug
      pastCachedSlugs
      collectionType
      whatToExpect
      eventsCount
      upcomingEventsCount
      createdBy {
        id
        firstName
        lastName
        email
      }
      createdAt
      status
      cities {
        id
        title
        cachedSlug
        timezone
      }
      cityDisplayType
      eventCollectionCities {
        id
        city {
          id
          title
          cachedSlug
        }
        position
      }
      citiesCount
      events {
        id
        startsAt
        localStartsAt
        cancelled
        isPublished
        neighborhood {
          id
          title
        }
        city {
          id
          cachedSlug
          title
        }
      }
      images {
        header {
          ratio1x1 {
            id
            ratio
            filename
            fileLocation
          }
          ratio16x9 {
            id
            ratio
            filename
            fileLocation
          }
        }
      }
      eventHeadline
      eventDescription
      merchandisingProperties {
        id
        propertyGroupId
        propertyGroupName
        key
        name
        iconName
        position
      }
    }
  }
`;

export const GET_EVENT_COLLECTIONS_FOR_ADMIN = gql`
  query GetEventCollectionsForAdmin(
    $eventCollectionSearch: String
    $businessOwner: String
    $city: String
    $collectionType: String
    $status: String
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    eventCollections(
      eventCollectionSearch: $eventCollectionSearch
      businessOwner: $businessOwner
      city: $city
      collectionType: $collectionType
      status: $status
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      eventCollections {
        id
        title
        cachedSlug
        collectionType
        eventsCount
        citiesCount
        upcomingEventsCount
        status
      }
      metadata {
        currentPage
        totalRecords
      }
    }
  }
`;
