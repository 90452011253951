import React from 'react';
import { useIntl } from 'react-intl';

import { useCurrentTheme } from 'app/shared/theme';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { Box, Divider, Logo, Typography } from 'app/shared/components/atoms';
import { NavigationLink as Link } from 'app/shared/components/atoms';
import { BasePageBox } from 'app/shared/components/molecules';

import { BottomSection } from './BottomSection';

interface Props {
  'data-qaid'?: string;
  footerRef: React.RefObject<HTMLDivElement>;
}

export const MinimalFooter: React.FC<Props> = ({
  'data-qaid': qaId = 'minimal-footer',
  footerRef,
}) => {
  const intl = useIntl();
  const { colors, zIndex } = useCurrentTheme();
  const { isDesktop, isTablet } = useIsBreakpointSize();

  return (
    <Box
      ref={footerRef}
      w="100%"
      h="fit-content"
      data-qaid={qaId}
      justifyContent="center"
      alignContent="center"
      flexDirection="column"
      alignItems="center"
      backgroundColor={colors.primary.black.base}
      zIndex={zIndex.navbar}
      bottom="0px"
    >
      <BasePageBox py={6}>
        <>
          <Box>
            <Link variant="tertiary" href="/">
              <Logo
                fillColor={colors.primary.white.base}
                variant="primary"
                height={30}
              />
            </Link>
          </Box>
          <Box w={isDesktop ? '33.3%' : isTablet ? '50%' : '100%'}>
            <Typography
              color={colors.primary.white.base}
              pt={5}
              fontSize="fontSize75"
            >
              {intl.formatMessage({ id: 'footer.sofarMission' })}
            </Typography>
          </Box>
        </>
      </BasePageBox>
      <Divider color={colors.primary.white.lightest} />
      <BottomSection />
    </Box>
  );
};
