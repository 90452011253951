import gql from 'graphql-tag';

export const ACCEPT_BOOKING_REQUEST = gql`
  mutation acceptBookingRequest(
    $id: Int!
    $performanceRequests: [PerformanceRequest!]!
  ) {
    acceptBookingRequest(id: $id, performanceRequests: $performanceRequests) {
      success
      bookingRequest {
        id
      }
      performances {
        id
      }
    }
  }
`;

export const DECLINE_BOOKING_REQUEST = gql`
  mutation declineBookingRequest($id: Int!) {
    declineBookingRequest(id: $id) {
      success
      bookingRequest {
        id
      }
    }
  }
`;
