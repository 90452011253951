import React, { useRef } from 'react';
import { css, keyframes } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { Typography } from 'app/shared/components/atoms';
import { NavigationLink as Link } from 'app/shared/components/atoms';
import { BasePageBox, IconButton } from 'app/shared/components/molecules';
import { ReactComponent as Close } from 'icons/close.svg';

interface Props {
  text?: string;
  link?: string;
  linkText?: string;
  onBannerDismiss?: () => void;
}

const verticalAnimation = (componentHeight: number) => keyframes`
  from {
    margin-top: -${componentHeight}px;
  }
  to {
    margin-top: 0px;
  }
`;

export const PageBanner: React.FC<Props> = ({
  text,
  link,
  linkText,
  onBannerDismiss,
}) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const ref = useRef<HTMLDivElement>(null);
  const { colors } = useCurrentTheme();

  const handleBannerClose = () => {
    if (onBannerDismiss) {
      onBannerDismiss();
      trackAnalyticsEvent('Banner Dismissed', {
        path: location.pathname,
        text,
        link,
        linkText,
      });
    }
  };

  if (!text) {
    return null;
  }

  const minHeight = 54;

  return (
    <BasePageBox
      ref={ref}
      data-qaid="page-banner"
      animation={css`
        ${verticalAnimation(ref?.current?.clientHeight || minHeight)} 500ms ease
      `}
      alignItems="center"
      justifyContent="space-between"
      gap={4}
      backgroundColor={colors.primary.white.base}
      py={4}
      minHeight={`${minHeight}px`}
    >
      <Typography as="div" display="inline" pr={1} data-qaid="page-banner-text">
        {text}{' '}
        {link && linkText && (
          <Link
            data-qaid="page-banner-link"
            href={link}
            openInNewTab={true}
            variant="secondary"
          >
            {linkText}
          </Link>
        )}
      </Typography>
      {onBannerDismiss && (
        <IconButton
          onClick={handleBannerClose}
          data-qaid="page-banner-dismiss-button"
          icon={Close}
          size="xs"
          strokeColor={colors.primary.black.base}
        />
      )}
    </BasePageBox>
  );
};
