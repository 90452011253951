import { useLazyQuery, useQuery } from 'react-apollo';

import { GetAttendeesData, GetAttendeesVariables } from 'app/typings/attendees';
import { queryParams } from 'app/shared/utils/queries';
import { GET_ATTENDEES } from 'app/admin/graphql/attendees/queries';

export const GetAttendees = (params: GetAttendeesVariables = {}) => {
  return useQuery<GetAttendeesData>(GET_ATTENDEES, queryParams(params));
};

export const GetAttendeesLazy = (params: GetAttendeesVariables = {}) => {
  return useLazyQuery<GetAttendeesData>(GET_ATTENDEES, queryParams(params));
};
