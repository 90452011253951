import { useMutation } from 'react-apollo';

import { Event } from 'app/typings/events';
import { CREATE_CANCELLATION } from 'app/admin/graphql/cancellations/mutations';

interface CreateCancellationData {
  success: boolean;
  event: Event;
}

export const CreateCancellation = () => {
  return useMutation<CreateCancellationData>(CREATE_CANCELLATION)[0];
};
