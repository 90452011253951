import gql from 'graphql-tag';

export const GET_COMPETITION = gql`
  query GetCompetition($id: Int!) {
    competition(id: $id) {
      id
      title
      closesAt
      status
      uid
      city {
        id
        title
        timezone
      }
      event {
        id
        localStartsAt
      }
      creator {
        id
        firstName
        lastName
        email
      }
      createdAt
      entriesCount
      winnersCount
      maxWinners
      entries {
        id
        currentState
        user {
          id
          firstName
          lastName
          email
        }
      }
      intro
      thirdPartyCtaText
      thirdPartyCtaLink
      backgroundImageUrl
      logoUrl
      subtitle
      subtitleTwo
      description
      postDescription
      winnerCopy
      expiredCopy
      moreInfoTitle
      moreInfoSubheading
      moreInfoPreview
      featuredContentCtaText
      featuredContentCtaLink
      moreInfoImageUrl
      turnOnOptIn
      optInCopy
      shareTitle
      shareCopy
      shareImageUrl
    }
  }
`;

export const GET_COMPETITIONS = gql`
  query GetCompetitions(
    $competitionSearch: String
    $city: String
    $status: String
    $closesAt: String
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
  ) {
    competitions(
      competitionSearch: $competitionSearch
      city: $city
      status: $status
      closesAt: $closesAt
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      competitions {
        id
        title
        closesAt
        status
        uid
        city {
          id
          title
          timezone
        }
        event {
          id
          localStartsAt
        }
        creator {
          id
          firstName
          lastName
          email
        }
        createdAt
        entriesCount
      }
      metadata {
        currentPage
        totalRecords
      }
    }
  }
`;
