import { useMutation } from 'react-apollo';

import {
  AcknowledgeUserAgreementData,
  UpdateUserFromAdminData,
  UpdateUserFromCrewDirectoryData,
  UpdateUserRolesData,
} from 'app/typings/users';
import {
  ACKNOWLEDGE_USER_AGREEMENT,
  UPDATE_USER_FROM_ADMIN,
  UPDATE_USER_FROM_CREW_DIRECTORY,
  UPDATE_USER_ROLES,
} from 'app/admin/graphql/users/mutations';

export const AcknowledgeUserAgreement = () => {
  return useMutation<AcknowledgeUserAgreementData>(
    ACKNOWLEDGE_USER_AGREEMENT
  )[0];
};

export const UpdateUserFromAdmin = () => {
  return useMutation<UpdateUserFromAdminData>(UPDATE_USER_FROM_ADMIN)[0];
};

export const UpdateUserFromCrewDirectory = () => {
  return useMutation<UpdateUserFromCrewDirectoryData>(
    UPDATE_USER_FROM_CREW_DIRECTORY
  )[0];
};

export const UpdateUserRoles = () => {
  return useMutation<UpdateUserRolesData>(UPDATE_USER_ROLES)[0];
};
