import gql from 'graphql-tag';

import { artistSocialUrls } from 'app/shared/utils/socialLinks';

export const GET_ARTIST_BASIC_INFO = gql`
  query GetArtistBasicInfo($id: Int!) {
    artist(id: $id) {
      id
      title
      description
      preferredPronouns {
        id
        key
        name
      }
      demographics {
        id
        propertyGroupName
        key
        name
      }
      artistApplication {
        id
        portfolioUrl1
        portfolioUrl2
        portfolioUrl3
      }
      homeCity
      homeCityDescription
      homeCityLatitude
      homeCityLongitude
      homeCountryCode
      homeState
      closestSofarCityTitle
      closestSofarCityCountry
      tags {
        id
        name
        tagGroupName
      }
      genres {
        id
        name
      }
      closestSofarCityId
      imageUrl
      largeImageUrl
      signedStatus
      bookingPreferences {
        id
        artistBookingAvailabilityDates {
          id
          date
        }
        city {
          id
          title
        }
        isAvailableLastMinute
        isAvailableOnCertainDates
        isGenerallyAvailable
        isLegacy
      }
      bookingPreferencesNotes
      bookingPreferencesUpdatedAt
      performingRightsOrganisation {
        id
        title
        country {
          id
          title
        }
      }
      artistEligibilities {
        key
        name
      }
      isSelfBookingEnabled
      needsReview
      shouldNotBook
      performances {
        id
        event {
          localStartsAt
          city {
            title
            id
          }
        }
      }
      artistSetups {
        id
        setupName
        numPerformers
        isPrimarySetup
        audioSupport
        additionalSupportNotes
        instrumentNotes
        setuppableId
        setuppableType
        lastUpdatedAt
        createdAt
        derivedFrom
      }
      associatedUsers {
        id
        firstName
        lastName
        email
      }
      contactInfos {
        id
        firstName
        lastName
        email
        phoneNumber
        position
        isDayOfShowContact
        contactRelationship {
          id
          name
        }
      }
      socialUrls {
        ${artistSocialUrls.join(' ')}
      }
      videos {
        id
        displayName
        description
        thumbnailUrl
        url
        performance {
          id
        }
        showOnFeaturedVideos
      }
      pastEventsCount
      upcomingEventsCount
      lastEventAt
      citiesPlayedCount
      reviewedAt
      reviewedBy {
        id
        firstName
        lastName
        email
        primaryRoles {
          id
          key
          name
        }
      }
    }
  }
`;

export const GET_ARTIST_ELIGIBILITIES = gql`
  query GetArtistEligibilities {
    artistEligibilities {
      artistEligibilities {
        key
        name
      }
    }
  }
`;

export const GET_ARTISTS = gql`
  query GetArtists(
    $artistSearch: String
    $availabilityType: String
    $availableForDate: String
    $bookingSettings: String
    $city: String
    $cityIds: String
    $closestSofarCity: String
    $eligibility: String
    $eventScope: String
    $excludeShouldNotBook: Boolean
    $excludeNeedsReview: Boolean
    $excludeDeclinedPerformanceOffersForEventId: Int
    $lastEventDate: String
    $lastEventDateInCity: String
    $lastOfferDate: String
    $notTag: String
    $numPastEvents: String
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
    $tag: String
  ) {
    artists(
      artistSearch: $artistSearch
      availabilityType: $availabilityType
      availableForDate: $availableForDate
      bookingSettings: $bookingSettings
      city: $city
      cityIds: $cityIds
      closestSofarCity: $closestSofarCity
      eligibility: $eligibility
      eventScope: $eventScope
      excludeShouldNotBook: $excludeShouldNotBook
      excludeNeedsReview: $excludeNeedsReview
      excludeDeclinedPerformanceOffersForEventId: $excludeDeclinedPerformanceOffersForEventId
      lastEventDate: $lastEventDate
      lastEventDateInCity: $lastEventDateInCity
      lastOfferDate: $lastOfferDate
      notTag: $notTag
      numPastEvents: $numPastEvents
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      tag: $tag
    ) {
      artists {
        id
        email
        title
        imageUrl
        largeImageUrl
        homeCityDescription
        homeCity
        bookingPreferences {
          id
          city {
            id
            title
          }
          isAvailableLastMinute
          isAvailableOnCertainDates
          isGenerallyAvailable
          isLegacy
        }
        bookingPreferencesNotes
        bookingPreferencesUpdatedAt
        mainArtistLink
        pastEventsCount
        upcomingEventsCount
        lastEventAt
        citiesPlayedCount
        signedStatus
        needsReview
        genres {
          id
          name
        }
        tags {
          id
          name
        }
        artistEligibilities {
          key
          name
        }
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_ARTISTS_FOR_ADD_ARTIST_TO_EVENT = gql`
  query GetArtistsForAddArtistToEvent(
    $availabilityType: String
    $availableForDate: String
    $city: String
    $eligibility: String
    $eventScope: String
    $excludeShouldNotBook: Boolean
    $excludeNeedsReview: Boolean
    $excludeDeclinedPerformanceOffersForEventId: Int
    $lastEventDateInCity: String
    $lastOfferDate: String
    $numPastEvents: String
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
  ) {
    artists(
      availabilityType: $availabilityType
      availableForDate: $availableForDate
      city: $city
      eligibility: $eligibility
      eventScope: $eventScope
      excludeShouldNotBook: $excludeShouldNotBook
      excludeNeedsReview: $excludeNeedsReview
      excludeDeclinedPerformanceOffersForEventId: $excludeDeclinedPerformanceOffersForEventId
      lastEventDateInCity: $lastEventDateInCity
      lastOfferDate: $lastOfferDate
      numPastEvents: $numPastEvents
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      artists {
        id
        email
        title
        imageUrl
        homeCityDescription
        homeCity
        needsReview
        bookingPreferences {
          id
          city {
            id
            title
          }
          isAvailableLastMinute
          isAvailableOnCertainDates
          isGenerallyAvailable
          isLegacy
        }
        pastEventsCount
        citiesPlayedCount
      }
    }
  }
`;

export const GET_ARTISTS_FOR_ARTIST_TYPEAHEAD = gql`
  query GetArtistsForArtistTypeahead(
    $artistSearch: String
    $eligibility: String
    $notTag: String
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
    $isLiteQuery: Boolean
  ) {
    artists(
      artistSearch: $artistSearch
      eligibility: $eligibility
      notTag: $notTag
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      isLiteQuery: $isLiteQuery
    ) {
      artists {
        id
        email
        title
        imageUrl
        homeCityDescription
        needsReview
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_ARTISTS_FOR_OFFER_CREATE = gql`
  query GetArtistsForOfferCreate(
    $availabilityType: String
    $bookingSettings: String
    $city: String
    $eventScope: String
    $excludeShouldNotBook: Boolean
    $excludeNeedsReview: Boolean
    $lastEventDateInCity: String
    $lastOfferDate: String
    $numPastEvents: String
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
  ) {
    artists(
      availabilityType: $availabilityType
      bookingSettings: $bookingSettings
      city: $city
      eventScope: $eventScope
      excludeShouldNotBook: $excludeShouldNotBook
      excludeNeedsReview: $excludeNeedsReview
      lastEventDateInCity: $lastEventDateInCity
      lastOfferDate: $lastOfferDate
      numPastEvents: $numPastEvents
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      artists {
        id
        email
        title
        imageUrl
        homeCityDescription
        homeCity
        bookingPreferences {
          id
          city {
            id
            title
          }
          isAvailableLastMinute
          isAvailableOnCertainDates
          isGenerallyAvailable
          isLegacy
        }
        pastEventsCount
        citiesPlayedCount
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;
