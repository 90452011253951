import { useContext } from 'react';

import { EventPreview } from 'app/typings';
import { CuratorGroup } from 'app/typings/curatorGroups';
import { currencyRoundedFormatter } from 'app/shared/utils/currencyFormatter';
import {
  COMMERCIAL_PARTNERSHIP_EVENT,
  CURATOR_COMMERCIAL,
  CURATOR_NONCOMMERCIAL,
  DISCOVERY_EVENT,
  FEATURED_SET_EVENT,
  O_AND_O,
  PRIVATE_DISCOVERY_EVENT,
} from 'app/shared/utils/events';
import { PermissionsContext } from 'app/shared/context/Permissions';

export const HasPermissionForEvent = (
  eventOrganizedAs: string | undefined,
  curatorGroup?: CuratorGroup
) => {
  const permissionsContext = useContext(PermissionsContext);
  const hasEditAllCuratorEventsPermission = permissionsContext.hasPermission(
    'event.eventOwnerTypeCurator.edit'
  );
  const hasViewEventsByCuratorGroupPermission = permissionsContext.hasPermission(
    'event.list.viewByCuratorGroup',
    curatorGroup?.id
  );
  const hasEditOAndOEventsPermission = permissionsContext.hasPermission(
    'event.eventOwnerTypeOAndO.edit'
  );

  let canAccess = true;

  if (
    permissionsContext &&
    permissionsContext.permissions &&
    eventOrganizedAs
  ) {
    canAccess =
      ((hasEditAllCuratorEventsPermission ||
        hasViewEventsByCuratorGroupPermission) &&
        (eventOrganizedAs === 'curator_owner_commercial_event' ||
          eventOrganizedAs === 'curator_owner_noncommercial_event')) ||
      (hasEditOAndOEventsPermission && eventOrganizedAs === 'o_and_o');
  }

  return canAccess;
};

export const HasEditPermissionForEvent = (
  eventOrganizedAs: string | undefined,
  curatorGroup?: CuratorGroup
) => {
  const permissionsContext = useContext(PermissionsContext);
  const hasEditAllCuratorEventsPermission = permissionsContext.hasPermission(
    'event.eventOwnerTypeCurator.edit'
  );
  const hasEditCuratorEventsByCuratorGroupPermission = permissionsContext.hasPermission(
    'event.eventOwnerTypeCurator.editByCuratorGroup',
    curatorGroup?.id
  );
  const hasEditOAndOEventsPermission = permissionsContext.hasPermission(
    'event.eventOwnerTypeOAndO.edit'
  );

  let canAccess = true;

  if (
    permissionsContext &&
    permissionsContext.permissions &&
    eventOrganizedAs
  ) {
    canAccess =
      ((hasEditAllCuratorEventsPermission ||
        hasEditCuratorEventsByCuratorGroupPermission) &&
        (eventOrganizedAs === 'curator_owner_commercial_event' ||
          eventOrganizedAs === 'curator_owner_noncommercial_event')) ||
      (hasEditOAndOEventsPermission && eventOrganizedAs === 'o_and_o');
  }

  return canAccess;
};

export const validDomainsForFromEmail = [
  'sofarsounds.com',
  'sofarcurators.com',
];

export const eventTypeLabels = {
  [DISCOVERY_EVENT]: 'Discovery',
  [FEATURED_SET_EVENT]: 'Featured Set',
  [COMMERCIAL_PARTNERSHIP_EVENT]: 'Commercial Partnership',
  [PRIVATE_DISCOVERY_EVENT]: 'Private Discovery',
};

export const eventOwnerTypeLabels = {
  [O_AND_O]: 'Sofar Operated',
  [CURATOR_COMMERCIAL]: 'Commercial Curator',
  [CURATOR_NONCOMMERCIAL]: 'Non-Commercial Curator',
};

export const eventTypeLabelsShort = {
  [DISCOVERY_EVENT]: 'Discovery',
  [FEATURED_SET_EVENT]: 'Featured Set',
  [COMMERCIAL_PARTNERSHIP_EVENT]: 'Comm Partnership',
  [PRIVATE_DISCOVERY_EVENT]: 'Private Discovery',
};

export const eventOrganizedAsLabels = (intl: any) => ({
  o_and_o: intl.formatMessage({
    id: 'admin.shared.eventOrganizedAs.sofarOperated',
  }),
  curator_owner_commercial_event: intl.formatMessage({
    id: 'admin.shared.eventOrganizedAs.commercialCurator',
  }),
  curator_owner_noncommercial_event: intl.formatMessage({
    id: 'admin.shared.eventOrganizedAs.noncommercialCurator',
  }),
});

export const eventOrganizedAsLabelsShort = {
  o_and_o: 'Sofar-OP',
  curator_owner_commercial_event: 'Curator-C',
  curator_owner_noncommercial_event: 'Curator-NC',
};

export const emailGroupLabels = (intl: any) => ({
  Artist: intl.formatMessage({
    id: 'shared.artists',
  }),
  Venue: intl.formatMessage({
    id: 'shared.venues',
  }),
  Guest: intl.formatMessage({
    id: 'admin.eventPlanner.emails.groupGuests',
  }),
  Crew: intl.formatMessage({
    id: 'admin.eventPlanner.emails.groupTeam',
  }),
  Urgent: intl.formatMessage({
    id: 'admin.eventPlanner.emails.groupUrgent',
  }),
});

export const emailGroupLabelsSingular = (intl: any) => ({
  Artist: intl.formatMessage({
    id: 'admin.eventPlanner.emails.groupArtistsSingular',
  }),
  Venue: intl.formatMessage({
    id: 'admin.eventPlanner.emails.groupVenuesSingular',
  }),
  Guest: intl.formatMessage({
    id: 'admin.eventPlanner.emails.groupGuestsSingular',
  }),
  Crew: intl.formatMessage({
    id: 'admin.eventPlanner.emails.groupTeamSingular',
  }),
});

export const emailGroupSettingsLabels = (intl: any) => ({
  Artist: intl.formatMessage({
    id: 'shared.artists',
  }),
  Venue: intl.formatMessage({
    id: 'shared.venues',
  }),
  Guest: intl.formatMessage({
    id: 'admin.eventPlanner.emails.groupGuests',
  }),
  Crew: intl.formatMessage({
    id: 'admin.eventPlanner.emails.groupTeam',
  }),
});

export const eventTypeOptions = (intl: any) => [
  {
    title: intl.formatMessage({
      id: 'admin.eventCreate.discoveryType',
    }),
    value: DISCOVERY_EVENT,
    shortName: 'DSC',
  },
  {
    title: intl.formatMessage({
      id: 'admin.eventCreate.featuredSetType',
    }),
    value: FEATURED_SET_EVENT,
    shortName: 'FS',
  },
  {
    title: intl.formatMessage({
      id: 'admin.eventCreate.commercialPartnershipType',
    }),
    titleShort: intl.formatMessage({
      id: 'admin.eventCreate.commercialPartnershipTypeShort',
    }),
    value: COMMERCIAL_PARTNERSHIP_EVENT,
    shortName: 'CPF',
  },
  {
    title: intl.formatMessage({
      id: 'admin.eventCreate.privateDiscoveryType',
    }),
    value: PRIVATE_DISCOVERY_EVENT,
    shortName: 'PD',
  },
  {
    title: intl.formatMessage({
      id: 'shared.experimental',
    }),
    value: 'EventFormatExperiment',
    shortName: '',
  },
];

export const SEATINGS = {
  unknown: 'Unknown',
  no_chairs: 'No Chairs Available',
  some_chairs: 'Some Chairs Available',
  chairs_available: 'Fully Seated (Chairs Available)',
  chairs_built_in: 'Fully Seated (Chairs Built In)',
  standing_room: 'Standing Room',
};

export const formatTypeLabels = {
  discovery_standard: 'Standard Discovery',
  discovery_differentiated: 'Differentiated Themed Discovery',
  high_margin: '3x Concerts',
};

// Based on db integer type max range
export const maxEventPriceMainUnits = 21400000;

export const eventMerchandisingHeadlineMaxLength = 70;

export const eventMerchandisingDescriptionMaxLength = 300;

export const eventTicketPriceForDisplay = (event: EventPreview) => {
  const eventTicketPrice =
    event.isEarlyBird && event.earlyBirdOriginalPrice
      ? event.earlyBirdOriginalPrice
      : event.price;

  return currencyRoundedFormatter(
    eventTicketPrice * 100 || 0,
    event.currencySymbol || '$'
  );
};
