import { useMutation } from 'react-apollo';

import {
  CreateEventCollectionData,
  UpdateEventCollectionData,
  UpdateEventCollectionPositionsData,
} from 'app/typings/eventCollections';
import {
  CREATE_EVENT_COLLECTION,
  UPDATE_EVENT_COLLECTION,
  UPDATE_EVENT_COLLECTION_POSITIONS,
} from 'app/admin/graphql/eventCollections/mutations';

export const CreateEventCollection = () => {
  return useMutation<CreateEventCollectionData>(CREATE_EVENT_COLLECTION)[0];
};

export const UpdateCollection = () => {
  return useMutation<UpdateEventCollectionData>(UPDATE_EVENT_COLLECTION)[0];
};

export const UpdateEventCollectionPositions = () => {
  return useMutation<UpdateEventCollectionPositionsData>(
    UPDATE_EVENT_COLLECTION_POSITIONS
  )[0];
};
