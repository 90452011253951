import { Venue } from 'app/typings/venues';

export const venueNameAndAddress = (venue: Venue | undefined) => {
  if (venue) {
    if (venue.venueName && venue.address && venue.city) {
      return `${venue.venueName} (${venue.address}), ${venue.city?.title}`;
    }
    if (venue.venueName && venue.address) {
      return `${venue.venueName} (${venue.address})`;
    }
    if (venue.venueName && venue.city) {
      return `${venue.venueName}, ${venue.city?.title}`;
    }
    if (venue.venueName) {
      return venue.venueName;
    }
    if (venue.address && venue.city) {
      return `${venue.address}, ${venue.city?.title}`;
    }
    if (venue.address) {
      return venue.address;
    }
  }
  return '';
};

export const venueNameOrAddress = (venue: Venue | undefined) => {
  if (venue) {
    if (venue.venueName) {
      return venue.venueName;
    }
    if (venue.address) {
      return venue.address;
    }
  }
  return '';
};

export const venueStatusLabels = {
  pending: 'Pending',
  accepted: 'Accepted',
  not_accepted: 'Not Accepted',
  expired: 'Expired',
};

export const availableDayOptions = [
  {
    title: 'Sunday',
    value: 'sunday',
  },
  {
    title: 'Monday',
    value: 'monday',
  },
  {
    title: 'Tuesday',
    value: 'tuesday',
  },
  {
    title: 'Wednesday',
    value: 'wednesday',
  },
  {
    title: 'Thursday',
    value: 'thursday',
  },
  {
    title: 'Friday',
    value: 'friday',
  },
  {
    title: 'Saturday',
    value: 'saturday',
  },
];
