import gql from 'graphql-tag';

import { venueSocialUrls } from 'app/shared/utils/socialLinks';

export const ARCHIVE_VENUE = gql`
  mutation archiveVenue($id: Int!) {
    archiveVenue(id: $id) {
      success
      venue {
        id
      }
    }
  }
`;

export const CREATE_VENUE = gql`
  mutation createVenue(
    $venueName: String
    $address: String!
    $address2: String
    $cityId: Int
    $contactInfos: [MutateContactInfo!]
    $closestStation: String
    $neighborhoodId: Int
    $processProperties: Boolean
    $properties: [Int!]
    $capacity: String
    $numTicketsAvailableForSale: String
    $numGuestTickets: String
    $tags: [Int!]
    $processTags: Boolean
    $venueCategories: [Int!]
    $socialUrls: SocialUrlsInput
    $mapUrl: String
    $streetViewUrl: String
    $venueFeePresent: Boolean
    $venueFeeDescription: String
    $maxPaInputs: String
  ) {
    createVenue(
      venueName: $venueName
      address: $address
      address2: $address2
      cityId: $cityId
      contactInfos: $contactInfos
      closestStation: $closestStation
      neighborhoodId: $neighborhoodId
      processProperties: $processProperties
      properties: $properties
      capacity: $capacity
      numTicketsAvailableForSale: $numTicketsAvailableForSale
      numGuestTickets: $numGuestTickets
      tags: $tags
      processTags: $processTags
      venueCategories: $venueCategories
      socialUrls: $socialUrls
      mapUrl: $mapUrl
      streetViewUrl: $streetViewUrl
      venueFeePresent: $venueFeePresent
      venueFeeDescription: $venueFeeDescription
      maxPaInputs: $maxPaInputs
    ) {
      venue {
        id
      }
      success
    }
  }
`;

export const RESET_VENUE_TERMS_AND_CONDITIONS = gql`
  mutation resetVenueTermsAndConditions($id: Int!) {
    resetVenueTermsAndConditions(id: $id) {
      success
      venue {
        id
      }
    }
  }
`;

export const UPDATE_VENUE = gql`
  mutation updateVenue(
    $venueId: Int!
    $venueName: String
    $address: String
    $address2: String
    $latitude: Float
    $longitude: Float
    $cityId: Int
    $neighborhoodId: Int
    $closestStation: String
    $processProperties: Boolean
    $properties: [Int!]
    $capacity: String
    $numTicketsAvailableForSale: String
    $numGuestTickets: String
    $contactInfos: [MutateContactInfo!]
    $availableDays: [String!]
    $availabilityNotes: String
    $hostingFrequency: String
    $tags: [Int!]
    $processTags: Boolean
    $venueCategories: [Int!]
    $socialUrls: SocialUrlsInput
    $mapUrl: String
    $streetViewUrl: String
    $externalVenueNotes: String
    $inviteEmailMessage: String
    $revealAddressEmailMessage: String
    $seating: String
    $seatingNotes: String
    $recommendedCrewCount: String
    $gearStorage: String
    $hasTapWater: Boolean
    $internalVenueNotes: String
    $audioNotes: String
    $maxPaInputs: String
    $bathroomNotes: String
    $checkInProcessNotes: String
    $loadInNotes: String
    $mcNotes: String
    $merchTablePlacementNotes: String
    $stageSetupNotes: String
    $suppliesNeededNotes: String
    $timingNotes: String
    $parkingNotes: String
    $noiseRestrictions: String
    $localCrewLoadInAt: String
    $localArtistLoadInAt: String
    $localGuestsArriveAt: String
    $localStartsAt: String
    $localEndsAt: String
    $venueFeePresent: Boolean
    $venueFeeDescription: String
    $applyMethod: String
    $selectedValues: [String!]
  ) {
    updateVenue(
      venueId: $venueId
      venueName: $venueName
      address: $address
      address2: $address2
      latitude: $latitude
      longitude: $longitude
      cityId: $cityId
      neighborhoodId: $neighborhoodId
      closestStation: $closestStation
      processProperties: $processProperties
      properties: $properties
      capacity: $capacity
      numTicketsAvailableForSale: $numTicketsAvailableForSale
      numGuestTickets: $numGuestTickets
      contactInfos: $contactInfos
      availableDays: $availableDays
      availabilityNotes: $availabilityNotes
      hostingFrequency: $hostingFrequency      
      tags: $tags
      processTags: $processTags
      venueCategories: $venueCategories
      socialUrls: $socialUrls
      mapUrl: $mapUrl
      streetViewUrl: $streetViewUrl
      externalVenueNotes: $externalVenueNotes
      inviteEmailMessage: $inviteEmailMessage
      audioNotes: $audioNotes
      maxPaInputs: $maxPaInputs
      bathroomNotes: $bathroomNotes
      checkInProcessNotes: $checkInProcessNotes
      loadInNotes: $loadInNotes
      mcNotes: $mcNotes
      merchTablePlacementNotes: $merchTablePlacementNotes
      stageSetupNotes: $stageSetupNotes
      suppliesNeededNotes: $suppliesNeededNotes
      timingNotes: $timingNotes
      parkingNotes: $parkingNotes
      revealAddressEmailMessage: $revealAddressEmailMessage
      seating: $seating
      seatingNotes: $seatingNotes
      recommendedCrewCount: $recommendedCrewCount
      gearStorage: $gearStorage
      hasTapWater: $hasTapWater
      internalVenueNotes: $internalVenueNotes
      noiseRestrictions: $noiseRestrictions
      localCrewLoadInAt: $localCrewLoadInAt
      localArtistLoadInAt: $localArtistLoadInAt
      localGuestsArriveAt: $localGuestsArriveAt
      localStartsAt: $localStartsAt
      localEndsAt: $localEndsAt
      venueFeePresent: $venueFeePresent
      venueFeeDescription: $venueFeeDescription
      applyMethod: $applyMethod
      selectedValues: $selectedValues
    ) {
      success
      venue {
        id
        address
        address2
        capacity
        numGuestTickets
        properties {
          id
          propertyGroupId
          key
          name
          iconName
          position
          infoUrl
        }
        city {
          id
          title
          country {
            id
            title
          }
        }
        emailAddress
        neighborhood {
          id
          title
        }
        closestStation
        capacity
        venueName
        mapUrl
        streetViewUrl
        externalVenueNotes
        inviteEmailMessage
        revealAddressEmailMessage
        availableDays {
          key
          name
        }
        availabilityNotes
        hostingFrequency {
          key
          name
        }
        seating {
          key
          name
        }
        seatingNotes
        tags {
          id
          name
        }
        venueCategories {
          id
          name
        }
        socialUrls {
          ${venueSocialUrls.join(' ')}
        }
        contactInfos {
          id
          firstName
          lastName
          email
          phoneNumber
          position
          isDayOfShowContact
          contactRelationship {
            id
            name
          }
        }
        pastEventsCount
        upcomingEventsCount
        lastEventAt
        numTicketsAvailableForSale
        recommendedCrewCount
        gearStorage
        hasTapWater
        internalVenueNotes
        audioNotes
        bathroomNotes
        checkInProcessNotes
        loadInNotes
        mcNotes
        merchTablePlacementNotes
        stageSetupNotes
        suppliesNeededNotes
        timingNotes
        parkingNotes
        noiseRestrictions
        localCrewLoadInAt
        localArtistLoadInAt
        localGuestsArriveAt
        localStartsAt
        localEndsAt
        venueFeePresent
        venueFeeDescription
        imageUrl
        largeImageUrl
        isArchived
        status
        termsAndConditionsAcceptedAt
        termsAndConditionsAcceptedBy {
          id
          firstName
          lastName
          email
        }
        maxPaInputs {
          key
          name
        }
      }
    }
  }
`;

export const CREATE_VENUE_PHOTO = gql`
  mutation createVenuePhoto(
    $venueId: Int!
    $title: String!
    $description: String
    $image: RefileImageInput!
  ) {
    createVenuePhoto(
      venueId: $venueId
      title: $title
      description: $description
      image: $image
    ) {
      success
      venuePhoto {
        id
        description
        imageUrl
        title
        updatedAt
      }
    }
  }
`;

export const DELETE_VENUE_PHOTO = gql`
  mutation deleteVenuePhoto($venuePhotoId: Int!) {
    deleteVenuePhoto(venuePhotoId: $venuePhotoId) {
      success
    }
  }
`;

export const UPDATE_VENUE_PHOTO = gql`
  mutation updateVenuePhoto(
    $photoId: Int!
    $title: String
    $description: String
    $image: RefileImageInput
  ) {
    updateVenuePhoto(
      photoId: $photoId
      title: $title
      description: $description
      image: $image
    ) {
      success
      venuePhoto {
        id
        description
        imageUrl
        title
        updatedAt
      }
    }
  }
`;
