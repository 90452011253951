import gql from 'graphql-tag';

export const SEND_EMAIL_TEST = gql`
  mutation sendEmailTest(
    $email: String!
    $toEmail: String!
    $useCase: String!
  ) {
    sendEmailTest(email: $email, toEmail: $toEmail, useCase: $useCase) {
      success
    }
  }
`;
