import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { Icon } from 'app/shared/components/atoms';
import { Box, Typography } from 'app/shared/components/atoms';
import { ReactComponent as MusicLove } from 'icons/streamline-regular/music-audio/music/music-love.svg';

interface Props {
  'data-qaid'?: string;
  children: any;
}

export const GenericErrorScreen: React.FC<Props> = ({
  'data-qaid': qaId,
  children,
}) => {
  const { colors } = useCurrentTheme();

  return (
    <Box
      h="100%"
      w="100%"
      data-qaid={qaId}
      justifyContent="center"
      alignContent="center"
      flexDirection="column"
      alignItems="center"
      p={12}
      mb={10}
    >
      <Box flexDirection="column" alignItems="center" maxWidth="400px">
        <Box
          borderRadius="100%"
          b={colors.accent.primaryAccent.base}
          alignItems="center"
          justifyContent="center"
          my={6}
          w="84px"
          h="84px"
        >
          <Icon
            icon={MusicLove}
            strokeColor={colors.accent.primaryAccent.base}
            size="xl"
          />
        </Box>
        <Typography fontSize="fontSize600" textTransform="uppercase">
          Oh No!
        </Typography>
        <Box flexDirection="column" alignItems="center" gap={10}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default GenericErrorScreen;
