import {
  Offer,
  OfferStatus,
  PerformanceOffer,
  PerformanceOfferStatus,
} from 'app/typings/offers';

// ES6 way to dedupe an array of objects by unique ID, ensuring that later objects
// take precedence over earlier objects when there are duplicates
export const dedupePerformanceOffers = (perfOffers: PerformanceOffer[]) => {
  return [
    ...new Map(
      perfOffers.map((perfOffer: PerformanceOffer) => [perfOffer.id, perfOffer])
    ).values(),
  ] as PerformanceOffer[];
};

export const filterActivePerformanceOffers = (
  performanceOffers: PerformanceOffer[]
) =>
  performanceOffers &&
  performanceOffers.filter(
    (offer: PerformanceOffer) =>
      ![PerformanceOfferStatus.BOOKED, PerformanceOfferStatus.CLOSED].includes(
        offer.status as PerformanceOfferStatus
      )
  );

export const validateOfferArtistLimit = (
  offer: Offer,
  maxNumArtistsByNumPositions: any
) => {
  if (
    !offer ||
    !offer.numEventPositionsTotal ||
    !offer.numEventsTotal ||
    !offer.numArtistsTotal ||
    offer.status === OfferStatus.CLOSED
  ) {
    return false;
  }
  const numPositions = Math.floor(
    offer.numEventPositionsTotal / offer.numEventsTotal
  );
  return (
    numPositions >= 1 &&
    numPositions <= 3 &&
    offer.numArtistsTotal < maxNumArtistsByNumPositions[numPositions]
  );
};
