import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { useAnalyticsContext } from 'app/shared/utils';
import { navConfig } from 'app/shared/utils/navbar';
import { useNavbarContext } from 'app/shared/context';
import { AuthContext } from 'app/shared/context/Auth';

export function useNavConfig({
  loggedIn: loggedInProp,
}: { loggedIn?: boolean } | undefined = {}) {
  const intl = useIntl();
  const { user, loggedIn, signOut } = useContext(AuthContext);
  const { isLandingPage } = useNavbarContext();
  const {
    trackAnalyticsEvent,
    segmentResetAnonymousId,
  } = useAnalyticsContext();

  const afterSignOut = () => {
    trackAnalyticsEvent('Log Out', {
      email: user?.email,
      user: user?.id,
    });

    segmentResetAnonymousId();

    // We need some time to track Segment events before refreshing the page
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const { navLoggedOutConfig, navLoggedInConfig, navSharedConfig } = navConfig({
    intl,
    signOut,
    afterSignOut,
    user,
  });

  const loggedInOrOutConfig =
    loggedIn || loggedInProp ? navLoggedInConfig : navLoggedOutConfig;

  if (isLandingPage) {
    return [...loggedInOrOutConfig];
  }

  return [...navSharedConfig, ...loggedInOrOutConfig];
}
