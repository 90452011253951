import { useMutation } from 'react-apollo';

import { DeleteVideoData, UpdateVideoData } from 'app/typings/videos';
import { DELETE_VIDEO, UPDATE_VIDEO } from 'app/admin/graphql/videos/mutations';

export const DeleteVideo = () => {
  return useMutation<DeleteVideoData>(DELETE_VIDEO)[0];
};

export const UpdateVideo = () => {
  return useMutation<UpdateVideoData>(UPDATE_VIDEO)[0];
};
