import { User } from 'app/typings/users';
import { buildFullName } from 'app/shared/utils/string';

export const userFullNameWithEmail = (user: User | undefined) =>
  user &&
  `${buildFullName(user.firstName, user.lastName) || 'No name'} (${
    user.email
  })`;

export const userFullNameOrEmail = (user: User | undefined) =>
  user && `${buildFullName(user.firstName, user.lastName) || user.email}`;

export const HRIS_SYSTEMS = {
  justworks: 'JustWorks',
  handle: 'Handle',
  wagepoint: 'Wagepoint',
};

export const roleResourceTypes = [
  'CuratorGroup',
  'City',
  'BusinessOwner',
  'None',
];

export const roleResourceTypeLabels = {
  CuratorGroup: 'Curator Group',
  City: 'City',
  BusinessOwner: 'Business Owner',
};

// Order is significant, this list is used for sorting
export const curatorPrimaryRoleKeys = [
  'curator_primary',
  'curator_editor',
  'curator_member',
  'curator_day_of_show',
];

export const curatorEditablePrimaryRoleKeys = [
  'curator_editor',
  'curator_member',
  'curator_day_of_show',
];
