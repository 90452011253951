import { useContext } from 'react';

import { GetCuratorGroupsData } from 'app/typings/curatorGroups';
import { get } from 'app/shared/utils/get';
import { PermissionsContext } from 'app/shared/context/Permissions';

const curatorGroupIdsWithPermission = (
  permissions: any[],
  permissionName: string
) =>
  permissions
    .filter(
      (p: any) => p.name === permissionName && p.resourceType === 'CuratorGroup'
    )
    .map((p: any) => Number(p.resourceId));

export const fieldsForCuratorGroups = (
  dataCuratorGroups: GetCuratorGroupsData | undefined,
  curatorGroupIds: number[],
  fieldName: string
) => {
  if (curatorGroupIds) {
    return get(dataCuratorGroups, 'curatorGroups.curatorGroups', [])
      .filter((curatorGroup: any) => curatorGroupIds.includes(curatorGroup.id))
      .map((curatorGroup: any) => get(curatorGroup, fieldName, undefined));
  }
  return [];
};

const CuratorGroupPermissionInfo = (permissionName: string) => {
  const permissionsContext = useContext(PermissionsContext);
  const hasByCuratorGroupPermission = permissionsContext.hasPermission(
    permissionName
  );

  let curatorGroupIdsToLimitBy: number[] | undefined = undefined;

  if (
    hasByCuratorGroupPermission &&
    permissionsContext &&
    permissionsContext.permissions
  ) {
    curatorGroupIdsToLimitBy = curatorGroupIdsWithPermission(
      permissionsContext && permissionsContext.permissions,
      permissionName
    );
  }

  return {
    hasByCuratorGroupPermission,
    curatorGroupIdsToLimitBy,
  };
};

export const CuratorGroupInfoForLimitingByCuratorGroup = (
  permissionName: string,
  dataCuratorGroups?: GetCuratorGroupsData
) => {
  const {
    hasByCuratorGroupPermission,
    curatorGroupIdsToLimitBy,
  } = CuratorGroupPermissionInfo(permissionName);

  let curatorGroupNamesToLimitBy: string[] | undefined = undefined;

  if (
    dataCuratorGroups &&
    curatorGroupIdsToLimitBy &&
    curatorGroupIdsToLimitBy.length > 0
  ) {
    curatorGroupNamesToLimitBy = fieldsForCuratorGroups(
      dataCuratorGroups,
      curatorGroupIdsToLimitBy,
      'name'
    );
  }

  return {
    hasByCuratorGroupPermission,
    curatorGroupIdsToLimitBy,
    curatorGroupNamesToLimitBy,
  };
};

export const HasPermissionForCuratorGroup = (
  curatorGroupId: number | undefined,
  permissionName: string
) => {
  const permissionsContext = useContext(PermissionsContext);
  const hasByCuratorGroupPermission = permissionsContext.hasPermission(
    permissionName
  );

  let canAccess = true;

  if (
    hasByCuratorGroupPermission &&
    permissionsContext &&
    permissionsContext.permissions &&
    curatorGroupId
  ) {
    const curatorGroupIdsToLimitBy = curatorGroupIdsWithPermission(
      permissionsContext && permissionsContext.permissions,
      permissionName
    );
    canAccess = curatorGroupIdsToLimitBy.includes(curatorGroupId);
  }

  return canAccess;
};

export const HasACommercialCuratorGroup = () => {
  const permissionsContext = useContext(PermissionsContext);

  let hasAGroup = false;

  if (permissionsContext && permissionsContext.permissions) {
    hasAGroup = permissionsContext.permissions
      .filter((p: any) => p.resourceType === 'CuratorGroup')
      .some(
        (p: any) =>
          p.resourceExtraData &&
          p.resourceExtraData.curatorGroupType == 'commercial'
      );
  }

  return hasAGroup;
};
