import gql from 'graphql-tag';

export const UPDATE_PERMISSIONS = gql`
  mutation updatePermissions(
    $roleKey: String
    $abilityKey: String
    $roleKeysForAbilityKey: [String!]
    $abilityKeysForRoleKey: [String!]
  ) {
    updatePermissions(
      roleKey: $roleKey
      abilityKey: $abilityKey
      roleKeysForAbilityKey: $roleKeysForAbilityKey
      abilityKeysForRoleKey: $abilityKeysForRoleKey
    ) {
      success
    }
  }
`;
