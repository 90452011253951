import { useQuery } from 'react-apollo';

import {
  GetCountriesFullInfoData,
  GetCountriesFullInfoVariables,
  GetCountryFullInfoData,
  GetCountryFullInfoVariables,
} from 'app/typings/countries';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_COUNTRIES_FULL_INFO,
  GET_COUNTRY_FULL_INFO,
} from 'app/admin/graphql/countries/queries';

export const GetCountriesFullInfo = (
  params: GetCountriesFullInfoVariables = {}
) => {
  return useQuery<GetCountriesFullInfoData>(
    GET_COUNTRIES_FULL_INFO,
    queryParams(params)
  );
};

export const GetCountryFullInfo = (
  params: GetCountryFullInfoVariables = {}
) => {
  return useQuery<GetCountryFullInfoData>(
    GET_COUNTRY_FULL_INFO,
    queryParams(params)
  );
};
