import { KeyValue, SharedGetDataParameters } from 'app/typings/generics';
import { User } from 'app/typings/users';

export interface GetFeedbacksVariables extends SharedGetDataParameters {
  feedbackSubjectId?: number;
  feedbackSubjectType?: string;
  page?: number;
  perPage?: number;
}

export interface GetFeedbacksData {
  feedbacks: Feedback[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GetFeedbackPhrasesVariables extends SharedGetDataParameters {}

export interface GetFeedbackPhrasesData {
  feedbackPhrases: FeedbackPhrase[];
}

export interface CreateArtistFeedbackData {
  success: boolean;
  feedback: Feedback;
}

export interface CreateEventFeedbackData {
  success: boolean;
}

export interface FeedbacksInfo {
  feedbacks: Feedback[];
  metadata: {
    totalRecords: number;
    currentPage: number;
  };
}

export interface Feedback {
  id: number;
  feedbackSubjectId: number;
  feedbackSubjectType: string;
  feedbackContext: string;
  feedbackPieces: FeedbackPiece[];
  overallFeeling: string;
  feedbackableAt: string;
  feedbackableRelatedData: KeyValue[];
  createdBy: User | undefined;
  createdAt: string;
}

export interface FeedbackPiece {
  id: number;
  feedbackPhrase: FeedbackPhrase;
}

export interface FeedbackPhrase {
  id: number;
  phrase: string;
  isPositive: boolean;
}

export interface DeleteFeedbackData {
  success: boolean;
}

export interface TopFeedbackPhrase extends FeedbackPhrase {
  percentage?: number;
}

export enum OverallFeeling {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
}
