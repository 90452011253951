import { useQuery } from 'react-apollo';

import {
  GetPrimaryRolesData,
  GetPrimaryRolesFullInfoData,
  GetPrimaryRolesFullInfoVariables,
  GetPrimaryRolesVariables,
} from 'app/typings/roles';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_PRIMARY_ROLES,
  GET_PRIMARY_ROLES_FULL_INFO,
} from 'app/admin/graphql/roles/queries';

export const GetPrimaryRoles = (params: GetPrimaryRolesVariables = {}) => {
  return useQuery<GetPrimaryRolesData>(GET_PRIMARY_ROLES, queryParams(params));
};

export const GetPrimaryRolesFullInfo = (
  params: GetPrimaryRolesFullInfoVariables = {}
) => {
  return useQuery<GetPrimaryRolesFullInfoData>(
    GET_PRIMARY_ROLES_FULL_INFO,
    queryParams(params)
  );
};
