import React, { CSSProperties } from 'react';
import { Helmet } from 'react-helmet-async';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { WithNavbarPaddingProps } from 'app/shared/components/styleUtils/manualCSS';
import ErrorBoundary from 'app/shared/components/atoms/ErrorBoundary';
import { GlobalError } from 'app/shared/components/pages/Status';
import Footer from 'app/admin/components/organisms/FooterManualCSS';
import Navbar, {
  NavbarProps,
} from 'app/admin/components/organisms/NavbarManualCSS';

interface Props {
  children: any;
  noNavbarSpacing?: boolean;
  noNavbarMargin?: boolean;
  navbarProps?: NavbarProps;
  'data-qaid'?: string;
  centerContent?: boolean;
  scrollDisabled?: boolean;
}

interface CenterProp {
  centerContent?: boolean;
  noNavbarMargin?: boolean;
  children: any;
  'data-qaid'?: string;
}

interface ScrollProp {
  scrollDisabled: boolean;
}

const Layout = styled.div<WithNavbarPaddingProps & ScrollProp>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;

  ${props =>
    props.scrollDisabled &&
    css`
      overflow-y: hidden;
      height: 100%;
      width: 100%;
      position: fixed;
    `}
`;

const CenterContainer = styled.div<CenterProp>`
  margin-top: 128px;
  flex: 1;

  ${({ centerContent }) =>
    centerContent &&
    css`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    `}

  ${({ theme, noNavbarMargin }) =>
    noNavbarMargin &&
    css`
      ${theme.media.xs`
         margin-top: ${theme.dimensions.navbarHeight.xs};
      `};

      ${theme.media.md`
         margin-top: ${theme.dimensions.navbarHeight.md};
      `};

      ${theme.media.lg`
         margin-top: ${theme.dimensions.navbarHeight.lg};
      `};
    `}
`;

const defaultNavbarProps = (theme: any) => ({
  backgroundColor: theme.colors.green800,
  position: 'fixed' as CSSProperties['position'],
  isAdmin: true,
  suppressAllLinks: false,
  useResponsiveHeight: false,
});

const AdminLayout: React.FC<Props> = ({
  children,
  navbarProps = {},
  centerContent,
  noNavbarSpacing = true,
  noNavbarMargin = false,
  'data-qaid': dataQaid = 'main-layout',
  scrollDisabled = false,
  ...rest
}) => {
  const theme = useCurrentTheme();

  const navProps = {
    ...defaultNavbarProps(theme),
    ...navbarProps,
  };

  return (
    <Layout
      data-qaid={dataQaid}
      stickToNavbar={noNavbarSpacing}
      scrollDisabled={scrollDisabled}
      {...rest}
    >
      <Navbar {...navProps} />
      <Helmet>
        <link
          rel="preload"
          href="/src/fonts/sofarsounds-icon-font.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="true"
        />
      </Helmet>
      <CenterContainer
        centerContent={centerContent}
        noNavbarMargin={noNavbarMargin}
      >
        {children}
      </CenterContainer>
      <Footer />
    </Layout>
  );
};

const GlobalErrorComponent = () => {
  return <GlobalError noLayout={true} />;
};

export default (props: Props) => {
  return (
    <ErrorBoundary ErrorComponent={GlobalErrorComponent}>
      <AdminLayout {...props} />
    </ErrorBoundary>
  );
};
