import gql from 'graphql-tag';

export const DISABLE_VISA_PRESALE = gql`
  mutation disableVisaPresale($eventId: Int!) {
    disableVisaPresale(eventId: $eventId) {
      success
      event {
        id
        onPresale
      }
    }
  }
`;
