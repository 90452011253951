import { useMutation } from 'react-apollo';

import { UPDATE_PERMISSIONS } from 'app/admin/graphql/permissions/mutations';

interface UpdatePermissionsData {
  success: boolean;
}

export const UpdatePermissions = () => {
  return useMutation<UpdatePermissionsData>(UPDATE_PERMISSIONS)[0];
};
