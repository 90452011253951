import {
  Cities as GetCitiesDataResponse,
  GetAgenciesData,
  GetArtistEligibilitiesData,
  GetCampaignsData,
  GetCitiesData,
  GetCuratorGroupsData,
  GetEmailTypesData,
  GetEventCollectionsData,
  GetGenerationReasonsData,
  GetNeighborhoodsData,
  GetPartnersData,
  GetPrimaryRolesData,
  GetPromoCodeTypesData,
  GetPropertiesData,
  GetTagsData,
  GetThemesData,
  GetUserAbilitiesData,
  GetVersionItemTypesData,
  Neighborhood,
} from 'app/typings';
import { get } from 'app/shared/utils/get';
import { roleResourceTypeLabels } from 'app/admin/utils/users';

export const getObjectOptions = (obj: object) => {
  return Object.entries(obj).map(([key, label]) => ({
    title: label,
    value: key,
  }));
};

export const getObjectOptionsPreservingOrder = (
  obj: object,
  keys: string[]
) => {
  return keys
    .filter((key: string) => !!obj[key])
    .map((key: string) => ({
      title: obj[key],
      value: key,
    }));
};

export const optionsWithNoneOption = (objList: any) =>
  [{ id: 0, title: 'None' }].concat(objList);

export const labelValueOptionsWithNoneOption = (objList: any) =>
  [{ value: '', label: 'None' }].concat(objList);

export const titleValueOptionsWithNoneOption = (objList: any) =>
  [{ value: null, title: 'None' }].concat(objList);

export const filterRadioOptionsBasedOnPermissions = (
  optionsList: any[],
  keysAndBooleansToCheck: object
) =>
  optionsList.filter(
    obj =>
      // @ts-ignore
      keysAndBooleansToCheck[obj.value]
  );

export const getCityOptions = (
  dataCities: GetCitiesDataResponse | GetCitiesData | undefined
) => {
  let options =
    get(dataCities, 'cities.cities', undefined) ||
    get(dataCities, 'cities', []);
  options = options.map((city: any) => ({
    title: city.title,
    value: city.cachedSlug,
    fields: {
      country: city.country.title,
    },
  }));
  return options;
};

export const getCityWithBusinessOwnerOptions = (
  dataCities: GetCitiesDataResponse | GetCitiesData | undefined
) => {
  let options =
    get(dataCities, 'cities.cities', undefined) ||
    get(dataCities, 'cities', []);
  options = options.map((city: any) => ({
    title: city.title,
    value: city.cachedSlug,
    fields: {
      country: city.country.title,
      businessOwnerGroupName: city.businessOwner && city.businessOwner.title,
      businessOwnerCachedSlug:
        city.businessOwner && city.businessOwner.cachedSlug,
    },
  }));
  return options;
};

export const getCountryOptions = (
  dataCountries: any | undefined,
  countryIds?: number[]
) => {
  let options = get(dataCountries, 'countries.countries', []);
  if (countryIds) {
    options = options.filter((country: any) => countryIds.includes(country.id));
  }
  options = options.map((country: any) => ({
    title: country.title,
    value: country.cachedSlug,
  }));
  return options;
};

export const getBusinessOwnerOptions = (
  dataBusinessOwners: any | undefined,
  businessOwnerIds?: number[]
) => {
  let options = get(dataBusinessOwners, 'businessOwners.businessOwners', []);
  if (businessOwnerIds) {
    options = options.filter((businessOwner: any) =>
      businessOwnerIds.includes(businessOwner.id)
    );
  }
  options = options.map((businessOwner: any) => ({
    title: businessOwner.title,
    value: businessOwner.cachedSlug,
  }));
  return options;
};

export const getContinentOptions = (dataContinents: any | undefined) => {
  let options = get(dataContinents, 'continents.continents', []);
  options = options.map((continent: any) => ({
    title: continent.title,
    value: continent.cachedSlug,
  }));
  return options;
};

export const getTagOptions = (
  dataTags: GetTagsData | undefined,
  idAsInt?: boolean,
  displayIsNotVisibleToFans?: boolean
) => {
  return get(dataTags, 'tags.tags', []).map((tag: any) => ({
    title:
      displayIsNotVisibleToFans && !tag.isVisibleToFans
        ? `${tag.name}*`
        : tag.name,
    value: idAsInt ? tag.id : tag.id.toString(),
    fields: {
      tagGroupName: tag.tagGroupName,
    },
  }));
};

export const getPropertyOptions = (
  dataProperties: GetPropertiesData | undefined,
  idAsInt?: boolean
) => {
  return get(dataProperties, 'properties.properties', []).map(
    (property: any) => ({
      title: property.name,
      value: idAsInt ? property.id : property.id.toString(),
      key: property.key,
      fields: {
        propertyGroupName: property.propertyGroupName,
      },
      iconName: property.iconName,
    })
  );
};

export const getThemeOptions = (dataThemes: GetThemesData | undefined) => {
  return get(dataThemes, 'themes.themes', []).map((theme: any) => ({
    title: theme.title,
    value: theme.id.toString(),
  }));
};

export const getEventCollectionOptions = (
  dataEventCollections: GetEventCollectionsData | undefined
) => {
  return (dataEventCollections?.eventCollections?.eventCollections || []).map(
    (eventCollection: any) => ({
      title: eventCollection.title,
      value: eventCollection.cachedSlug,
    })
  );
};

export const getPrimaryRoleOptions = (
  dataPrimaryRoles: GetPrimaryRolesData | undefined,
  resourceTypes?: string[],
  namePrefix?: string
) => {
  let options = get(dataPrimaryRoles, 'primaryRoles.primaryRoles', []);
  if (resourceTypes) {
    options = options.filter((primaryRole: any) =>
      resourceTypes.includes(primaryRole.resourceType || 'None')
    );
  }
  options = options.map((primaryRole: any) => ({
    title:
      primaryRole.resourceType === 'Event'
        ? namePrefix
          ? `${namePrefix} ${primaryRole.name}`
          : primaryRole.name
        : primaryRole.resourceType
        ? `${primaryRole.name} (By ${roleResourceTypeLabels[
            primaryRole.resourceType
          ] || primaryRole.resourceType})`
        : primaryRole.name,
    value: primaryRole.key,
  }));
  return options;
};

export const getCuratorGroupOptions = (
  dataCuratorGroups: GetCuratorGroupsData | undefined
) => {
  return get(dataCuratorGroups, 'curatorGroups.curatorGroups', []).map(
    (curatorGroup: any) => ({
      title: curatorGroup.name,
      value: curatorGroup.id.toString(),
    })
  );
};

export const getUserAbilityOptions = (
  dataUserAbilities: GetUserAbilitiesData | undefined
) => {
  return get(dataUserAbilities, 'userAbilities.abilities', []).map(
    (userAbility: any) => ({
      title: userAbility.name,
      value: userAbility.key,
      contextName: userAbility.contextName,
    })
  );
};

export const getEmailTypeOptions = (
  dataEmailTypes: GetEmailTypesData | undefined
) => {
  return get(dataEmailTypes, 'emailTypes.emailTypes', []).map((email: any) => ({
    title: email.name,
    value: email.key,
  }));
};

export const getEmailMessageStatusOptions = (statusLabels: object) =>
  getObjectOptions(statusLabels);

export const getArtistEligibilityOptions = (
  dataArtistEligibilities: GetArtistEligibilitiesData | undefined
) => {
  return get(
    dataArtistEligibilities,
    'artistEligibilities.artistEligibilities',
    []
  ).map((artistEligibility: any) => ({
    title: artistEligibility.name,
    value: artistEligibility.key,
  }));
};

export const getNeighborhoodOptions = (
  dataNeighborhoods: GetNeighborhoodsData | undefined,
  cityIds?: number[]
) => {
  return get(dataNeighborhoods, 'neighborhoods.neighborhoods', [])
    .filter((neighborhood: Neighborhood) =>
      cityIds && neighborhood?.city?.id
        ? cityIds.includes(neighborhood?.city?.id)
        : true
    )
    .map((neighborhood: Neighborhood) => ({
      title: neighborhood.title,
      value: neighborhood.cachedSlug,
      fields: {
        cityGroupName: neighborhood?.city?.title,
        cityCachedSlug: neighborhood?.city?.cachedSlug,
      },
    }))
    .sort((a: any, b: any) =>
      a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
    );
};

export const getNeighborhoodOptionsSimple = (
  neighborhoods: Neighborhood[] | undefined
) => {
  return (neighborhoods || []).map((neighborhood: any) => ({
    title: neighborhood.title,
    value: neighborhood.cachedSlug,
  }));
};

export const getPartnerOptions = (
  dataPartners: GetPartnersData | undefined,
  idAsInt?: boolean
) => {
  return get(dataPartners, 'partners.partners', []).map((partner: any) => ({
    title: partner.title,
    value: idAsInt ? partner.id : partner.id.toString(),
  }));
};

export const getAgencyOptions = (
  dataAgencies: GetAgenciesData | undefined,
  idAsInt?: boolean
) => {
  return get(dataAgencies, 'agencies.agencies', []).map((agency: any) => ({
    title: agency.title,
    value: idAsInt ? agency.id : agency.id.toString(),
  }));
};

export const getGenerationReasonOptions = (
  dataGenerationReasons: GetGenerationReasonsData | undefined
) => {
  return get(
    dataGenerationReasons,
    'generationReasons.generationReasons',
    []
  ).map((generationReason: any) => ({
    title: generationReason.description,
    value: generationReason.id,
  }));
};

export const getPromoCodeTypeOptions = (
  dataPromoCodeTypes: GetPromoCodeTypesData | undefined
) => {
  return get(dataPromoCodeTypes, 'promoCodeTypes.promoCodeTypes', []).map(
    (promoCodeType: any) => ({
      title: promoCodeType.name,
      value: promoCodeType.key,
      fields: {
        typeGroupName: promoCodeType.typeGroupName,
      },
    })
  );
};

export const getCampaignOptions = (
  dataCampaigns: GetCampaignsData | undefined
) => {
  return get(dataCampaigns, 'campaigns.campaigns', []).map((campaign: any) => ({
    title: campaign.title,
    value: campaign.id,
  }));
};

export const getCompetitionTemplatesOptions = (
  dataCompetitionTemplates: any | undefined
) => {
  return get(
    dataCompetitionTemplates,
    'competitionTemplates.competitionTemplates',
    []
  ).map((competitionTemplate: any) => ({
    title: competitionTemplate.templateName,
    value: competitionTemplate.id,
  }));
};

export const getSeatingOptions = (seatings: object) => {
  return Object.keys(seatings)
    .reverse()
    .map((key: string) => ({
      title: seatings[key],
      value: key,
    }));
};

export const getEventOrganizedAsOptions = (eventOrganizedAsLabels: object) =>
  getObjectOptions(eventOrganizedAsLabels);

export const getBookingSettingsOptions = (
  bookingSettingsLabelsInGroups: any
) => {
  let options = [];
  for (const bookingSettingsGroup of Object.keys(
    bookingSettingsLabelsInGroups
  )) {
    for (const fieldKey of Object.keys(
      bookingSettingsLabelsInGroups[bookingSettingsGroup]
    )) {
      options.push({
        title: bookingSettingsLabelsInGroups[bookingSettingsGroup][fieldKey],
        value: fieldKey,
        fields: {
          bookingSettingsGroup,
        },
      });
    }
  }
  return options;
};

const getFilterOptionsFromFilterState = (
  filterState: object,
  filterName: string
) =>
  filterState[filterName].map((opt: string) => {
    const displayValue = opt.replace(/^\d+-/, '');
    return {
      title: displayValue,
      value: opt,
    };
  });

export const getFilterNamesAndOptionsForTitleMapping = (
  filterDropdownOptionsInfoList: object[],
  filterState?: any
) =>
  filterDropdownOptionsInfoList.reduce((newObj, obj) => {
    // @ts-ignore
    if (obj.dropdownParams.typeaheadFilterComponent && filterState) {
      // @ts-ignore
      newObj[obj.filterName] = getFilterOptionsFromFilterState(
        filterState,
        // @ts-ignore
        obj.filterName
      );
    } else {
      // @ts-ignore
      newObj[obj.filterName] = obj.dropdownParams.options;
    }
    return newObj;
  }, {});

export const filterInfoListFilteredByCheckOnFilterName = (
  infoList: any[],
  keysAndBooleansToCheck: object
) =>
  infoList.filter(
    obj =>
      // @ts-ignore
      !(obj.filterName in keysAndBooleansToCheck) ||
      // @ts-ignore
      keysAndBooleansToCheck[obj.filterName]
  );

export const getMaxPaInputsOptions = (intl: any) => {
  return [
    {
      title: intl.formatMessage({ id: 'unknown' }),
      value: 'unknown',
    },
    {
      title: intl.formatMessage({ id: 'admin.shared.paOptions.acoustic' }),
      value: 'acoustic',
    },
    {
      title: intl.formatMessage({ id: 'admin.shared.paOptions.2ch' }),
      value: 'two_ch',
    },
    {
      title: intl.formatMessage({ id: 'admin.shared.paOptions.10ch' }),
      value: 'ten_ch',
    },
  ];
};

export const getVersionItemTypeOptions = (
  dataVersionItemTypes: GetVersionItemTypesData | undefined
) => {
  return get(dataVersionItemTypes, 'versionItemTypes.versionItemTypes', []).map(
    (value: string) => ({
      title: value,
      value,
    })
  );
};
