import gql from 'graphql-tag';

export const GET_GENERATION_REASONS = gql`
  query GetGenerationReasons {
    generationReasons {
      generationReasons {
        id
        description
      }
    }
  }
`;

export const GET_PROMO_CODE = gql`
  query GetPromoCode($id: Int!) {
    promoCode(id: $id) {
      id
      code
      type
      typeName
      validFrom
      expiresAt
      maxUses
      maxUsesPerUser
      rules {
        id
        ruleType
        ruleDayOfWeek
        ruleDaysOfWeek
        ruleCity {
          id
          title
        }
        ruleCities {
          id
          title
        }
        ruleCountry {
          id
          title
        }
        ruleEvent {
          id
          localStartsAt
          city {
            id
            title
          }
        }
        ruleEventType
        ruleEventOwnerTypes
        rulePurchaserType
      }
      campaign {
        id
        title
      }
      generationReason {
        id
        description
      }
      percentageDiscount
      customPrices {
        numTickets
        price
      }
      numTimesUsed
      numTicketsPurchased
      orders {
        id
        hasPreviousOrders
        createdAt
        eventAttendee {
          id
          event {
            id
            localStartsAt
            city {
              id
              title
              timezone
            }
          }
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
      status
      createdBy {
        id
        firstName
        lastName
        email
      }
      curatorGroup {
        id
        name
      }
      createdAt
    }
  }
`;

export const GET_PROMO_CODES = gql`
  query GetPromoCodes(
    $promoCodeSearch: String
    $typeGroup: String
    $type: String
    $status: String
    $expiresAt: String
    $generationReason: String
    $campaign: String
    $curatorGroup: String
    $createdById: Int
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
  ) {
    promoCodes(
      promoCodeSearch: $promoCodeSearch
      typeGroup: $typeGroup
      type: $type
      status: $status
      expiresAt: $expiresAt
      generationReason: $generationReason
      campaign: $campaign
      curatorGroup: $curatorGroup
      createdById: $createdById
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      promoCodes {
        id
        code
        type
        typeName
        validFrom
        expiresAt
        campaign {
          id
          title
        }
        generationReason {
          id
          description
        }
        numTimesUsed
        numTicketsPurchased
        status
        createdBy {
          id
          firstName
          lastName
          email
        }
        createdAt
      }
      metadata {
        currentPage
        totalRecords
      }
    }
  }
`;

export const GET_PROMO_CODE_TYPES = gql`
  query GetPromoCodeTypes {
    promoCodeTypes {
      promoCodeTypes {
        key
        name
        typeGroupName
      }
      promoCodeTypeGroupNames
    }
  }
`;
