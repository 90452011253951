import { useQuery } from 'react-apollo';

import {
  GetVenueBasicInfoData,
  GetVenueBasicInfoVariables,
  GetVenueEventsData,
  GetVenueEventsVariables,
  GetVenuesData,
  GetVenuesVariables,
} from 'app/typings/venues';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_VENUE_BASIC_INFO,
  GET_VENUE_EVENTS_PAST,
  GET_VENUE_EVENTS_UPCOMING,
  GET_VENUES,
  GET_VENUES_FOR_MAP,
  GET_VENUES_FOR_VENUE_TYPEAHEAD,
} from 'app/admin/graphql/venues/queries';

const liteQueryParams = (params: any) =>
  Object.assign(params, { isLiteQuery: true });

export const GetVenueBasicInfo = (params: GetVenueBasicInfoVariables = {}) => {
  return useQuery<GetVenueBasicInfoData>(
    GET_VENUE_BASIC_INFO,
    queryParams(params)
  );
};

export const GetVenueEvents = (params: GetVenueEventsVariables = {}) => {
  const query =
    params.scope === 'upcoming'
      ? GET_VENUE_EVENTS_UPCOMING
      : GET_VENUE_EVENTS_PAST;
  return useQuery<GetVenueEventsData>(query, queryParams(params));
};

export const GetVenues = (params: GetVenuesVariables = {}) => {
  return useQuery<GetVenuesData>(GET_VENUES, queryParams(params));
};

export const GetVenuesForMap = (params: GetVenuesVariables = {}) => {
  return useQuery<GetVenuesData>(GET_VENUES_FOR_MAP, queryParams(params));
};

export const GetVenuesForVenueTypeahead = (params: GetVenuesVariables = {}) => {
  return useQuery<GetVenuesData>(
    GET_VENUES_FOR_VENUE_TYPEAHEAD,
    queryParams(liteQueryParams(params))
  );
};
