import gql from 'graphql-tag';

export const GET_VERSION = gql`
  query GetVersion($id: Int!) {
    version(id: $id) {
      version {
        id
        itemType
        itemId
        event
        whodunnit {
          id
          firstName
          lastName
          email
        }
        versionTo
        versionFrom
        changeset
      }
    }
  }
`;

export const GET_VERSION_ITEM_TYPES = gql`
  query GetVersionItemTypes {
    versionItemTypes {
      versionItemTypes
    }
  }
`;

export const GET_VERSIONS = gql`
  query GetVersions(
    $versionSearch: String
    $itemType: String
    $event: String
    $date: String
    $orderBy: String
    $orderDirection: String
    $perPage: Int
    $page: Int
  ) {
    versions(
      versionSearch: $versionSearch
      itemType: $itemType
      event: $event
      date: $date
      orderBy: $orderBy
      orderDirection: $orderDirection
      perPage: $perPage
      page: $page
    ) {
      versions {
        id
        itemType
        itemId
        event
        whodunnit {
          id
          firstName
          lastName
          email
        }
        createdAt
        changeset
      }
      metadata {
        totalRecords
      }
    }
  }
`;
