import gql from 'graphql-tag';

export const GET_TAGS = gql`
  query GetTags($tagContext: String!) {
    tags(tagContext: $tagContext) {
      tags {
        id
        name
        isVisibleToFans
        tagGroupName
      }
      tagGroupNames
    }
  }
`;
