import { useQuery } from 'react-apollo';

import {
  GetPartnersData,
  GetPartnersFullInfoData,
  GetPartnersFullInfoVariables,
  GetPartnersVariables,
} from 'app/typings/partners';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_PARTNERS,
  GET_PARTNERS_FULL_INFO,
} from 'app/admin/graphql/partners/queries';

export const GetPartners = (params: GetPartnersVariables = {}) => {
  return useQuery<GetPartnersData>(GET_PARTNERS, queryParams(params));
};

export const GetPartnersFullInfo = (
  params: GetPartnersFullInfoVariables = {}
) => {
  return useQuery<GetPartnersFullInfoData>(
    GET_PARTNERS_FULL_INFO,
    queryParams(params)
  );
};
