import gql from 'graphql-tag';

import { artistSocialUrls } from 'app/shared/utils/socialLinks';

export const GET_ARTIST_APPLICATION = gql`
  query GetArtistApplication($id: Int!) {
    artistApplication(id: $id) {
      id
      city {
        id
        title
        cachedSlug
        cityOperatingModel
      }
      artistName
      homeCityDescription
      emailAddress
      portfolioUrl1
      portfolioUrl2
      portfolioUrl3
      decisionMadeAt
      decisionMadeByUser {
        id
        firstName
        lastName
      }
      referrer {
        id
        firstName
        lastName
      }
      createdAt
      customEmailMessage
      status
      isAvailableLastMinute
      artistSetups {
        id
        setupName
        numPerformers
        isPrimarySetup
        audioSupport
        additionalSupportNotes
        instrumentNotes
        setuppableId
        setuppableType
        lastUpdatedAt
        createdAt
        derivedFrom
      }
      socialUrls {
        ${artistSocialUrls.join(' ')}
      }
    }
  }
`;

export const GET_ARTIST_APPLICATIONS = gql`
  query GetArtistApplications(
    $cityOfApplication: String
    $cityIdsOfApplication: String
    $closestSofarCity: String
    $createdDate: String
    $businessOwner: String
    $status: String
    $artistApplicationSearch: String
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
  ) {
    artistApplications(
      cityOfApplication: $cityOfApplication
      cityIdsOfApplication: $cityIdsOfApplication
      closestSofarCity: $closestSofarCity
      createdDate: $createdDate
      businessOwner: $businessOwner
      status: $status
      artistApplicationSearch: $artistApplicationSearch
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      artistApplications {
        id
        city {
          id
          title
        }
        artistName
        homeCityDescription
        emailAddress
        portfolioUrl1
        portfolioUrl2
        portfolioUrl3
        referrer {
          id
          firstName
          lastName
        }
        artist {
          id
        }
        createdAt
        status
      }
      metadata {
        totalRecords
      }
    }
  }
`;
