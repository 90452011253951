import { useQuery } from 'react-apollo';

import { CancellationReason } from 'app/typings/cancellationReasons';
import { SharedGetDataParameters } from 'app/typings/generics';
import { queryParams } from 'app/shared/utils/queries';
import { GET_CANCELLATION_REASONS } from 'app/admin/graphql/cancellationReasons/queries';

interface GetCancellationReasonsVariables extends SharedGetDataParameters {
  cancellableType: string;
}

interface GetCancellationReasonsData {
  cancellationReasons: CancellationReason[];
}

export const GetCancellationReasons = (
  params: GetCancellationReasonsVariables
) => {
  return useQuery<GetCancellationReasonsData>(
    GET_CANCELLATION_REASONS,
    queryParams(params)
  );
};
