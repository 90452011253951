export const promoCodeStatusLabels = (intl: any) => ({
  pending_start: intl.formatMessage({
    id: 'admin.shared.promoCodeStatus.pendingStart',
  }),
  active: intl.formatMessage({
    id: 'shared.active',
  }),
  cancelled: intl.formatMessage({
    id: 'admin.shared.promoCodeStatus.cancelled',
  }),
  expired: intl.formatMessage({
    id: 'admin.shared.promoCodeStatus.expired',
  }),
});
