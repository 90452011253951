import gql from 'graphql-tag';

export const CANCEL_COMPETITION = gql`
  mutation cancelCompetition($id: Int!) {
    cancelCompetition(id: $id) {
      success
      competition {
        id
        status
      }
    }
  }
`;

export const CREATE_COMPETITION = gql`
  mutation createCompetition(
    $title: String
    $closesAt: String
    $uid: String
    $cityId: Int
    $eventId: Int
    $maxWinners: Int
    $competitionTemplateId: Int
  ) {
    createCompetition(
      title: $title
      closesAt: $closesAt
      uid: $uid
      cityId: $cityId
      eventId: $eventId
      maxWinners: $maxWinners
      competitionTemplateId: $competitionTemplateId
    ) {
      success
      competition {
        id
        title
        closesAt
        status
        uid
        city {
          id
          title
        }
        event {
          id
          localStartsAt
        }
        creator {
          id
          firstName
          lastName
          email
        }
        createdAt
        entriesCount
      }
    }
  }
`;

export const UPDATE_COMPETITION = gql`
  mutation updateCompetition(
    $competitionId: Int!
    $title: String
    $closesAt: String
    $uid: String
    $cityId: Int
    $maxWinners: Int
    $intro: String
    $thirdPartyCtaText: String
    $thirdPartyCtaLink: String
    $subtitle: String
    $subtitleTwo: String
    $description: String
    $postDescription: String
    $winnerCopy: String
    $expiredCopy: String
    $moreInfoTitle: String
    $moreInfoSubheading: String
    $moreInfoPreview: String
    $featuredContentCtaText: String
    $featuredContentCtaLink: String
    $turnOnOptIn: Boolean
    $optInCopy: String
    $shareTitle: String
    $shareCopy: String
  ) {
    updateCompetition(
      competitionId: $competitionId
      title: $title
      closesAt: $closesAt
      uid: $uid
      cityId: $cityId
      maxWinners: $maxWinners
      intro: $intro
      thirdPartyCtaText: $thirdPartyCtaText
      thirdPartyCtaLink: $thirdPartyCtaLink
      subtitle: $subtitle
      subtitleTwo: $subtitleTwo
      description: $description
      postDescription: $postDescription
      winnerCopy: $winnerCopy
      expiredCopy: $expiredCopy
      moreInfoTitle: $moreInfoTitle
      moreInfoSubheading: $moreInfoSubheading
      moreInfoPreview: $moreInfoPreview
      featuredContentCtaText: $featuredContentCtaText
      featuredContentCtaLink: $featuredContentCtaLink
      turnOnOptIn: $turnOnOptIn
      optInCopy: $optInCopy
      shareTitle: $shareTitle
      shareCopy: $shareCopy
    ) {
      success
      competition {
        id
        title
        closesAt
        status
        uid
        city {
          id
          title
        }
        event {
          id
          localStartsAt
        }
        creator {
          id
          firstName
          lastName
          email
        }
        createdAt
        entriesCount
        winnersCount
        maxWinners
        entries {
          id
          currentState
          user {
            id
            firstName
            lastName
            email
          }
        }
        intro
        thirdPartyCtaText
        thirdPartyCtaLink
        backgroundImageUrl
        logoUrl
        subtitle
        subtitleTwo
        description
        postDescription
        winnerCopy
        expiredCopy
        moreInfoTitle
        moreInfoSubheading
        moreInfoPreview
        featuredContentCtaText
        featuredContentCtaLink
        moreInfoImageUrl
        turnOnOptIn
        optInCopy
        shareTitle
        shareCopy
        shareImageUrl
      }
    }
  }
`;
