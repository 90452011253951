import { useQuery } from 'react-apollo';

import {
  GetUserAbilitiesData,
  GetUserAbilitiesVariables,
} from 'app/typings/userAbilities';
import { queryParams } from 'app/shared/utils/queries';
import { GET_USER_ABILITIES } from 'app/admin/graphql/userAbilities/queries';

export const GetUserAbilities = (params: GetUserAbilitiesVariables = {}) => {
  return useQuery<GetUserAbilitiesData>(
    GET_USER_ABILITIES,
    queryParams(params)
  );
};
