import gql from 'graphql-tag';

export const ARCHIVE_CAMPAIGN = gql`
  mutation archiveCampaign($id: Int!) {
    archiveCampaign(id: $id) {
      success
      campaign {
        id
      }
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign(
    $title: String
    $partnerId: Int
    $agencyId: Int
    $accountManagerUserId: Int
    $salesRepUserId: Int
  ) {
    createCampaign(
      title: $title
      partnerId: $partnerId
      agencyId: $agencyId
      accountManagerUserId: $accountManagerUserId
      salesRepUserId: $salesRepUserId
    ) {
      success
      campaign {
        id
        title
      }
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign(
    $campaignId: Int!
    $title: String
    $partnerId: Int
    $agencyId: Int
    $accountManagerUserId: Int
    $salesRepUserId: Int
  ) {
    updateCampaign(
      campaignId: $campaignId
      title: $title
      partnerId: $partnerId
      agencyId: $agencyId
      accountManagerUserId: $accountManagerUserId
      salesRepUserId: $salesRepUserId
    ) {
      success
      campaign {
        id
        title
      }
    }
  }
`;
