import gql from 'graphql-tag';

export const GET_CAMPAIGNS = gql`
  query GetCampaigns(
    $partner: String
    $archivedStatus: String
    $skipPagination: Boolean
  ) {
    campaigns(
      partner: $partner
      archivedStatus: $archivedStatus
      skipPagination: $skipPagination
    ) {
      campaigns {
        id
        title
        partner {
          id
          title
        }
      }
    }
  }
`;

export const GET_CAMPAIGNS_FULL_INFO = gql`
  query GetCampaignsFullInfo(
    $campaignSearch: String
    $partner: String
    $agency: String
    $archivedStatus: String
    $orderBy: String
    $orderDirection: String
    $page: Int!
    $perPage: Int!
  ) {
    campaigns(
      campaignSearch: $campaignSearch
      partner: $partner
      agency: $agency
      archivedStatus: $archivedStatus
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      campaigns {
        id
        title
        isArchived
        partner {
          id
          title
        }
        agency {
          id
          title
        }
        accountManagerUser {
          id
          firstName
          lastName
          email
        }
        salesRepUser {
          id
          firstName
          lastName
          email
        }
        pastEventsWithCampaignCount
        upcomingEventsWithCampaignCount
        promoCodesWithCampaignCount
        createdAt
      }
      metadata {
        currentPage
        totalRecords
      }
    }
  }
`;
