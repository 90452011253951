import React, { useEffect, useState } from 'react';

import { CommonTheme } from 'app/shared/theme';
import { useCurrentTheme } from 'app/shared/theme';

interface Props {
  children: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  > | null;
  hide?: string;
  show?: string;
  hideUp?: string;
  showUp?: string;
  showOnSizes?: string[];
}

interface FindNextProps {
  keys: string[];
  key: string;
}

const hasWindow = typeof window !== 'undefined';

const findNext = ({ keys, key }: FindNextProps): string | null => {
  if (keys.indexOf(key) > -1) {
    const nextIndex = keys.indexOf(key) + 1;
    let next = keys[nextIndex];
    return next ? next : null;
  }

  return null;
};

export const Responsive: React.FC<Props> = ({
  children,
  hide,
  show,
  hideUp,
  showUp,
  showOnSizes,
}) => {
  const theme: CommonTheme = useCurrentTheme();
  const { breakPoints } = theme;
  const [windowSize, setWindowSize] = useState<number>(
    hasWindow ? window.innerWidth : 0
  );

  function handleResize() {
    setWindowSize(window.innerWidth);
  }

  useEffect(() => {
    if (hasWindow) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function isInBoundary(
    screenSize: string,
    windowSize: number,
    noLimit?: boolean
  ) {
    const minWidth = breakPoints[screenSize];
    const nextSize = findNext({
      keys: Object.keys(breakPoints),
      key: screenSize,
    });

    if (!nextSize || noLimit) {
      return windowSize >= minWidth;
    }

    const maxWidth = breakPoints[nextSize];

    return windowSize >= minWidth && windowSize < maxWidth;
  }

  if (showOnSizes) {
    const inAnyBoundary = showOnSizes
      .map((size: string) => isInBoundary(size, windowSize, false))
      .reduce((a, b) => a || b);
    return !inAnyBoundary ? null : children;
  }

  if (hide || hideUp) {
    const useHideProp = hide || hideUp;
    switch (useHideProp) {
      case 'mobile':
        return isInBoundary('mobile', windowSize, !hide) ? null : children;
      case 'tablet':
        return isInBoundary('tablet', windowSize, !hide) ? null : children;
      case 'smallDesktop':
        return isInBoundary('smallDesktop', windowSize, !hide)
          ? null
          : children;
      case 'largeDesktop':
        return isInBoundary('largeDesktop', windowSize, !hide)
          ? null
          : children;
      default:
        return children;
    }
  }

  if (show || showUp) {
    const useShowProp = show || showUp;
    switch (useShowProp) {
      case 'mobile':
        return !isInBoundary('mobile', windowSize, !show) ? null : children;
      case 'tablet':
        return !isInBoundary('tablet', windowSize, !show) ? null : children;
      case 'smallDesktop':
        return !isInBoundary('smallDesktop', windowSize, !show)
          ? null
          : children;
      case 'largeDesktop':
        return !isInBoundary('largeDesktop', windowSize, !show)
          ? null
          : children;
      default:
        return children;
    }
  }

  return children;
};
