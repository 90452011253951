import gql from 'graphql-tag';

export const CREATE_PERFORMANCE = gql`
  mutation createPerformance(
    $artistId: Int!
    $eventId: Int!
    $isArtistBookedAsGenerallyAvailable: Boolean
    $isArtistBookedAsAvailableOnCertainDates: Boolean
    $position: Int!
    $performanceOfferId: Int
  ) {
    createPerformance(
      artistId: $artistId
      eventId: $eventId
      isArtistBookedAsGenerallyAvailable: $isArtistBookedAsGenerallyAvailable
      isArtistBookedAsAvailableOnCertainDates: $isArtistBookedAsAvailableOnCertainDates
      position: $position
      performanceOfferId: $performanceOfferId
    ) {
      success
      performance {
        id
        artistConfirmed
        artist {
          title
          imageUrl
          id
        }
        isArtistSelfBooked
      }
      performanceOffers {
        id
        position
        status
        artist {
          id
          title
          imageUrl
        }
        offer {
          id
          name
          status
          isMultiSlot
        }
      }
    }
  }
`;

export const DELETE_PERFORMANCE = gql`
  mutation deletePerformance($performanceId: Int!) {
    deletePerformance(performanceId: $performanceId) {
      success
    }
  }
`;

export const UPDATE_PERFORMANCE_ARTIST_CONFIRMED = gql`
  mutation updatePerformanceArtistConfirmed(
    $performanceId: Int!
    $isConfirmed: Boolean!
  ) {
    updatePerformanceArtistConfirmed(
      performanceId: $performanceId
      isConfirmed: $isConfirmed
    ) {
      success
      performance {
        id
        artistConfirmed
      }
    }
  }
`;

export const UPDATE_PERFORMANCE_POSITIONS = gql`
  mutation updatePerformancePositions(
    $performancePositions: [PerformancePosition!]!
  ) {
    updatePerformancePositions(performancePositions: $performancePositions) {
      success
      performances {
        id
        position
        artistConfirmed
        artist {
          id
          title
          imageUrl
        }
      }
    }
  }
`;
