import gql from 'graphql-tag';

export const GET_OFFER_FOR_OFFER_DETAIL = gql`
  query GetOfferForOfferDetail($id: Int!) {
    offer(id: $id) {
      id
      name
      status
      createdAt
      createdBy {
        id
        firstName
        lastName
        email
      }
      city {
        id
        title
      }
      events {
        id
        localStartsAt
        neighborhood {
          id
          title
        }
        venue {
          id
          neighborhood {
            id
            title
          }
        }
      }
      artists {
        id
        title
        imageUrl
        homeCityDescription
      }
      artistsAndEventsBooked {
        artistId
        artistEvents {
          eventId
          isBooked
        }
      }
      artistsAndEventsNotBooked {
        artistId
        artistEvents {
          eventId
        }
      }
      artistIdsOpen
      artistIdsDeclined
      numArtistsTotal
      numEventPositionsTotal
      numEventsTotal
    }
  }
`;

export const GET_OFFERS = gql`
  query GetOffers(
    $offerSearch: String
    $offerType: String
    $artistIds: String
    $status: String
    $hasUpcomingEvents: Boolean
    $city: String
    $createdDate: String
    $createdById: Int
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
  ) {
    offers(
      offerSearch: $offerSearch
      offerType: $offerType
      artistIds: $artistIds
      status: $status
      hasUpcomingEvents: $hasUpcomingEvents
      city: $city
      createdDate: $createdDate
      createdById: $createdById
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
    ) {
      offers {
        id
        name
        createdAt
        createdBy {
          id
          email
          firstName
          lastName
        }
        city {
          id
          title
        }
        numArtistsAccepted
        numArtistsBooked
        numArtistsDeclined
        numArtistsOpen
        numArtistsTotal
        numEventsTotal
        numEventPositionsBooked
        numEventPositionsTotal
        numPerformancesCurrentlyAccepted
        status
      }
      metadata {
        currentPage
        totalRecords
      }
    }
  }
`;
