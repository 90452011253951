import { useQuery } from 'react-apollo';

import {
  GetArtistBasicInfoData,
  GetArtistBasicInfoVariables,
  GetArtistEligibilitiesData,
  GetArtistEligibilitiesVariables,
  GetArtistsData,
  GetArtistsVariables,
} from 'app/typings/artists';
import { queryParams } from 'app/shared/utils/queries';
import {
  GET_ARTIST_BASIC_INFO,
  GET_ARTIST_ELIGIBILITIES,
  GET_ARTISTS,
  GET_ARTISTS_FOR_ADD_ARTIST_TO_EVENT,
  GET_ARTISTS_FOR_ARTIST_TYPEAHEAD,
  GET_ARTISTS_FOR_OFFER_CREATE,
} from 'app/admin/graphql/artists/queries';

const liteQueryParams = (params: any) =>
  Object.assign(params, { isLiteQuery: true });

export const GetArtistBasicInfo = (
  params: GetArtistBasicInfoVariables = {}
) => {
  return useQuery<GetArtistBasicInfoData>(
    GET_ARTIST_BASIC_INFO,
    queryParams(params)
  );
};

export const GetArtistEligibilities = (
  params: GetArtistEligibilitiesVariables = {}
) => {
  return useQuery<GetArtistEligibilitiesData>(
    GET_ARTIST_ELIGIBILITIES,
    queryParams(params)
  );
};

export const GetArtists = (params: GetArtistsVariables = {}) => {
  return useQuery<GetArtistsData>(GET_ARTISTS, queryParams(params));
};

export const GetArtistsForAddArtistToEvent = (
  params: GetArtistsVariables = {}
) => {
  return useQuery<GetArtistsData>(
    GET_ARTISTS_FOR_ADD_ARTIST_TO_EVENT,
    queryParams(params)
  );
};

export const GetArtistsForArtistTypeahead = (
  params: GetArtistsVariables = {}
) => {
  return useQuery<GetArtistsData>(
    GET_ARTISTS_FOR_ARTIST_TYPEAHEAD,
    queryParams(liteQueryParams(params))
  );
};

export const GetArtistsForOfferCreate = (params: GetArtistsVariables = {}) => {
  return useQuery<GetArtistsData>(
    GET_ARTISTS_FOR_OFFER_CREATE,
    queryParams(params)
  );
};
