import { useQuery } from 'react-apollo';

import {
  GetBusinessOwnersData,
  GetBusinessOwnersVariables,
} from 'app/typings/businessOwners';
import { queryParams } from 'app/shared/utils/queries';
import { GET_BUSINESS_OWNERS } from 'app/admin/graphql/businessOwners/queries';

export const GetBusinessOwners = (params: GetBusinessOwnersVariables = {}) => {
  return useQuery<GetBusinessOwnersData>(
    GET_BUSINESS_OWNERS,
    queryParams(params)
  );
};
