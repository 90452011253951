import gql from 'graphql-tag';

import { venueSocialUrls } from 'app/shared/utils/socialLinks';

export const GET_VENUE_BASIC_INFO = gql`
  query GetVenueBasicInfo($id: Int!, $page: Int, $perPage: Int) {
    venue(id: $id) {
      id
      address
      address2
      latitude
      longitude
      capacity
      numGuestTickets
      imageUrl
      largeImageUrl
      properties {
        id
        propertyGroupId
        key
        name
        iconName
        position
        infoUrl
      }
      city {
        id
        title
        cityOperatingModel
        country {
          id
          title
        }
        timezone
        isAutomatedEmailEnabled
      }
      emailAddress
      neighborhood {
        id
        title
      }
      closestStation
      venueName
      tags {
        id
        name
      }
      venueCategories {
        id
        name
      }
      socialUrls {
        ${venueSocialUrls.join(' ')}
      }
      contactInfos {
        id
        firstName
        lastName
        email
        phoneNumber
        position
        isDayOfShowContact
        contactRelationship {
          id
          name
        }
      }
      mapUrl
      streetViewUrl
      inviteEmailMessage
      revealAddressEmailMessage
      seating {
        key
        name
      }
      maxPaInputs {
        key
        name
      }
      seatingNotes
      pastEventsCount
      upcomingEventsCount
      lastEventAt
      numTicketsAvailableForSale
      localCrewLoadInAt
      localArtistLoadInAt
      localGuestsArriveAt
      localStartsAt
      localEndsAt
      recommendedCrewCount
      gearStorage
      hasTapWater
      availableDays {
        key
        name
      }
      hostingFrequency {
        key
        name
      }
      availabilityNotes
      audioNotes
      bathroomNotes
      checkInProcessNotes
      externalVenueNotes
      internalVenueNotes
      loadInNotes
      mcNotes
      merchTablePlacementNotes
      parkingNotes
      seatingNotes
      stageSetupNotes
      suppliesNeededNotes
      timingNotes
      noiseRestrictions
      venueFeePresent
      venueFeeDescription
      isArchived
      creationType
      hostingStatus {
        key
        name
      }
      status
      termsAndConditionsAcceptedAt
      termsAndConditionsAcceptedBy {
        id
        firstName
        lastName
        email
      }
      allUpcomingEvents {
        id
        localStartsAt
      }
      upcomingEvents(page: $page, perPage: $perPage) {
        city {
          title
          cachedSlug
        }
        localStartsAt
        artists {
          title
        }
        eventType
        id
        cancelled
        cancellations {
          updatedAt
          reasonDescription
          cancellationReason {
            id
            description
            cancellableType
            key
          }
        }
      }
      upcomingEventsMetadata(page: $page) {
        totalRecords
        currentPage
      }
      pastEvents(page: $page, perPage: $perPage) {
        city {
          title
          cachedSlug
        }
        localStartsAt
        artists {
          title
        }
        eventType
        id
        cancelled
        cancellations {
          updatedAt
          reasonDescription
          cancellationReason {
            id
            description
            cancellableType
            key
          }
        }
      }
      pastEventsMetadata(page: $page) {
        totalRecords
        currentPage
      }
      venuePhotos {
        id
        title
        description
        imageUrl
        updatedAt
        createdBy {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_VENUE_EVENTS_PAST = gql`
  query GetVenueBasicInfo($id: Int!, $page: Int, $perPage: Int) {
    venue(id: $id) {
      id
      pastEvents(page: $page, perPage: $perPage) {
        id
        eventType
        cancelled
        cancellations {
          updatedAt
          reasonDescription
          cancellationReason {
            id
            description
            cancellableType
            key
          }
        }
        localStartsAt
        city {
          title
          cachedSlug
        }
        artists {
          title
        }
      }
      pastEventsMetadata(page: $page) {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_VENUE_EVENTS_UPCOMING = gql`
  query GetVenueBasicInfo($id: Int!, $page: Int, $perPage: Int) {
    venue(id: $id) {
      id
      upcomingEvents(page: $page, perPage: $perPage) {
        id
        eventType
        cancelled
        cancellations {
          updatedAt
          reasonDescription
          cancellationReason {
            id
            description
            cancellableType
            key
          }
        }
        localStartsAt
        city {
          title
          cachedSlug
        }
        artists {
          title
        }
      }
      upcomingEventsMetadata(page: $page) {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_VENUES = gql`
  query GetVenues(
    $availableDays: String
    $venueSearch: String
    $city: String
    $cityIds: String
    $businessOwner: String
    $neighborhood: String
    $characteristic: String
    $capacity: String
    $maxPaInputs: String
    $numTicketsAvailableForSale: String
    $eventScope: String
    $tag: String
    $venueCategory: String
    $hostingStatus: String
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    venues(
      availableDays: $availableDays
      venueSearch: $venueSearch
      city: $city
      cityIds: $cityIds
      businessOwner: $businessOwner
      neighborhood: $neighborhood
      characteristic: $characteristic
      capacity: $capacity
      maxPaInputs: $maxPaInputs
      numTicketsAvailableForSale: $numTicketsAvailableForSale
      eventScope: $eventScope
      tag: $tag
      venueCategory: $venueCategory
      hostingStatus: $hostingStatus
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      venues {
        id
        address
        imageUrl
        largeImageUrl
        properties {
          id
          propertyGroupId
          key
          name
          iconName
          position
          infoUrl
        }
        city {
          id
          title
        }
        emailAddress
        neighborhood {
          id
          title
          cachedSlug
        }
        capacity
        venueName
        mapUrl
        streetViewUrl
        tags {
          id
          name
        }
        hostingStatus {
          key
          name
        }
        pastEventsCount
        upcomingEventsCount
        lastEventAt
        numTicketsAvailableForSale
        latitude
        longitude
        isArchived
      }
      metadata {
        totalRecords
      }
    }
  }
`;

export const GET_VENUES_FOR_MAP = gql`
  query GetVenuesForMap(
    $venueSearch: String
    $city: String
    $cityIds: String
    $businessOwner: String
    $neighborhood: String
    $characteristic: String
    $capacity: String
    $numTicketsAvailableForSale: String
    $eventScope: String
    $tag: String
    $venueCategory: String
    $hostingStatus: String
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    venues(
      venueSearch: $venueSearch
      city: $city
      cityIds: $cityIds
      businessOwner: $businessOwner
      neighborhood: $neighborhood
      characteristic: $characteristic
      capacity: $capacity
      numTicketsAvailableForSale: $numTicketsAvailableForSale
      eventScope: $eventScope
      tag: $tag
      venueCategory: $venueCategory
      hostingStatus: $hostingStatus
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      venues {
        id
        latitude
        longitude
        venueName
        address
      }
      metadata {
        totalRecords
      }
    }
  }
`;

export const GET_VENUES_FOR_VENUE_TYPEAHEAD = gql`
  query GetVenuesForVenueTypeahead(
    $venueSearch: String
    $city: String
    $page: Int
    $perPage: Int
    $isLiteQuery: Boolean
  ) {
    venues(
      venueSearch: $venueSearch
      city: $city
      page: $page
      perPage: $perPage
      isLiteQuery: $isLiteQuery
    ) {
      venues {
        id
        venueName
        address
        capacity
        city {
          id
          title
        }
        neighborhood {
          id
          title
        }
      }
      metadata {
        totalRecords
      }
    }
  }
`;
