import gql from 'graphql-tag';

export const CREATE_NEIGHBORHOOD = gql`
  mutation createNeighborhood($cityId: Int!, $title: String) {
    createNeighborhood(cityId: $cityId, title: $title) {
      success
      neighborhood {
        id
        title
        venuesCount
      }
    }
  }
`;

export const DELETE_NEIGHBORHOOD = gql`
  mutation deleteNeighborhood($neighborhoodId: Int!) {
    deleteNeighborhood(neighborhoodId: $neighborhoodId) {
      success
    }
  }
`;

export const UPDATE_NEIGHBORHOOD = gql`
  mutation updateNeighborhood($neighborhoodId: Int!, $title: String) {
    updateNeighborhood(neighborhoodId: $neighborhoodId, title: $title) {
      success
      neighborhood {
        id
        title
        venuesCount
      }
    }
  }
`;
