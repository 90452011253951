import { useMutation } from 'react-apollo';

import {
  ArchiveBusinessOwnerData,
  CreateBusinessOwnerData,
  UpdateBusinessOwnerData,
} from 'app/typings/businessOwners';
import {
  ARCHIVE_BUSINESS_OWNER,
  CREATE_BUSINESS_OWNER,
  UPDATE_BUSINESS_OWNER,
} from 'app/admin/graphql/businessOwners/mutations';

export const ArchiveBusinessOwner = () => {
  return useMutation<ArchiveBusinessOwnerData>(ARCHIVE_BUSINESS_OWNER)[0];
};

export const CreateBusinessOwner = () => {
  return useMutation<CreateBusinessOwnerData>(CREATE_BUSINESS_OWNER)[0];
};

export const UpdateBusinessOwner = () => {
  return useMutation<UpdateBusinessOwnerData>(UPDATE_BUSINESS_OWNER)[0];
};
