import { useMutation } from 'react-apollo';

import {
  CreatePerformanceData,
  DeletePerformanceData,
  UpdatePerformanceArtistConfirmedData,
  UpdatePerformancePositionsData,
} from 'app/typings/performances';
import {
  CREATE_PERFORMANCE,
  DELETE_PERFORMANCE,
  UPDATE_PERFORMANCE_ARTIST_CONFIRMED,
  UPDATE_PERFORMANCE_POSITIONS,
} from 'app/admin/graphql/performances/mutations';

export const CreatePerformance = () => {
  return useMutation<CreatePerformanceData>(CREATE_PERFORMANCE)[0];
};

export const DeletePerformance = () => {
  return useMutation<DeletePerformanceData>(DELETE_PERFORMANCE)[0];
};

export const UpdatePerformanceArtistConfirmed = () => {
  return useMutation<UpdatePerformanceArtistConfirmedData>(
    UPDATE_PERFORMANCE_ARTIST_CONFIRMED
  )[0];
};

export const UpdatePerformancePositions = () => {
  return useMutation<UpdatePerformancePositionsData>(
    UPDATE_PERFORMANCE_POSITIONS
  )[0];
};
